import React from 'react';

import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const BlackTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '14px',
    color: theme.palette.common.white,
    fontWeight: '400',
    borderRadius: '8px',
    padding: '12px 16px',
  },
}));

export default BlackTooltip;
