// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YXm0wPrCkRR9xK5AxMC6{display:flex;align-items:end}.W0CiClt_SoqOlewnk2va{margin-right:20px}", "",{"version":3,"sources":["webpack://./src/components/organisms/EditPhoto/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CAEA,sBACE,iBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: end;\n\n  &__item {\n    margin-right: 20px\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "YXm0wPrCkRR9xK5AxMC6",
	"container__item": "W0CiClt_SoqOlewnk2va"
};
export default ___CSS_LOADER_EXPORT___;
