import { createTheme } from '@mui/material';

import { colors } from '~/theme/colors';
import { palette } from '~/theme/palette';
import { typography } from '~/theme/typography';

export default createTheme({
  palette,
  colors,
  spacing: [0, 4, 8, 16, 32, 40, 48],
  typography,
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          marginLeft: 4,
        },
      },
    },
  },
});
