import React, { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  isLoading?: boolean;
  className?: string;
};

const Loader: FC<Props> = ({ className, isLoading = true, children }) => {
  return isLoading ? (
    <div className={clsx(styles.lds_ring, className)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loader;
