import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Icon } from '@iconify/react';
import { readAndCompressImage } from 'browser-image-resizer';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import FileUploadButton from '~/components/atoms/buttons/FileUploadButton';
import IconButton from '~/components/atoms/buttons/IconButton';
import FullImage from '~/components/atoms/FullImage';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import HookFormToggler from '~/components/hookFormControls/TogglerControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import useFilters from '~/hooks/useFilters';
import useQueryParams from '~/hooks/useQueryParams';
import useData, {
  FiltersType,
} from '~/modules/themeCategories/components/ThemeCategoriesList/useData';
import { ThemeCategoryType } from '~/modules/themeCategories/types';
import { ThemesListRoute } from '~/modules/themes';
import { fonts } from '~/modules/themes/constants';
import { CreateThemeDtoType } from '~/modules/themes/types';
import { SORT_ORDER } from '~/types/common';

import styles from './styles.module.scss';

type FormDataType = CreateThemeDtoType;

export interface FontObject {
  key: string;
  title: string;
  font: {
    family: string;
    weight?: number;
    italic?: boolean;
  };
}

const DEFAULT_FONT: FontObject = {
  key: 'Inter-Regular',
  title: 'Inter - Regular',
  font: {
    family: 'Inter',
    weight: 400,
  },
};

const DEFAULT_FILTER_STATE: FiltersType = {
  sortOrder: SORT_ORDER.DESC,
  sortBy: 'created_at',
  page: 1,
  'per-page': 10,
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    color: yup.string(),
    font_name: yup.string(),
    font_color: yup.string(),
    font_color_hex: yup.string(),
    theme_category_id: yup.string().required('Required'),
  })
  .required();

const CreateForm: FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [assetSrc, setAssetSrc] = useState<string>('');
  const [previewAssetSrc, setPreviewAssetSrc] = useState<string>('');
  const [categories, setCategories] = useState<ThemeCategoryType[]>([]);
  const { getQueryParams } = useQueryParams();
  const [text, setText] = useState<string>('');
  const [color, setColor] = useState<string>('#000000');
  const [selectedFont, setSelectedFont] = useState<FontObject>(DEFAULT_FONT);
  const [iconsColor, SetIconsColor] = useState<'White' | 'Black'>('White');

  const defaultFilterState = {
    ...DEFAULT_FILTER_STATE,
    ...getQueryParams(),
  };

  const { filters } = useFilters<FiltersType>(defaultFilterState);

  const { updateItems } = useData(filters);

  const { showSnackbar } = useSnackBarContext();

  const loadCategories = async (): Promise<void> => {
    try {
      const categories = await Api.getThemeCategories({
        query: { 'per-page': 1000 },
      });
      setCategories(categories.data);
    } catch (e) {
      console.error(e);
    }
  };

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      color: '',
      is_new: true,
      asset_src: '',
      font_color: '',
      font_color_hex: '',
      font_name: '',
      theme_category_id: '',
    },
  });

  const { handleSubmit, watch } = form;

  const generateFilename = (): string => {
    const date = new Date();
    return `preview_${date.getTime()}.jpg`;
  };

  const handleAdd = async (data: CreateThemeDtoType): Promise<void> => {
    try {
      await Api.createTheme(data);
      await updateItems();
      showSnackbar('Theme is created', 'success');
    } catch (e: any) {
      showSnackbar(e.message);
      console.error(e);
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    const modifiedData = { ...data };
    if (data.color) {
      modifiedData.color = '#000000';
    } else {
      modifiedData.color = '#ffffff';
    }

    modifiedData.font_color = color;

    const selectedCategory = categories.find(
      (cat) => cat.id === data.theme_category_id,
    );

    if (selectedCategory) {
      modifiedData.category_name = selectedCategory.name;
    }

    modifiedData.asset_src = assetSrc;
    modifiedData.preview_asset_src = previewAssetSrc;

    try {
      setIsLoading(true);
      await handleAdd(modifiedData);

      setIsLoading(false);
      history.push(ThemesListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  const handleUpdatePhoto = async (file: File): Promise<void> => {
    const reader = new FileReader();
    if (reader) {
      reader.onloadend = (): void => {
        setPreviewImg(reader.result);
      };
      reader.readAsDataURL(file);
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('data', file);

      const res = await Api.uploadThemeBackground(file);
      setAssetSrc(res.data);

      await updateItems();
      showSnackbar('Background is added', 'success');
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      console.error(e);
    }

    try {
      const compressConfig = {
        quality: 1,
        maxHeight: 250,
      };
      const compressedImageBlob = await readAndCompressImage(
        file,
        compressConfig,
      );

      const fileName = generateFilename();
      const compressedImageFile = new File([compressedImageBlob], fileName, {
        type: 'image/jpeg',
        lastModified: new Date().getTime(),
      });

      const formData = new FormData();
      formData.append('image', compressedImageFile);

      const res = await Api.uploadThemeBackground(compressedImageFile);
      setPreviewAssetSrc(res.data);
      await updateItems();
      showSnackbar('Background preview is added', 'success');
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      console.error(e);
    }
  };

  const handleTextChange = (event): void => {
    setText(event.target.value);
  };

  const handleHexColorChange = (event): void => {
    setColor(event.target.value);
  };

  const handleRemovePhoto = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.removeFile();
      setPreviewImg('');
      showSnackbar('Removed', 'success');
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message, 'error');
    }
  };

  React.useEffect(() => {
    const subscription = watch(() => {
      const watchAllFields = watch();
      if (watchAllFields.font_name) {
        const selectedFontObject = fonts.find(
          (font) => font.key === watchAllFields.font_name,
        );

        if (selectedFontObject) {
          setSelectedFont(selectedFontObject);
        }
      }

      if (watchAllFields.font_color) {
        setColor(watchAllFields.font_color);
      }

      if (watchAllFields.font_color_hex) {
        setColor(watchAllFields.font_color);
      }

      if (watchAllFields.color) {
        SetIconsColor('Black');
      } else {
        SetIconsColor('White');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.main_container}>
        <ModalContentTitle text="New Theme" />
        <FormProvider {...form}>
          <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
            <div className={styles.field_wrapper}>
              <div className={styles.container}>
                <div className={styles.container__item}>
                  <FileUploadButton
                    onChange={(e): void => {
                      if (e?.target?.files && e.target.files[0]) {
                        handleUpdatePhoto(e.target.files[0]);
                      }
                    }}
                    variant="outlined"
                  >
                    Upload photo
                  </FileUploadButton>
                </div>
                {Boolean(previewImg) && (
                  <div className={styles.container__item}>
                    <IconButton
                      size="medium"
                      variant="outlined"
                      disabled={isLoading}
                      onClick={handleRemovePhoto}
                    >
                      <Icon color="#f00" icon="heroicons:trash" />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.field_wrapper}>
              <HookFormTextField
                required
                name="name"
                label="Name"
                disabled={isLoading}
                fullWidth
              />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormCheckbox name="is_premium" label="Is premium?" />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormToggler
                name="color"
                label={`${iconsColor} icons color`}
                disabled={isLoading}
                type="color"
              />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormCheckbox name="is_new" label="Is new?" />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormTextField
                required
                name="asset_src"
                label="Asset Src"
                disabled={isLoading}
                fullWidth
              />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormTextField
                required
                name="font_color"
                label="Font Color"
                disabled={isLoading}
                value={color}
                fullWidth
                type="color"
                onChange={handleHexColorChange}
              />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormTextField
                name="font_color_hex"
                label="Here you can paste hex color"
                disabled={isLoading}
                fullWidth
                onChange={handleHexColorChange}
                value={color}
              />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormTextField
                required
                name="text"
                label="Text"
                disabled={isLoading}
                fullWidth
                onChange={handleTextChange}
                value={text}
              />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormSelectField
                required
                name="font_name"
                label="Font"
                disabled={isLoading}
                fullWidth
                options={fonts.map((value) => ({
                  text: value.title,
                  value: value.key,
                }))}
              />
            </div>
            <div className={styles.field_wrapper}>
              <HookFormSelectField
                required
                name="theme_category_id"
                label="Category"
                disabled={isLoading}
                fullWidth
                options={categories.map((value) => ({
                  text: value.name,
                  value: value.id,
                }))}
              />
            </div>
            <div className={styles.buttons_container}>
              <Button
                disabled={isLoading}
                color="primary"
                variant="outlined"
                onClick={(): void => {
                  history.push(ThemesListRoute.path);
                }}
                fullWidth
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                color="primary"
                variant="contained"
                fullWidth
              >
                Add Theme
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div className={styles.image_container}>
        {previewImg && (
          <div className={styles.image}>
            <FullImage imgSrc={String(previewImg)} />
            <p
              className={styles.text}
              style={{
                color: color,
                fontFamily: selectedFont.font.family,
                fontWeight: selectedFont.font.weight
                  ? selectedFont.font.weight
                  : undefined,
                fontStyle: selectedFont.font.italic ? 'italic' : undefined,
              }}
            >
              {text}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateForm;
