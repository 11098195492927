// here is the place where import new modules
import '~/modules/auth';
import '~/modules/improvementAreas';
import '~/modules/groupsAffirmationsCategories';
import '~/modules/affirmationCategories';
import '~/modules/affirmation';
import '~/modules/themes';
import '~/modules/themeCategories';
import '~/modules/users';
import '~/modules/plans';

import '~/modules/admins';
import '~/modules/products';

import errorLogger from '~/services/ErrorLogger';

const SENTRY_DSN = process.env.SENTRY_DSN;

if (SENTRY_DSN) {
  errorLogger.init({ sentryDsn: SENTRY_DSN });
}
