import React, { FC } from 'react';

import {
  FormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';

export type TogglerCustomProps = MuiSwitchProps & {
  label?: string;
  errorMessage?: string;
};

const TogglerComponent: FC<TogglerCustomProps> = ({
  checked,
  onChange,
  name,
  label,
  errorMessage,
}) => {
  return (
    <div>
      <FormControlLabel
        label={label}
        checked={checked}
        control={
          <MuiSwitch
            checked={checked}
            onChange={onChange}
            name={name}
            size="medium"
            color="default"
            sx={{
              margin: '20px 0',
            }}
          />
        }
      />
      {errorMessage && <div style={{ color: '#f00' }}>{errorMessage}</div>}
    </div>
  );
};

export default TogglerComponent;
