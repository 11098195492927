// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xTUQVmHraMDL31GS5vZy{opacity:.8;pointer-events:none}.yEF_3GILmF3QASZkVygQ{display:flex;flex-direction:column}.yJ35xJbJIwM1bNtcnGbd{height:100%}.EPYKxdNOIyYhHpHUyy7E{flex:1}.RWC3cYHiNECC8a6JBY48{border-bottom:1px solid #e5e6e9}.nnOYRaQu_KnfGgMpPi4x{display:flex;justify-content:space-between;flex-wrap:nowrap;width:100%;padding:18px 40px 0}.zaZKdjfCGUaNWlLbaeEd{display:flex;flex-direction:column;flex:1;overflow-x:hidden}.k5HhVO56aP3VrMKzZHbj{min-width:230px}.__LD5r0xC7i5l8OVWgNq{font-size:15px;font-weight:700;display:block;margin-bottom:10px}.jIhG0Eltz2mg_gdmD7jR{margin-right:25px;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/users/components/UsersList/styles.modules.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAEA,sBACE,WAAA,CAGF,sBACE,MAAA,CAIJ,sBACE,+BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,UAAA,CACA,mBAAA,CAEA,sBACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CAIJ,sBACE,iBAAA,CACA,WAAA","sourcesContent":["@import 'src/styles/colors';\n@import \"src/styles/fonts\";\n\n.loading {\n  opacity: .8;\n  pointer-events: none;\n}\n\n.vertical_flex_container {\n  display: flex;\n  flex-direction: column;\n\n  &--full_height {\n    height: 100%;\n  }\n\n  &__full_parent_height {\n    flex: 1;\n  }\n}\n\n.top_bar {\n  border-bottom: 1px solid $neutral-200;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  width: 100%;\n  padding: 18px 40px 0;\n\n  &__left {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    overflow-x: hidden;\n  }\n\n  &__right {\n    min-width: 230px;\n  }\n\n  &__headline {\n    font-size: 15px;\n    font-weight: 700;\n    display: block;\n    margin-bottom: 10px\n  }\n}\n\n.table_container {\n  margin-right: 25px;\n  height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "xTUQVmHraMDL31GS5vZy",
	"vertical_flex_container": "yEF_3GILmF3QASZkVygQ",
	"vertical_flex_container--full_height": "yJ35xJbJIwM1bNtcnGbd",
	"vertical_flex_container__full_parent_height": "EPYKxdNOIyYhHpHUyy7E",
	"top_bar": "RWC3cYHiNECC8a6JBY48",
	"container": "nnOYRaQu_KnfGgMpPi4x",
	"container__left": "zaZKdjfCGUaNWlLbaeEd",
	"container__right": "k5HhVO56aP3VrMKzZHbj",
	"container__headline": "__LD5r0xC7i5l8OVWgNq",
	"table_container": "jIhG0Eltz2mg_gdmD7jR"
};
export default ___CSS_LOADER_EXPORT___;
