import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import AffirmationItem from '~/modules/affirmation/components/AffirmationItem';
import AffirmationItemList from '~/modules/affirmation/components/AffirmationList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'Affirmation';

export const AffirmationListRoute = {
  path: '/affirmations',
  Component: withSidebarLayout(AffirmationItemList),
  title: 'Affirmations',
  exact: true,
  permissionKey,
};

export const AffirmationItemRoute = {
  path: '/affirmations/:id',
  Component: withSidebarLayout(AffirmationItem),
  title: 'Affirmation Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  a: [
    {
      path: '/affirmations',
      title: 'Affirmations',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([AffirmationListRoute, AffirmationItemRoute]);
