import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import AffirmationsItem from '~/modules/plans/components/AffirmationsItem';
import AffirmationsList from '~/modules/plans/components/AffirmationsList';
import DaysItem from '~/modules/plans/components/DaysItem';
import DaysList from '~/modules/plans/components/DaysList';
import PlansItem from '~/modules/plans/components/PlansItem';
import PlansList from '~/modules/plans/components/PlansList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'Plans';

export const PlansListRoute = {
  path: '/plans',
  Component: withSidebarLayout(PlansList),
  title: 'Plans',
  exact: true,
  permissionKey,
};

export const PlansItemRoute = {
  path: '/plans/:id',
  Component: withSidebarLayout(PlansItem),
  title: 'Plan Card',
  exact: true,
  permissionKey,
};

export const DaysListRoute = {
  path: '/plans/:planId/days',
  Component: withSidebarLayout(DaysList),
  title: 'Days',
  exact: true,
  permissionKey,
};

export const DaysItemRoute = {
  path: '/plans/:planId/days/:id',
  Component: withSidebarLayout(DaysItem),
  title: 'Days',
  exact: true,
  permissionKey,
};

export const AffirmationsListRoute = {
  path: '/plans/:planId/days/:dayId/affirmations',
  Component: withSidebarLayout(AffirmationsList),
  title: 'Affirmations',
  exact: true,
  permissionKey,
};

export const AffirmationsItemRoute = {
  path: '/plans/:planId/days/:dayId/affirmations/:id',
  Component: withSidebarLayout(AffirmationsItem),
  title: 'Affirmations',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  e: [
    {
      path: '/plans',
      title: 'Plans',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([
  PlansListRoute,
  PlansItemRoute,
  DaysListRoute,
  DaysItemRoute,
  AffirmationsItemRoute,
  AffirmationsListRoute,
]);
