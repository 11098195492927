import React, { ComponentType, FC } from 'react';

import SidebarLayout from '~/components/layoutComponents/SidebarLayout';

export const withLayout = <P extends {}>(
  Layout: ComponentType<{ children: React.ReactNode }>,
  Component: ComponentType<P>,
): FC<P> => {
  return (props: P) => (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

// layouts
const withSidebarLayout = <P extends {}>(
  Component: ComponentType<P>,
): FC<P> => {
  return (props: P) => {
    return withLayout(SidebarLayout, Component)(props);
  };
};

export { withSidebarLayout };
