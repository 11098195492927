import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';

import styles from './styles.module.scss';

type FormDataType = {
  product_id: string;
  product_name: string;
};

const formSchema = yup
  .object()
  .shape({
    product_id: yup.string().required('Required'),
    product_name: yup.string().required('Required'),
  })
  .required();

type Props = {
  onClose: () => void;
  addProduct: (data: {
    product_id: string;
    product_name: string;
  }) => Promise<void>;
};

const CreateProductForm: FC<Props> = ({ onClose, addProduct }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      product_id: '',
      product_name: '',
    },
  });

  const { handleSubmit } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      await addProduct(data);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.main_container}>
      <ModalContentTitle text="New Product" />
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="product_id"
              label="Product ID (Solid)"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="product_name"
              label="Product name"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Product
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateProductForm;
