import React, { FC } from 'react';

import { TextFieldProps, Typography } from '@mui/material';

import { StyledTextField } from '~/components/atoms/inputs/styles';

export type TextFieldCustomProps = TextFieldProps;

const TextField: FC<TextFieldCustomProps> = ({ ...props }) => {
  const { size } = props;

  return (
    <>
      {props.label && (
        <Typography
          variant="body2"
          sx={{
            color: 'neutral.600',
            fontSize: size === 'small' ? '14px' : '15px',
            marginBottom: '8px',
          }}
        >
          {props.label}
        </Typography>
      )}
      <StyledTextField {...props} label={null} />
    </>
  );
};

export default TextField;
