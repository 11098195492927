import { useEffect, useState } from 'react';

import Api from '~/Api';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import { ImprovementAreaType } from '~/modules/improvementAreas/types';
import { SORT_ORDER } from '~/types/common';

export type FiltersType = {
  sortOrder: SORT_ORDER;
  sortBy: 'created_at' | 'updated_at';
  page: number | null;
  'per-page': number | null;
};

const useData = (
  filters: FiltersType,
): {
  isLoading: boolean;
  areas: ImprovementAreaType[];
  updateItems: () => Promise<void>;
  meta: {
    currentPage: number;
    totalPages: number;
    totalCount: number;
    pageSize: number;
  };
} => {
  const { showSnackbar } = useSnackBarContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    areas: ImprovementAreaType[];
    meta: {
      currentPage: number;
      pageSize: number;
      totalCount: number;
      totalPages: number;
    };
  }>({
    areas: [],
    meta: {
      currentPage: 1,
      pageSize: 20,
      totalCount: 0,
      totalPages: 1,
    },
  });

  const load = async (filters: FiltersType): Promise<void> => {
    try {
      setIsLoading(true);
      const { data, meta } = await Api.getImprovementAreas({
        query: {
          sort_type: filters.sortOrder,
          sort_by: filters.sortBy,
          page: filters.page || 1,
          'per-page': filters['per-page'] || 20,
        },
      });
      setData({
        areas: data,
        meta: {
          currentPage: meta.currentPage,
          totalPages: meta.totalPages,
          totalCount: meta.totalCount,
          pageSize: meta.pageSize,
        },
      });
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load(filters);
  }, [filters]);
  return {
    isLoading,
    areas: data.areas,
    meta: data.meta,
    updateItems: () => load(filters),
  };
};

export default useData;
