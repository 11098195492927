import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
  is_visible: boolean;
  is_premium: boolean;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    is_visible: yup.boolean().required('Required'),
    is_premium: yup.boolean().required('Required'),
  })
  .required();

type Props = {
  onClose: () => void;
  addItem: (data: {
    name: string;
    is_visible: boolean;
    is_premium: boolean;
  }) => Promise<void>;
};

const CreateForm: FC<Props> = ({ onClose, addItem }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      is_visible: false,
      is_premium: false,
    },
  });

  const { handleSubmit } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      await addItem(data);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.main_container}>
      <ModalContentTitle text="New Plan" />
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="name"
              label="Name"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <HookFormCheckbox name="is_visible" label="Is visible?" />
          <HookFormCheckbox name="is_premium" label="Is premium?" />
          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Plan
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateForm;
