import React, { forwardRef, ReactElement } from 'react';

import clsx from 'clsx';

import { ReactComponent as ErrorIcon } from '~/assets/icons/snackbar_error.svg';
import { ReactComponent as SuccessIcon } from '~/assets/icons/snackbar_success.svg';

import styles from './styles.module.scss';

interface Props {
  message: ReactElement | string;
  optionalMessage?: ReactElement | string;
  type: 'error' | 'success' | 'info';
  handleClose(): void;
  fullWidth?: boolean;
}

const icons = {
  success: <SuccessIcon color="#fff" />,
  error: <ErrorIcon />,
  info: null,
};

const Snackbar = forwardRef<HTMLDivElement, Props>(
  ({ message, optionalMessage, type, handleClose, fullWidth }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(styles.card, styles[type], {
          [styles.full_width]: fullWidth,
        })}
      >
        <div className={styles.flexContainer}>
          {icons[type] && <div className={styles.icon}>{icons[type]}</div>}
          <span className={styles.message}>{message}</span>
        </div>
        {optionalMessage && (
          <div className={styles.optionalMessage}>{optionalMessage}</div>
        )}
        <div className={styles.closeIcon} onClick={handleClose} />
      </div>
    );
  },
);

export default Snackbar;
