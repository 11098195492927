import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import ImprovementAreaItem from '~/modules/improvementAreas/components/ImprovementAreaItem';
import ImprovementAreaList from '~/modules/improvementAreas/components/ImprovementAreaList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'ImprovementAreas';

export const ImprovementAreaListRoute = {
  path: '/improvement-area',
  Component: withSidebarLayout(ImprovementAreaList),
  title: 'Products',
  exact: true,
  permissionKey,
};

export const ImprovementAreaItemRoute = {
  path: '/improvement-area/:id',
  Component: withSidebarLayout(ImprovementAreaItem),
  title: 'Product Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  a: [
    {
      path: '/improvement-area',
      title: 'Improvement Areas',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([ImprovementAreaListRoute, ImprovementAreaItemRoute]);
