import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import AdminItem from '~/modules/admins/components/AdminItem';
import AdminsList from '~/modules/admins/components/AdminsList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'ADMINS';

export const AdminsListRoute = {
  path: '/admin',
  Component: withSidebarLayout(AdminsList),
  title: 'Admins',
  exact: true,
  permissionKey,
};

export const AdminsItemRoute = {
  path: '/admin/:id',
  Component: withSidebarLayout(AdminItem),
  title: 'Admin Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: '/admin',
      title: 'Admins',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([AdminsListRoute, AdminsItemRoute]);
