import React, { FC } from 'react';

import { styled, TableCellProps } from '@mui/material';

import { ReactComponent as EllipseIcon } from '~/assets/icons/ellipse.svg';
import { TableCellStyled } from '~/components/atoms/TableCell/index';
import { getStatusColor } from '~/helpers/statusColor';

interface TableCellStatusProps extends TableCellProps {
  status: string;
}

const TableCellStatus: FC<TableCellStatusProps> = ({ status, ...props }) => {
  const color = getStatusColor(status);

  const EllipseIconStyled = styled(EllipseIcon)(() => ({
    '& circle': {
      fill: color,
    },
  }));

  return (
    <TableCellStyled
      {...props}
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      <EllipseIconStyled />
      <span
        style={{
          color,
          textTransform: 'capitalize',
          marginLeft: '8px',
        }}
      >
        {status}
      </span>
    </TableCellStyled>
  );
};

export default TableCellStatus;
