export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SORT_BY {
  NAME = 'name',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  CATEGORY_ID = 'category_id',
  EMAIL = 'email',
  STATUS = 'status',
  ID = 'id',
  CLIENT_COUNT = 'clients_count',
}

export const DEFAULT_STATUS = 'All';
export type DEFAULT_STATUS_TYPE = 'All';
