import { useState } from 'react';

const useFilters = <T>(
  defaultFilters: T,
): {
  filters: T;
  updateFilter: (filters: Partial<T>) => void;
  resetFilters: () => void;
} => {
  const [filters, setFilters] = useState<T>(defaultFilters);

  return {
    filters: filters,
    updateFilter: (filters: Partial<T>): void => {
      setFilters((prev) => ({
        ...prev,
        ...filters,
      }));
    },
    resetFilters: (): void => {
      setFilters(defaultFilters);
    },
  };
};

export default useFilters;
