// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v90cg6QUGMu6hscCDE8V{display:inline-block;position:relative;width:80px;height:80px}.v90cg6QUGMu6hscCDE8V div{box-sizing:border-box;display:block;position:absolute;width:64px;height:64px;margin:8px;border:8px solid;border-radius:50%;animation:m_dLGk3csR5fM0sturkl 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;border-color:#1e1e1f rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)}.v90cg6QUGMu6hscCDE8V div:nth-child(1){animation-delay:-0.45s}.v90cg6QUGMu6hscCDE8V div:nth-child(2){animation-delay:-0.3s}.v90cg6QUGMu6hscCDE8V div:nth-child(3){animation-delay:-0.15s}@keyframes m_dLGk3csR5fM0sturkl{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/components/atoms/Loader/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,oBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CAGF,0BACE,qBAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,yEAAA,CACA,8DAAA,CAGF,uCACE,sBAAA,CAGF,uCACE,qBAAA,CAGF,uCACE,sBAAA,CAGF,gCACE,GACE,sBAAA,CAGF,KACE,wBAAA,CAAA","sourcesContent":["@import \"../../../styles/colors\";\n\n.lds_ring {\n  display: inline-block;\n  position: relative;\n  width: 80px;\n  height: 80px;\n}\n\n.lds_ring div {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 64px;\n  height: 64px;\n  margin: 8px;\n  border: 8px solid;\n  border-radius: 50%;\n  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: $black transparent transparent transparent;\n}\n\n.lds_ring div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n\n.lds_ring div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n\n.lds_ring div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n@keyframes lds-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lds_ring": "v90cg6QUGMu6hscCDE8V",
	"lds-ring": "m_dLGk3csR5fM0sturkl"
};
export default ___CSS_LOADER_EXPORT___;
