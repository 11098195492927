import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { GroupAffirmationCategoryListRoute } from '~/modules/groupsAffirmationsCategories';
import { GroupAffirmationCategoryType } from '~/modules/groupsAffirmationsCategories/types';
import { ImprovementAreaType } from '~/modules/improvementAreas/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
  position: number;
  improvement_area_id: string;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    position: yup.number().required('Required'),
    improvement_area_id: yup.string().required('Required'),
  })
  .required();

const ProductItem: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const [group, setGroup] = useState<GroupAffirmationCategoryType>({
    id: '1',
    name: '',
    position: 1,
    affirmations_category_count: 0,
    improvement_area_name: '',
    improvement_area_id: '',
    created_at: '2022-11-11 11:11:11',
    updated_at: '2022-11-11 11:11:11',
  });
  const [areas, setAreas] = useState<ImprovementAreaType[]>([]);

  const loadAreas = async (): Promise<void> => {
    try {
      const areas = await Api.getImprovementAreas({
        query: { 'per-page': 1000 },
      });
      setAreas(areas.data);
    } catch (e) {
      console.error(e);
    }
  };

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      position: 1,
      improvement_area_id: '',
    },
  });

  const { handleSubmit, setValue } = form;

  const loadAdmin = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      const group = await Api.getGroupAffirmationCategory(id);
      setGroup(group);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const group = await Api.updateGroupAffirmationCategory(id, data);
      showSnackbar('Updated', 'success');
      setGroup(group);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleRemove = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.deleteGroupAffirmationCategory(id);
      showSnackbar('Removed', 'success');
      setIsLoading(false);
      history.push(GroupAffirmationCategoryListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push(GroupAffirmationCategoryListRoute.path);
    }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    Promise.all([loadAdmin(id), loadAreas()]).finally(() =>
      setIsFirstLoading(false),
    );
  }, []);

  useEffect(() => {
    setValue('name', group.name);
    setValue('position', group.position);
    setValue('improvement_area_id', group.improvement_area_id);
  }, [group]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to List"
        onClick={(): void => {
          history.push(GroupAffirmationCategoryListRoute.path);
        }}
      />
      <TopHeadlineRow
        className={styles.top_bar}
        text="Group affirmation category card"
      />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          <Tabs
            className={styles.container__tabs}
            items={[
              {
                label: 'General info',
                isActive: true,
              },
            ]}
            onClickItem={(): void => {}}
          />
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="name"
                    label="Group name"
                    fullWidth
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="position"
                    label="Position"
                    fullWidth
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormSelectField
                    required
                    name="improvement_area_id"
                    label="Improvement area"
                    disabled={isLoading}
                    fullWidth
                    options={areas.map(({ name, id }) => ({
                      text: name,
                      value: id,
                    }))}
                  />
                </div>

                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(group.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(group.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.buttons_container}>
                  <Button
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                    type="submit"
                    disabled={isLoading}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(handleSuccessSubmit)}
                  >
                    Update info
                  </Button>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    color="secondary"
                    variant="contained"
                    onClick={handleRemove}
                  >
                    Delete
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default ProductItem;
