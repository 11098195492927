import React, { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  text: string;
  className?: string;
};

const TopHeadlineRow: FC<Props> = ({ text, className, children }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <span className={styles.container__text}>{text}</span>
      {children && <div>{children}</div>}
    </div>
  );
};

export default TopHeadlineRow;
