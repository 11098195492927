export const DATABASE_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

export const DATE_CALENDAR_FORMAT = 'yyyy-MM-dd';

export const READABLE_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const DATE_FORMAT = 'dd-MM-yyyy';

export const TIME_FORMAT = 'HH:mm';

export enum WEEKDAYS {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum ISO_WEEKDAYS {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
