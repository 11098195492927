import React, { FC } from 'react';

import { TableCellProps } from '@mui/material';

import { ReactComponent as ArrowIconDown } from '~/assets/icons/arrow-down.svg';
import { ReactComponent as ArrowIconUp } from '~/assets/icons/arrow-up.svg';
import BlackTooltip from '~/components/atoms/BlackTooltip';
import { TableCellStyled } from '~/components/atoms/TableCell/index';
import { SORT_ORDER } from '~/types/common';

interface TableCellHeaderProps extends TableCellProps {
  handleClickSort?: () => void;
  order: SORT_ORDER.ASC | SORT_ORDER.DESC | null;
}

const TableCellHeader: FC<TableCellHeaderProps> = ({
  handleClickSort,
  order,
  children,
  ...props
}) => {
  return (
    <TableCellStyled {...props}>
      <div
        onClick={handleClickSort}
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          ...(handleClickSort
            ? {
                cursor: 'pointer',
              }
            : {}),
        }}
      >
        {children}
        <BlackTooltip title="Sort">
          <div className={'header_sort_arrow'}>
            {order === SORT_ORDER.ASC ? (
              <ArrowIconUp />
            ) : order === SORT_ORDER.DESC ? (
              <ArrowIconDown />
            ) : null}
          </div>
        </BlackTooltip>
      </div>
    </TableCellStyled>
  );
};

export default TableCellHeader;
