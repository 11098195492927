// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".huTkSyBtsH7Kg9uvlJK9{display:flex;flex-wrap:nowrap;height:100vh;overflow:hidden}.SeqCjmsyQiOthUNb_4QH{min-width:206px;border-right:1px solid #c6c8cf;height:100%}.VxhcAO73XYOONeacdcNu{width:100%;height:100%;overflow-y:scroll;position:relative;-ms-overflow-style:none;scrollbar-width:none}.VxhcAO73XYOONeacdcNu::-webkit-scrollbar{display:none}", "",{"version":3,"sources":["webpack://./src/components/layoutComponents/SidebarLayout/Desktop/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,gBAAA,CACA,YAAA,CACA,eAAA,CAEA,sBACE,eAAA,CACA,8BAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CAEA,uBAAA,CACA,oBAAA,CAEA,yCACE,YAAA","sourcesContent":["@import \"src/styles/colors\";\n\n.desktop_layout {\n  display: flex;\n  flex-wrap: nowrap;\n  height: 100vh;\n  overflow: hidden;\n\n  &__sidebar {\n    min-width: 206px;\n    border-right: 1px solid $neutral-500;\n    height: 100%;\n  }\n\n  &__main {\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n    position: relative;\n\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop_layout": "huTkSyBtsH7Kg9uvlJK9",
	"desktop_layout__sidebar": "SeqCjmsyQiOthUNb_4QH",
	"desktop_layout__main": "VxhcAO73XYOONeacdcNu"
};
export default ___CSS_LOADER_EXPORT___;
