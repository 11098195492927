import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import ThemeItem from '~/modules/themes/components/ThemeItem';
import ThemesList from '~/modules/themes/components/ThemesList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';
import CreateForm from './components/ThemesList/CreateForm';

const permissionKey = 'THEMES';

export const ThemesListRoute = {
  path: '/themes',
  Component: withSidebarLayout(ThemesList),
  title: 'Themes',
  exact: true,
  permissionKey,
};

export const ThemesCreateRoute = {
  path: '/themes-create',
  Component: withSidebarLayout(CreateForm),
  title: 'Create Theme',
  exact: true,
  permissionKey,
};

export const ThemesItemRoute = {
  path: '/themes/:id',
  Component: withSidebarLayout(ThemeItem),
  title: 'Theme Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  c: [
    {
      path: '/themes',
      title: 'Themes',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([ThemesListRoute, ThemesItemRoute, ThemesCreateRoute]);
