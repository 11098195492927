export const fonts = [
  {
    key: 'AbrilFatface-Regular',
    title: 'Abril Fatface - Regular',
    font: {
      family: 'Abril Fatface',
      weight: 400,
    },
  },
  {
    key: 'AmaticSC-Bold',
    title: 'Amatic SC - Bold',
    font: {
      family: 'Amatic SC',
      weight: 700,
    },
  },
  {
    key: 'Anton-Regular',
    title: 'Anton - Regular',
    font: {
      family: 'Anton',
      weight: 400,
    },
  },
  {
    key: 'Baloo2-Medium',
    title: 'Baloo 2 - Medium',
    font: {
      family: "'Baloo 2', cursive",
      weight: 500,
    },
  },
  {
    key: 'BebasNeue-Regular',
    title: 'Bebas Neue - Regular',
    font: {
      family: 'Bebas Neue',
    },
  },
  {
    key: 'Cabin-Medium',
    title: 'Cabin - Medium',
    font: {
      family: "'Cabin', sans-serif",
      weight: 500,
    },
  },
  {
    key: 'Cardo-Regular',
    title: 'Cardo - Regular',
    font: {
      family: "'Cardo', serif",
    },
  },
  {
    key: 'Cinzel-Regular',
    title: 'Cinzel - Regular',
    font: {
      family: "'Cinzel', serif",
      weight: 400,
    },
  },
  {
    key: 'CinzelRoman-Black',
    title: 'Cinzel Roman - Black',
    font: {
      family: "'Cinzel', serif",
      weight: 700,
    },
  },
  {
    key: 'CinzelRoman-Bold',
    title: 'Cinzel Roman - Bold',
    font: {
      family: "'Cinzel', serif",
      weight: 900,
    },
  },
  {
    key: 'Comfortaa-Regular',
    title: 'Comfortaa - Regular',
    font: {
      family: "'Comfortaa', cursive",
      weight: 300,
    },
  },
  {
    key: 'Comfortaa-Regular_Bold',
    title: 'Comfortaa - Regular / Bold',
    font: {
      family: "'Comfortaa', cursive",
      weight: 400,
    },
  },
  {
    key: 'Comfortaa-Regular_Light',
    title: 'Comfortaa - Regular / Light',
    font: {
      family: "'Comfortaa', cursive",
      weight: 700,
    },
  },
  {
    key: 'DancingScript-Regular',
    title: 'Dancing Script - Regular',
    font: {
      family: "'Dancing Script', cursive",
    },
  },
  {
    key: 'Delius-Regular',
    title: 'Delius - Regular',
    font: {
      family: "'Delius', cursive",
    },
  },
  {
    key: 'DeliusSwashCaps-Regular',
    title: 'Delius Swash Caps - Regular',
    font: {
      family: "'Delius Swash Caps', cursive",
    },
  },
  {
    key: 'FrederickatheGreat-Regular',
    title: 'Frederickathe Great - Regular',
    font: {
      family: "'Fredericka the Great', cursive",
    },
  },
  {
    key: 'FredokaOne-Regular',
    title: 'Fredoka One - Regular',
    font: {
      family: "'Fredoka', cursive",
    },
  },
  {
    key: 'Gayathri-Regular',
    title: 'Gayathri - Regular',
    font: {
      family: "'Gayathri', sans-serif",
    },
  },
  {
    key: 'GreatVibes-Regular',
    title: 'Great Vibes - Regular',
    font: {
      family: "'Great Vibes', cursive",
    },
  },
  {
    key: 'IBMPlexSerif-Light',
    title: 'IBM Plex Serif - Light',
    font: {
      family: "'IBM Plex Serif', serif",
      weight: 300,
    },
  },
  {
    key: 'IBMPlexSerif-SemiBoldItalic',
    title: 'IBM Plex Serif - Semi / Bold / Italic',
    font: {
      family: "'IBM Plex Serif', serif",
      weight: 600,
      italic: true,
    },
  },
  {
    key: 'Inter-Bold',
    title: 'Inter - Bold',
    font: {
      family: 'Inter',
      weight: 700,
    },
  },
  {
    key: 'Inter-Medium',
    title: 'Inter - Medium',
    font: {
      family: 'Inter',
      weight: 500,
    },
  },
  {
    key: 'Inter-Regular',
    title: 'Inter - Regular',
    font: {
      family: 'Inter',
      weight: 400,
    },
  },
  {
    key: 'Itim-Regular',
    title: 'Itim - Regular',
    font: {
      family: "'Itim', cursive",
    },
  },
  {
    key: 'JosefinSans-Regular',
    title: 'Josefin Sans - Regular',
    font: {
      family: "'Josefin Sans', sans-serif",
    },
  },
  {
    key: 'JosefinSlab-Bold',
    title: 'Josefin Slab - Bold',
    font: {
      family: 'Josefin Slab',
      weight: 700,
    },
  },
  {
    key: 'JosefinSlab-Medium',
    title: 'Josefin Slab - Medium',
    font: {
      family: 'Josefin Slab',
    },
  },
  {
    key: 'Jost-Regular',
    title: 'Jost - Regular',
    font: {
      family: "'Jost', sans-serif",
    },
  },
  {
    key: 'KaushanScript-Regular',
    title: 'Kaushan Script - Regular',
    font: {
      family: "'Kaushan Script', cursive",
    },
  },
  {
    key: 'Laila-Medium',
    title: 'Laila - Medium',
    font: {
      family: "'Laila', sans-serif",
      weight: 500,
    },
  },
  {
    key: 'McLaren-Regular',
    title: 'McLaren - Regular',
    font: {
      family: "'McLaren', cursive",
    },
  },
  {
    key: 'Merriweather-Regular',
    title: 'Merriweather - Regular',
    font: {
      family: "'Merriweather', serif",
    },
  },
  {
    key: 'NixieOne-Regular',
    title: 'Nixie One - Regular',
    font: {
      family: "'Nixie One', cursive",
    },
  },
  {
    key: 'Pacifico-Regular',
    title: 'Pacifico - Regular',
    font: {
      family: "'Pacifico', cursive",
    },
  },
  {
    key: 'Parisienne-Regular',
    title: 'Parisienne - Regular',
    font: {
      family: "'Parisienne', cursive",
    },
  },
  {
    key: 'Poppins-LightItalic',
    title: 'Poppins - Light / Italic',
    font: {
      family: "'Poppins', sans-serif",
      weight: 300,
      italic: true,
    },
  },
  {
    key: 'Raleway-SemiBold',
    title: 'Raleway - Semi-Bold',
    font: {
      family: "'Raleway', sans-serif",
      weight: 600,
    },
  },
  {
    key: 'RobotoMono-Regular',
    title: 'Roboto Mono - Regular',
    font: {
      family: "'Roboto Mono', monospace",
    },
  },
  {
    key: 'Satisfy-Regular',
    title: 'Satisfy - Regular',
    font: {
      family: "'Satisfy', cursive",
    },
  },
  {
    key: 'Sofia-Regular',
    title: 'Sofia - Regular',
    font: {
      family: "'Sofia', cursive",
    },
  },
  {
    key: 'SpecialElite-Regular',
    title: 'Special Elite - Regular',
    font: {
      family: "'Special Elite', cursive",
    },
  },
  {
    key: 'Syne-Medium',
    title: 'Syne - Medium',
    font: {
      family: "'Syne', sans-serif",
      weight: 500,
    },
  },
  {
    key: 'VarelaRound-Regular',
    title: 'Varela Round - Regular',
    font: {
      family: "'Varela Round', sans-serif",
    },
  },
  {
    key: 'Vidaloka-Regular',
    title: 'Vidaloka - Regular',
    font: {
      family: "'Vidaloka', serif",
    },
  },
];
