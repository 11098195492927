import React, { FC } from 'react';

import {
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableBody,
  Tooltip,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import TableCell from '~/components/atoms/TableCell';
import TableCellHeader from '~/components/atoms/TableCell/TableCellHeader';
import TableContainer from '~/components/atoms/TableContainer';
import TableHead from '~/components/atoms/TableHead';
import TableRow from '~/components/atoms/TableRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { ThemeType } from '~/modules/themes/types';
import DateService from '~/services/Date';
import colors from '~/theme/colors.module.scss';
import { SORT_ORDER } from '~/types/common';

import styles from '../styles.modules.scss';

type FieldsValueType = {
  sortBy: 'created_at' | 'updated_at' | 'category_id';
  sortOrder: SORT_ORDER;
};

type Props = {
  themes: ThemeType[];
  filter: FieldsValueType;
  applyFilters: (filters: FieldsValueType) => void;
};

const TABLE_HEADER_SETTINGS = [
  {
    title: 'Name',
    key: 'name',
    sortable: false,
  },
  {
    title: 'Position',
    key: 'position',
    sortable: false,
  },
  {
    title: 'Premium',
    key: 'is_premium',
    sortable: false,
  },
  {
    title: 'Color',
    key: 'color',
    sortable: false,
  },
  {
    title: 'New',
    key: 'is_new',
    sortable: false,
  },
  {
    title: 'Asset',
    key: 'asset_src',
    sortable: false,
  },
  {
    title: 'Font Color',
    key: 'font_color',
    sortable: false,
  },
  {
    title: 'Font Name',
    key: 'font_name',
    sortable: false,
  },
  {
    title: 'Category',
    key: 'category_id',
    sortable: true,
  },
  {
    title: 'Updated at',
    key: 'updated_at',
    sortable: true,
  },
  {
    title: 'Created at',
    key: 'created_at',
    sortable: true,
  },
];

const ThemesTable: FC<Props> = ({ filter, applyFilters, themes }) => {
  const history = useHistory();

  const handleClickSort = (
    field: 'created_at' | 'updated_at' | 'category_id',
  ): void => {
    const order =
      field === filter.sortBy && filter.sortOrder === SORT_ORDER.DESC
        ? SORT_ORDER.ASC
        : SORT_ORDER.DESC;
    applyFilters({
      sortBy: field,
      sortOrder: order,
    });
  };

  return (
    <div className={styles.table_container}>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <MuiTableRow>
              {TABLE_HEADER_SETTINGS.map((item) => {
                return (
                  <TableCellHeader
                    key={item.key}
                    handleClickSort={
                      item.sortable
                        ? (): void => {
                            handleClickSort(
                              item.key as
                                | 'created_at'
                                | 'updated_at'
                                | 'category_id',
                            );
                          }
                        : undefined
                    }
                    order={item.key === filter.sortBy ? filter.sortOrder : null}
                  >
                    {item.title}
                  </TableCellHeader>
                );
              })}
            </MuiTableRow>
          </TableHead>
          <TableBody>
            {themes.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <span
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={(): void => {
                      history.push(`/themes/${row.id}`);
                    }}
                  >
                    {row.name}
                  </span>
                </TableCell>
                <TableCell>{row.position}</TableCell>
                <TableCell>{row.is_premium.toString()}</TableCell>
                <TableCell>
                  <Tooltip title={row.color} placement="top">
                    <div style={{ backgroundColor: row.color, width: '20px' }}>
                      &nbsp;
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell>{row.is_new.toString()}</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <img
                        src={row.asset_src}
                        className={styles.image_tooltip__wrapper}
                      />
                    }
                  >
                    <img
                      src={row.asset_src}
                      className={styles.image_cell__wrapper}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.font_color} placement="top">
                    <div
                      style={{ backgroundColor: row.font_color, width: '20px' }}
                    >
                      &nbsp;
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell>{row.font_name}</TableCell>
                <TableCell>
                  {/* <span
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={(): void => {
                      history.push(
                        `/themes/?category_id=${row.theme_category_id}`,
                      );
                      window.location.reload();
                    }}
                  >
                    {row.category_name}
                  </span> */}
                  {row.category_name}
                </TableCell>
                <TableCell
                  sx={{ color: `${colors.color_neutral_800}!important` }}
                >
                  {DateService.format(
                    new Date(row.created_at),
                    READABLE_DATE_TIME_FORMAT,
                  )}
                </TableCell>
                <TableCell
                  sx={{ color: `${colors.color_neutral_800}!important` }}
                >
                  {DateService.format(
                    new Date(row.updated_at),
                    READABLE_DATE_TIME_FORMAT,
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
};

export default ThemesTable;
