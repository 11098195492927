export class FieldsError extends Error {
  public error: string;
  public fields: Record<string, string>;

  constructor(error) {
    super(error.message);
    this.error = error.error;
    this.fields = error.fields;
  }
}
