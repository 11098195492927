export const fonts = {
  default: 'Source Sans Pro, sans-serif',
  title: 'Source Serif Pro, sans-serif',
};

export const typography = {
  fontSize: 16,
  fontFamily: fonts.default,
  h1: {
    fontSize: 46,
    fontWeight: 700,
    lineHeight: '60px',
    marginBottom: 48,
    fontFamily: fonts.title,
  },
  h2: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '52px',
    marginBottom: 32,
    fontFamily: fonts.title,
  },
  h3: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '47px',
    marginBottom: 32,
    fontFamily: fonts.title,
  },
  h4: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '42px',
    marginBottom: 24,
    fontFamily: fonts.title,
  },
  h5: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '36px',
    marginBottom: 24,
    fontFamily: fonts.title,
  },
  h6: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '31px',
    marginBottom: 16,
    fontFamily: fonts.title,
  },
  body1: {
    fontSize: 18,
    lineHeight: '26px',
  },
  body2: {
    fontSize: 15,
    lineHeight: '24px',
  },
  button: {
    fontWeight: 600,
    letterSpacing: '-0.01em',
  },
};
