import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import { CreateAffirmationType } from '~/modules/affirmation/types';
import { AffirmationCategoryType } from '~/modules/affirmationCategories/types';

import styles from './styles.module.scss';

type FormDataType = CreateAffirmationType;

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    category_id: yup.string().required('Required'),
    is_visible: yup.boolean().required('Required'),
    text: yup.string().required('Required'),
  })
  .required();

type Props = {
  onClose: () => void;
  addAffirmation: (data: CreateAffirmationType) => Promise<void>;
};

const CreateImprovementAreaForm: FC<Props> = ({ onClose, addAffirmation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();
  const [categories, setCategories] = useState<AffirmationCategoryType[]>([]);
  const loadGroups = async (): Promise<void> => {
    try {
      const categories = await Api.getAffirmationCategories({
        query: { 'per-page': 1000 },
      });
      setCategories(categories.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadGroups();
  }, []);

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      await addAffirmation(data);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.main_container}>
      <ModalContentTitle text="New Affirmation" />
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="name"
              label="Name"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="text"
              label="Text"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormSelectField
              required
              name="category_id"
              label="Category"
              disabled={isLoading}
              fullWidth
              options={categories.map(({ id, name }) => ({
                value: id,
                text: name,
              }))}
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormCheckbox
              required
              name="isVisible"
              label="Visible"
              disabled={isLoading}
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormCheckbox
              required
              name="is_new"
              label="New"
              disabled={isLoading}
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormCheckbox
              required
              name="is_visible"
              label="Visible"
              disabled={isLoading}
            />
          </div>
          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Affirmation
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateImprovementAreaForm;
