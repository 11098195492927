import React, { FC, useState } from 'react';

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextFieldProps,
  Typography,
} from '@mui/material';

import { StyledTextField } from '~/components/atoms/inputs/styles';

export type PasswordFieldProps = TextFieldProps;

const PasswordField: FC<PasswordFieldProps> = ({ ...props }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { size } = props;

  return (
    <>
      {props.label && (
        <Typography
          variant="body2"
          sx={{
            color: 'neutral.600',
            fontSize: size === 'small' ? '14px' : '15px',
            marginBottom: '8px',
          }}
        >
          {props.label}
        </Typography>
      )}
      <StyledTextField
        {...props}
        label={null}
        type={isPasswordVisible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={(): void => setIsPasswordVisible(!isPasswordVisible)}
                edge="end"
              >
                {isPasswordVisible ? (
                  <VisibilityOffOutlined style={{ color: '#B4B6BC' }} />
                ) : (
                  <VisibilityOutlined style={{ color: '#B4B6BC' }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default PasswordField;
