import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import UsersList from '~/modules/users/components/UsersList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'Users';

export const UsersListRoute = {
  path: '/users',
  Component: withSidebarLayout(UsersList),
  title: 'Users',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: '/users',
      title: 'Users',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([UsersListRoute]);
