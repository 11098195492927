import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
  text: string;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    text: yup.string().required('Required'),
  })
  .required();

type Props = {
  onClose: () => void;
  addItem: (data: { name: string; text: string }) => Promise<void>;
};

const CreateForm: FC<Props> = ({ onClose, addItem }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      text: '',
    },
  });

  const { handleSubmit } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      await addItem(data);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.main_container}>
      <ModalContentTitle text="New Affirmation" />
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="name"
              label="Name"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="text"
              label="Text"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Affirmation
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateForm;
