import { FC } from 'react';

import { SignInRoute } from '~/modules/auth';

export type RouteItemType = {
  path: string;
  Component: FC;
  title: string;
  permissionKey?: string;
  exact?: boolean;
};

class Router {
  private authRoutes: RouteItemType[] = [];
  private privateRoutes: RouteItemType[] = [];

  constructor() {
    this.authRoutes = [];
    this.privateRoutes = [];
  }

  addAuthRoutes(route: RouteItemType[]): void {
    this.authRoutes = [...this.authRoutes, ...route];
  }

  getAuthRoutes(): RouteItemType[] {
    return this.authRoutes;
  }

  getSignInRoutePath(): string {
    return SignInRoute.path;
  }

  addPrivateRoutes(route: RouteItemType[]): void {
    this.privateRoutes = [...this.privateRoutes, ...route];
  }

  getPrivateRoutes(): RouteItemType[] {
    return this.privateRoutes;
  }

  getDefaultPrivateRoutePath(): string {
    return this.privateRoutes.length ? this.privateRoutes[0].path : '';
  }

  getPrivateRoutesByPermissions(permissionsList: string[]): RouteItemType[] {
    return this.privateRoutes.filter(
      ({ permissionKey }) =>
        !permissionKey || permissionsList.includes(permissionKey),
    );
  }

  getDefaultPrivateRouteByPermissions(
    permissionsList: string[],
  ): RouteItemType | null {
    const availablePrivateRoutes =
      this.getPrivateRoutesByPermissions(permissionsList);
    if (availablePrivateRoutes.length) {
      return availablePrivateRoutes[0];
    }
    return null;
  }
}

export default new Router();
