import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { ThemeCategoriesListRoute } from '~/modules/themeCategories';
import { ThemeCategoryType } from '~/modules/themeCategories/types';
import { ThemesListRoute } from '~/modules/themes';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
  is_visible: boolean;
  position: number;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    is_visible: yup.boolean().required('Required'),
  })
  .required();

const ThemeCategoriesItem: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState<ThemeCategoryType>({
    id: '1',
    name: 'Category 1',
    position: 1,
    is_visible: true,
    number_of_themes: 2,
    created_at: '2021-01-01',
    updated_at: '2021-01-01',
  });
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      is_visible: false,
      position: 0,
    },
  });

  const { handleSubmit, setValue } = form;

  const loadTheme = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);

      const category = await Api.getThemeCategory(id);
      setCategory(category);

      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const modifiedData = { ...data };
      modifiedData.position = Number(data.position);

      const category = await Api.updateThemeCategory(id, modifiedData);
      showSnackbar('Updated', 'success');

      setCategory(category);
      setIsLoading(false);
      history.push(ThemesListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleRemoveThemeCategory = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.deleteThemeCategory(id);
      showSnackbar('Removed', 'success');
      setIsLoading(false);
      history.push(ThemeCategoriesListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    loadTheme(id).finally(() => setIsFirstLoading(false));
  }, [id]);

  useEffect(() => {
    setValue('name', category.name);
    setValue('is_visible', category.is_visible);
    setValue('position', category.position);
  }, [category]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to List"
        onClick={(): void => {
          history.push(ThemeCategoriesListRoute.path);
        }}
      />
      <TopHeadlineRow className={styles.top_bar} text="Theme Category card" />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          <Tabs
            className={styles.container__tabs}
            items={[
              {
                label: 'General info',
                isActive: true,
              },
            ]}
            onClickItem={(): void => {}}
          />
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <div style={{ marginBottom: '8px' }}>
                    <HookFormTextField
                      required
                      name="name"
                      label="Category name"
                      fullWidth
                    />
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <HookFormTextField
                      required
                      name="position"
                      label="Position"
                      fullWidth
                      type="number"
                    />
                  </div>
                  <HookFormCheckbox name="is_visible" label="Is visible?" />
                </div>
                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(category.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(category.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.buttons_container}>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(handleSuccessSubmit)}
                  >
                    Update info
                  </Button>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    color="secondary"
                    variant="contained"
                    onClick={handleRemoveThemeCategory}
                  >
                    Delete Theme Category
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default ThemeCategoriesItem;
