// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vUlUxfIII3095TTwduAE{position:absolute;top:18px;right:18px;z-index:3}@media(max-width: 768px){.vUlUxfIII3095TTwduAE{top:8px;right:8px}}.uEI6O7t14bOgAK3EAXdV{padding:56px;text-align:left;overflow-y:auto;max-width:432px;width:100%;height:100%;box-sizing:border-box}@media(max-width: 768px){.uEI6O7t14bOgAK3EAXdV{padding:40px 20px 40px;max-width:100%}}.tdjRLIutF8OTUX06OeDi{max-height:100%}", "",{"version":3,"sources":["webpack://./src/components/molecules/Modal/styles.module.scss","webpack://./src/styles/_breakpoints.scss"],"names":[],"mappings":"AAIA,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,SAAA,CCNE,yBDEJ,sBAOI,OAAA,CACA,SAAA,CAAA,CAIJ,sBACE,YAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CCrBE,yBDcJ,sBAUI,sBAAA,CACA,cAAA,CAAA,CAGF,sBACE,eAAA","sourcesContent":["@import 'src/styles/colors';\n@import 'src/styles/breakpoints';\n@import 'src/styles/fonts';\n\n.close_icon_container {\n  position: absolute;\n  top: 18px;\n  right: 18px;\n  z-index: 3;\n\n  @include breakpoint(xs) {\n    top: 8px;\n    right: 8px;\n  }\n}\n\n.content {\n  padding: 56px;\n  text-align: left;\n  overflow-y: auto;\n  max-width: 432px;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n\n  @include breakpoint(xs) {\n    padding: 40px 20px 40px;\n    max-width: 100%;\n  }\n\n  &--full {\n    max-height: 100%;\n  }\n}\n","@mixin breakpoint($class) {\n  @if $class == xs {\n    @media (max-width: 768px) {\n      @content;\n    }\n  }\n\n  @if $class == md {\n    @media (max-width: 992px) {\n      @content;\n    }\n  }\n\n  @if $class == xl {\n    @media (min-width: 1536px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close_icon_container": "vUlUxfIII3095TTwduAE",
	"content": "uEI6O7t14bOgAK3EAXdV",
	"content--full": "tdjRLIutF8OTUX06OeDi"
};
export default ___CSS_LOADER_EXPORT___;
