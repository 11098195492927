import React, { FC, MutableRefObject } from 'react';

import clsx from 'clsx';

import Tab, { TabProps } from '~/components/atoms/Tab';

import styles from './styles.module.scss';

export interface TabsProps {
  items: Array<
    Omit<TabProps, 'onClick'> & {
      ref?: MutableRefObject<HTMLDivElement | null>;
    }
  >;
  onClickItem: (idx: number) => void;
  className?: string;
}

const Tabs: FC<TabsProps> = ({ items, onClickItem, className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.container__inner_wrapper}>
        {items.map(({ ref, ...props }, idx) => (
          <div className={styles.container__tab_container} key={idx} ref={ref}>
            <Tab
              onClick={(): void => {
                onClickItem(idx);
              }}
              {...props}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
