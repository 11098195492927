import React, { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  imgSrc: string;
  className?: string;
}

const FullImage: FC<Props> = ({ imgSrc, className }) => {
  return (
    <img src={imgSrc} alt="image" className={clsx(className, styles.image)} />
  );
};

export default FullImage;
