import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import EditPhoto from '~/components/organisms/EditPhoto';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { AffirmationCategoryListRoute } from '~/modules/affirmationCategories';
import { AffirmationCategoryType } from '~/modules/affirmationCategories/types';
import { GroupAffirmationCategoryType } from '~/modules/groupsAffirmationsCategories/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
  is_premium: boolean;
  is_visible: boolean;
  is_new: boolean;
  group_id: string;
  image_src: string;
  position: number;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    group_id: yup.string().required('Required'),
  })
  .required();

const AffirmationCategoryItem: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<GroupAffirmationCategoryType[]>([]);
  const [category, setCategory] = useState<AffirmationCategoryType>({
    id: '1',
    name: '',
    position: 1,
    is_premium: false,
    is_visible: false,
    is_new: false,
    image_src: '',
    preview_image_src: '',
    affirmations_count: 1,
    group_id: '1',
    group_name: 'Group name',
    created_at: '2022-11-11 11:11:11',
    updated_at: '2022-11-11 11:11:11',
  });
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit, watch, setValue } = form;

  const load = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      const category = await Api.getAffirmationCategory(id);
      setCategory(category);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push(AffirmationCategoryListRoute.path);
    }
  };

  const loadGroups = async (): Promise<void> => {
    try {
      const groups = await Api.getGroupsAffirmationCategory({
        query: { 'per-page': 1000 },
      });
      setGroups(groups.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const category = await Api.updateAffirmationCategory(id, data);
      showSnackbar('Updated', 'success');
      setCategory(category);
      setIsLoading(false);
      history.push(AffirmationCategoryListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push(AffirmationCategoryListRoute.path);
    }
  };

  const handleRemove = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.deleteAffirmationCategory(id);
      showSnackbar('Removed', 'success');
      setIsLoading(false);
      history.push(AffirmationCategoryListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push(AffirmationCategoryListRoute.path);
    }
  };
  const handleUploadPhoto = async (file: File): Promise<void> => {
    try {
      setIsLoading(true);
      const src = await Api.uploadMediaFile(file);
      setValue('image_src', src);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      history.push(AffirmationCategoryListRoute.path);
      return;
    }
    Promise.all([load(id), loadGroups()]).finally(() =>
      setIsFirstLoading(false),
    );
  }, []);

  useEffect(() => {
    setValue('name', category.name);
    setValue('position', category.position);
    setValue('is_premium', category.is_premium);
    setValue('is_visible', category.is_visible);
    setValue('is_new', category.is_new);
    setValue('group_id', category.group_id);
    setValue('image_src', category.image_src);
  }, [category]);

  const image_src = watch('image_src');

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to List"
        onClick={(): void => {
          history.push(AffirmationCategoryListRoute.path);
        }}
      />
      <TopHeadlineRow
        className={styles.top_bar}
        text="Affirmation Category Card"
      />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          <Tabs
            className={styles.container__tabs}
            items={[
              {
                label: 'General info',
                isActive: true,
              },
            ]}
            onClickItem={(): void => {}}
          />
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <EditPhoto
                    isLoading={isLoading}
                    src={image_src}
                    update={handleUploadPhoto}
                  />
                  <HookFormTextField
                    required
                    hidden
                    style={{ display: 'none' }}
                    name="image_src"
                    disabled={isLoading}
                    fullWidth
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="name"
                    label="Category Name"
                    disabled={isLoading}
                    fullWidth
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="position"
                    label="Position"
                    disabled={isLoading}
                    fullWidth
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormSelectField
                    required
                    name="group_id"
                    label="Group"
                    disabled={isLoading}
                    fullWidth
                    options={groups.map(({ id, name }) => ({
                      value: id,
                      text: name,
                    }))}
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormCheckbox
                    required
                    name="is_premium"
                    label="Premium"
                    disabled={isLoading}
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormCheckbox
                    required
                    name="is_new"
                    label="New"
                    disabled={isLoading}
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormCheckbox
                    required
                    name="is_visible"
                    label="Visible"
                    disabled={isLoading}
                  />
                </div>

                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(category.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(category.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.buttons_container}>
                  <Button
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                    type="submit"
                    disabled={isLoading}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(handleSuccessSubmit)}
                  >
                    Update info
                  </Button>
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    variant="contained"
                    onClick={handleRemove}
                  >
                    Delete
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default AffirmationCategoryItem;
