import React, { FC } from 'react';

import {
  TableContainer as MuiTableContainer,
  styled,
  TableContainerProps,
} from '@mui/material';

export const TableContainerStyled = styled(MuiTableContainer)(() => ({
  '&': {
    borderRadius: '8px',
  },
}));

const TableContainer: FC<TableContainerProps> = ({ children, ...props }) => {
  return <TableContainerStyled {...props}>{children}</TableContainerStyled>;
};

export default TableContainer;
