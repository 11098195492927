import React, { FC, useEffect, useState } from 'react';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import { useActions } from '~/store/hooks/useActions';
// import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';

const CheckAuthWrapper: FC = ({ children }) => {
  const { checkIsUserLoggedIn } = useActions();
  const [isLoading, setIsLoading] = useState(true);
  // const { showSnackbar } = useSnackBarContext();

  const load = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await checkIsUserLoggedIn();
    } catch (e: any) {
      console.error(e);
      if (e?.message) {
        // showSnackbar(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return isLoading ? <FullscreenPreloader /> : <>{children}</>;
};
export { CheckAuthWrapper };
