import router, { RouteItemType } from '~/core/router';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';

const SignIn = lazyComponentLoader(() => import('./components/SignIn'));
const SignUp = lazyComponentLoader(() => import('./components/SignUp'));
const ForgotPassword = lazyComponentLoader(
  () => import('./components/ForgotPassword'),
);
const ResetPassword = lazyComponentLoader(
  () => import('./components/ResetPassword'),
);

export const SignInRoute: RouteItemType = {
  Component: SignIn,
  path: '/sign-in',
  title: `Log In`,
  exact: true,
};

export const SignUpRoute: RouteItemType = {
  Component: SignUp,
  path: '/sign-up',
  title: `Sign Up`,
  exact: true,
};

export const ForgotPasswordRoute: RouteItemType = {
  Component: ForgotPassword,
  path: '/forgot-password',
  title: `Forgot Password`,
  exact: true,
};

export const ResetPasswordRoute: RouteItemType = {
  Component: ResetPassword,
  path: '/reset-password',
  title: `Reset Password`,
  exact: true,
};

router.addAuthRoutes([
  SignInRoute,
  SignUpRoute,
  ForgotPasswordRoute,
  ResetPasswordRoute,
]);
