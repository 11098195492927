/* eslint-disable @typescript-eslint/no-unused-vars */
import { FieldsError } from '~/Api/FieldsError';
import { MetaDataResponseType } from '~/Api/types';
import { ADMIN_STATUSES } from '~/modules/admins/constants';
import { AdminType } from '~/modules/admins/types';
import {
  AffirmationType,
  CreateAffirmationType,
} from '~/modules/affirmation/types';
import {
  AffirmationCategoryType,
  CreateAffirmationCategoryType,
} from '~/modules/affirmationCategories/types';
import { GroupAffirmationCategoryType } from '~/modules/groupsAffirmationsCategories/types';
import { ImprovementAreaType } from '~/modules/improvementAreas/types';
import {
  PlanAffirmationType,
  PlanDayType,
  PlanType,
} from '~/modules/plans/types';
import { ProductType } from '~/modules/products/types';
import {
  CreateThemeCategoryDtoType,
  ThemeCategoryType,
} from '~/modules/themeCategories/types';
import {
  CreateThemeBackgroundType,
  CreateThemeDtoType,
  ThemeType,
} from '~/modules/themes/types';
import { UserType } from '~/modules/users/types';
import AbstractStorage from '~/services/storage/AbstractStorage';
import localStorage from '~/services/storage/localStorage';
import { SORT_BY, SORT_ORDER } from '~/types/common';
import { ProfileType } from '~/types/profile';

import { Api, ModelsAdmin, RequestParams } from './generated-api/api';

const baseApiClient = new Api({
  baseUrl: process.env.REST_API_BASE_URL || '/',
});

class AdminApi {
  static ACCESS_TOKEN_STORAGE_KEY = 'access_token';
  static REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

  accessToken: string | null;
  refreshToken: string | null;
  storage: AbstractStorage;

  constructor({
    accessToken,
    refreshToken,
    storage,
  }: {
    accessToken: string | null;
    refreshToken: string | null;
    storage: AbstractStorage;
  }) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.storage = storage;
  }

  static async handleError(e: any, methodName): Promise<Error> {
    try {
      if (e.status === 404) {
        throw Error('Not found');
      }

      if (e.status >= 500) {
        throw Error('Server error');
      }

      if (e?.error?.fields) {
        return new FieldsError(e.error);
      }

      if (e?.error?.error) {
        return new Error(e.error.error);
      }

      if (e instanceof Response) {
        const parsedData = await e.json();

        if (parsedData.fields) {
          return new FieldsError(parsedData);
        }

        if (parsedData.error) {
          return new Error(parsedData.error);
        }
      }

      throw Error('Error');
    } catch (e: any) {
      return new Error(e.message || `Error in ${methodName}`);
    }
  }

  private getAuthHeaders(): Record<string, string> {
    if (this.accessToken === null) {
      this.clearAccessKeys();
      throw Error('Not authorized');
    }
    return {
      'Access-Token': this.accessToken,
    };
  }
  private setAccessKeys(access_token: string, refresh_token: string): string {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.storage.setByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY, access_token);
    this.storage.setByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY, refresh_token);
    return access_token;
  }

  private clearAccessKeys(): void {
    this.accessToken = null;
    this.refreshToken = null;
    this.storage.removeByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY);
    this.storage.removeByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY);
  }

  private async makeAuthorizedRequest<T>(
    requestFunction: (params: RequestParams) => Promise<T>,
    methodName: string,
  ): Promise<T> {
    try {
      try {
        return await requestFunction({ headers: this.getAuthHeaders() });
      } catch (e: any) {
        if (e?.status === 403 || e?.status === 401) {
          try {
            await this.updateTokens();
          } catch (e: any) {
            if (e.message === 'Not authorized') {
              await this.clearAccessKeys();
              window.location.reload();
            }
          }
          return await requestFunction({ headers: this.getAuthHeaders() });
        }
        throw e;
      }
    } catch (e) {
      throw await AdminApi.handleError(e, methodName);
    }
  }

  static mapAdminResponse = (admin: ModelsAdmin): AdminType => {
    try {
      return {
        id: admin.id || '',
        email: admin.email || '',
        status: ADMIN_STATUSES.ACTIVE,
        created_at: admin.created_at || '2021-01-01',
        updated_at: admin.updated_at || '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapAffirmationCategoryResponse = (
    data: Record<string, any>,
  ): AffirmationCategoryType => {
    try {
      return {
        id: data.id ?? '',
        name: data.name ?? '',
        position: data.position ?? '',
        is_premium: data.is_premium ?? '',
        is_visible: data.is_visible ?? '',
        is_new: data.is_new ?? '',
        image_src: data.asset_src ?? '',
        preview_image_src: data.preview_image_src ?? '', // TODO
        affirmations_count: data.affirmations_count ?? 0, // TODO
        group_id: data.affirmations_count ?? 'todo',
        group_name: data.affirmations_count ?? 'todo',
        created_at: data.created_at ?? '2021-01-01',
        updated_at: data.updated_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapImprovementAreasResponse = (
    data: Record<string, any>,
  ): ImprovementAreaType => {
    try {
      return {
        id: data.id ?? '',
        name: data.name ?? '',
        created_at: data.created_at ?? '2021-01-01',
        updated_at: data.updated_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapGroupAffirmationCategoryResponse = (
    data: Record<string, any>,
  ): GroupAffirmationCategoryType => {
    try {
      return {
        id: data.id ?? '',
        name: data.name ?? '',
        position: data.position ?? 1,
        affirmations_category_count: 12,
        improvement_area_id: '833a2ccf-3955-4451-a36c-0a416005a4e3', // TODO
        improvement_area_name: 'todo', // TODO
        created_at: data.created_at ?? '2021-01-01',
        updated_at: data.updated_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapAffirmationResponse = (
    data: Record<string, any>,
  ): AffirmationType => {
    try {
      return {
        id: data.id ?? '',
        name: data.name ?? '',
        is_visible: data.is_visible ?? false,
        text: data.text ?? '',
        category_id: data.category_id ?? false,
        category_name: data.category_name ?? 'todo', // TODO
        created_at: data.created_at ?? '2021-01-01',
        updated_at: data.updated_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapThemesCategoryResponse = (
    data: Record<string, any>,
  ): ThemeCategoryType => {
    try {
      return {
        id: data.id ?? '',
        name: data.name ?? '',
        position: data.position ?? 1,
        is_visible: data.is_visible ?? false,
        number_of_themes: data.themes_count ?? 0,
        created_at: data.created_at ?? '2021-01-01',
        updated_at: data.updated_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapThemeResponse = (data: Record<string, any>): ThemeType => {
    try {
      return {
        id: data.id ?? '',
        name: data.name ?? '',
        is_premium: data.is_premium ?? false,
        color: data.color ?? '',
        is_new: data.is_new ?? false,
        asset_src: data.asset_src ?? '',
        preview_asset_src: data.preview_asset_src ?? '',
        font_color: data.font_color ?? '',
        font_name: data.font_name ?? '',
        position: data.position ?? 1,
        theme_category_id: data.theme_category_id ?? '',
        category_name: data.category_name ?? '',
        created_at: data.created_at ?? '2021-01-01',
        updated_at: data.updated_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapThemeBackgroundResponse = (
    data: string,
  ): CreateThemeBackgroundType => {
    try {
      return {
        data: data ?? '',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapUserResponse = (user: Record<string, any>): UserType => {
    try {
      return {
        id: user.id ?? '',
        email: user.email ?? '',
        status: user.email ?? '',
        panda_id: user.panda_id ?? '',
        panda_id_ios: user.panda_id_ios ?? '',
        created_at: user.created_at ?? '2021-01-01',
        updated_at: user.created_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapProductResponse = (product: Record<string, any>): ProductType => {
    try {
      return {
        id: product.id ?? '',
        product_id: product.product_id ?? '',
        product_name: product.name ?? '',
        created_at: product.created_at ?? '2021-01-01',
        updated_at: product.created_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  async updateTokens(): Promise<void> {
    try {
      if (this.refreshToken && this.accessToken) {
        const { data } = await baseApiClient.adminPanel.refreshTokenCreate({
          refresh_token: this.refreshToken,
          access_token: this.accessToken,
        });

        if (data.data?.access_token && data.data?.refresh_token) {
          const { access_token, refresh_token } = data.data;
          this.setAccessKeys(access_token, refresh_token);
        }
      }
    } catch (e) {
      console.error(e);
      this.clearAccessKeys();
      throw Error('Not authorized');
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      const { data } = await baseApiClient.adminPanel.loginCreate({
        email: email.trim(),
        password,
      });
      if (data.data?.access_token && data.data?.refresh_token) {
        const { access_token, refresh_token } = data.data;
        this.setAccessKeys(access_token, refresh_token);
        return;
      }
    } catch (error) {
      throw await AdminApi.handleError(error, 'signIn');
    }
  }

  async signUp(params: {
    email: string;
    password: string;
    name: string;
  }): Promise<void> {
    try {
      await baseApiClient.adminPanel.signUpCreate({
        ...params,
        email: params.email.trim(),
      });
    } catch (error) {
      throw await AdminApi.handleError(error, 'signUp');
    }
  }

  async forgotPassword(email: string): Promise<void> {
    // try {
    //   await baseApiClient.adminPanel.forgotPasswordCreate({
    //     email: email.trim(),
    //   });
    // } catch (error) {
    //   throw await AdminApi.handleError(error, 'forgotPassword');
    // }
  }

  async resetPassword(token: string, password: string): Promise<void> {
    // try {
    //   await baseApiClient.adminPanel.resetPasswordCreate({
    //     token,
    //     password,
    //   });
    // } catch (error) {
    //   throw await AdminApi.handleError(error, 'resetPassword');
    // }
  }

  async signOut(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      try {
        await baseApiClient.adminPanel.logoutCreate(params);
        this.clearAccessKeys();
      } catch (e) {
        throw await AdminApi.handleError(e, 'signOut');
      }
    }, 'signOut');
  }

  async getProfile(): Promise<ProfileType> {
    return this.makeAuthorizedRequest<ProfileType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.adminsProfileList(params);
      if (data.data) {
        return {
          id: data.data.id || '',
          email: data.data.email || '',
        };
      }
      throw Error('Invalid response');
    }, 'getProfile');
  }

  async createAdmin(email: string, password: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.adminPanel.signUpCreate({ email, password }, params);
    }, 'createAdmin');
  }

  async updateAdminPassword(id: string, password: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      // await baseApiClient.adminPanel.adminsInviteCreate({ email }, params); // TODO: uncomment when BE will be ready
    }, 'updateAdminPassword');
  }

  async getAdmin(id: string): Promise<AdminType> {
    return this.makeAuthorizedRequest<AdminType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.adminsDetail(id, params);
      if (data.data) {
        return AdminApi.mapAdminResponse(data.data);
      }
      throw Error('Invalid response');
    }, 'getAdmin');
  }

  async updateAdmin(
    id: string,
    dataToUpdate: Partial<AdminType>,
  ): Promise<void> {}

  async getAdmins({
    query,
  }: {
    query: {
      email?: string;
      name?: string;
      status?: ADMIN_STATUSES;
      sort_type: SORT_ORDER;
      sort_by: 'email' | 'name' | 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: AdminType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: AdminType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.adminsList(query, params);

      if (data && data.data && data.meta) {
        const users = data.data;
        const meta = data.meta;
        return {
          data: users.map((item) => AdminApi.mapAdminResponse(item)),
          meta: {
            currentPage: meta.current_page ?? 1,
            pageSize: meta.page_size ?? 10,
            totalCount: meta.total_count ?? 0,
            totalPages: meta.total_pages ?? 1,
          },
        };
      }
      throw Error('Invalid response');
    }, 'getAdmins');
  }

  async getProduct(id: string): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.productsDetail(
        id,
        params,
      );
      return AdminApi.mapProductResponse(data.data);
    }, 'getProduct');
  }

  async getProducts({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: ProductType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ProductType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.productsList(
        query,
        params,
      );

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const products = data.data;
      const meta = data.meta;
      return {
        data: products.map((item) => AdminApi.mapProductResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getProducts');
  }

  async updateProduct(
    id: string,
    dataToUpdate: Partial<ProductType>,
  ): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.productsUpdate(
        id,
        {
          ...dataToUpdate,
          ...(dataToUpdate.product_name
            ? { name: dataToUpdate.product_name }
            : {}),
        },
        params,
      );
      return AdminApi.mapProductResponse(data.data);
    }, 'updateProduct');
  }

  async createProduct(dataToUpdate: {
    product_id: string;
    product_name: string;
  }): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.productsCreate(
        {
          ...dataToUpdate,
          ...(dataToUpdate.product_name
            ? { name: dataToUpdate.product_name }
            : {}),
        },
        params,
      );
      return AdminApi.mapProductResponse(data.data);
    }, 'updateProduct');
  }

  async deleteProduct(id: string): Promise<void> {
    // return this.makeAuthorizedRequest<void>(async (params) => {
    //   await baseApiClient.adminPanel.prod(id, params);
    // }, 'deleteProduct');
  }

  async createImprovementArea(createData: {
    name: string;
  }): Promise<ImprovementAreaType> {
    return this.makeAuthorizedRequest<ImprovementAreaType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.improvementAreasCreate(
        createData,
        params,
      );

      return AdminApi.mapImprovementAreasResponse(data.data);
    }, 'createImprovementArea');
  }

  async getImprovementAreas({
    query,
  }: {
    query?: {
      sort_type?: SORT_ORDER;
      sort_by?: 'created_at' | 'updated_at';
      page?: number;
      'per-page'?: number;
    };
  }): Promise<{
    data: ImprovementAreaType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ImprovementAreaType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.improvementAreasList(
        query,
        params,
      );

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const improvementAreas = data.data;
      const meta = data.meta;
      return {
        data: improvementAreas.map((item) =>
          AdminApi.mapImprovementAreasResponse(item),
        ),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getImprovementAreas');
  }
  async getImprovementArea(id: string): Promise<ImprovementAreaType> {
    return this.makeAuthorizedRequest<ImprovementAreaType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.improvementAreasDetail(
        id,
        params,
      );
      return AdminApi.mapImprovementAreasResponse(data.data);
    }, 'getImprovementArea');
  }
  async updateImprovementArea(
    id: string,
    dataToUpdate: Partial<ImprovementAreaType>,
  ): Promise<ImprovementAreaType> {
    return this.makeAuthorizedRequest<ImprovementAreaType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.improvementAreasUpdate(
        id,
        dataToUpdate,
        params,
      );
      return AdminApi.mapImprovementAreasResponse(data.data);
    }, 'updateImprovementArea');
  }

  async deleteImprovementArea(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.adminPanel.improvementAreasDelete(id, params);
    }, 'deleteImprovementArea');
  }
  async getGroupsAffirmationCategory({
    query,
  }: {
    query?: {
      sort_type?: SORT_ORDER;
      sort_by?: 'created_at' | 'updated_at';
      page?: number;
      'per-page'?: number;
    };
  }): Promise<{
    data: GroupAffirmationCategoryType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: GroupAffirmationCategoryType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.groupsList(query, params);

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const groups = data.data;
      const meta = data.meta;
      return {
        data: groups.map((item) =>
          AdminApi.mapGroupAffirmationCategoryResponse(item),
        ),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getGroupsAffirmationCategory');
  }

  async getGroupAffirmationCategory(
    id: string,
  ): Promise<GroupAffirmationCategoryType> {
    return this.makeAuthorizedRequest<GroupAffirmationCategoryType>(
      async (params) => {
        const { data } = await baseApiClient.adminPanel.groupsDetail(
          id,
          params,
        );

        return AdminApi.mapGroupAffirmationCategoryResponse(data.data);
      },
      'getGroupAffirmationCategory',
    );
  }

  async updateGroupAffirmationCategory(
    id: string,
    dataToUpdate: Partial<GroupAffirmationCategoryType>,
  ): Promise<GroupAffirmationCategoryType> {
    return this.makeAuthorizedRequest<GroupAffirmationCategoryType>(
      async (params) => {
        const { data } = await baseApiClient.adminPanel.groupsUpdate(
          id,
          dataToUpdate,
          params,
        );

        return AdminApi.mapGroupAffirmationCategoryResponse(data.data);
      },
      'updateGroupAffirmationCategory',
    );
  }

  async deleteGroupAffirmationCategory(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.adminPanel.groupsDelete(id, params);
    }, 'updateGroupAffirmationCategory');
  }

  async createGroupAffirmationCategory(dataToCreate: {
    name: string;
    improvement_area_id: string;
  }): Promise<GroupAffirmationCategoryType> {
    return this.makeAuthorizedRequest<GroupAffirmationCategoryType>(
      async (params) => {
        const { data } = await baseApiClient.adminPanel.groupsCreate(
          dataToCreate,
          params,
        );
        return AdminApi.mapGroupAffirmationCategoryResponse(data.data);
      },
      'createGroupAffirmationCategoryType',
    );
  }

  async getAffirmationCategories({
    query,
  }: {
    query?: {
      sort_type?: SORT_ORDER;
      sort_by?: 'created_at' | 'updated_at';
      page?: number;
      'per-page'?: number;
    };
  }): Promise<{
    data: AffirmationCategoryType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: AffirmationCategoryType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.categoriesList(
        query,
        params,
      );

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const categories = data.data;
      const meta = data.meta;
      return {
        data: categories.map((item) =>
          AdminApi.mapAffirmationCategoryResponse(item),
        ),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getAffirmationCategories');
  }

  async createAffirmationCategory(
    dataToCreate: CreateAffirmationCategoryType,
  ): Promise<AffirmationCategoryType> {
    return this.makeAuthorizedRequest<AffirmationCategoryType>(
      async (params) => {
        const { data } = await baseApiClient.adminPanel.categoriesCreate(
          dataToCreate,
          params,
        );
        return AdminApi.mapAffirmationCategoryResponse(data.data);
      },
      'createGroupAffirmationCategoryType',
    );
  }

  async getAffirmationCategory(id: string): Promise<AffirmationCategoryType> {
    return this.makeAuthorizedRequest<AffirmationCategoryType>(
      async (params) => {
        const { data } = await baseApiClient.adminPanel.categoriesDetail(
          id,
          params,
        );
        return AdminApi.mapAffirmationCategoryResponse(data.data);
      },
      'getAffirmationCategory',
    );
  }

  async updateAffirmationCategory(
    id: string,
    dataToUpdate: Partial<AffirmationCategoryType>,
  ): Promise<AffirmationCategoryType> {
    return this.makeAuthorizedRequest<AffirmationCategoryType>(
      async (params) => {
        const { data } = await baseApiClient.adminPanel.categoriesUpdate(
          id,
          dataToUpdate,
          params,
        );
        return AdminApi.mapAffirmationCategoryResponse(data.data);
      },
      'updateAffirmationCategory',
    );
  }

  async deleteAffirmationCategory(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.adminPanel.categoriesDelete(id, params);
    }, 'deleteAffirmationCategory');
  }
  async getAffirmations({
    query,
  }: {
    query?: {
      sort_type?: SORT_ORDER;
      sort_by?: 'created_at' | 'updated_at';
      page?: number;
      'per-page'?: number;
    };
  }): Promise<{
    data: AffirmationType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: AffirmationType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.affirmationsList(
        query,
        params,
      );

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const affirmations = data.data;
      const meta = data.meta;
      return {
        data: affirmations.map((item) => AdminApi.mapAffirmationResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getAffirmations');
  }

  async createAffirmation(
    dataToCreate: CreateAffirmationType,
  ): Promise<AffirmationType> {
    return this.makeAuthorizedRequest<AffirmationType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.affirmationsCreate(
        dataToCreate,
        params,
      );
      return AdminApi.mapAffirmationResponse(data.data);
    }, 'createAffirmation');
  }

  async getAffirmation(id: string): Promise<AffirmationType> {
    return this.makeAuthorizedRequest<AffirmationType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.affirmationsDetail(
        id,
        params,
      );
      return AdminApi.mapAffirmationResponse(data.data);
    }, 'getAffirmation');
  }

  async updateAffirmation(
    id: string,
    dataToUpdate: Partial<AffirmationType>,
  ): Promise<AffirmationType> {
    return this.makeAuthorizedRequest<AffirmationType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.affirmationsUpdate(
        id,
        dataToUpdate,
        params,
      );
      return AdminApi.mapAffirmationResponse(data.data);
    }, 'updateAffirmation');
  }

  async deleteAffirmation(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.adminPanel.affirmationsDelete(id, params);
    }, 'deleteAffirmation');
  }

  async uploadMediaFile(file: File): Promise<string> {
    return 'https://images.freeimages.com/images/previews/ac9/railway-hdr-1361893.jpg';
  }

  async uploadThemeBackground(file: File): Promise<CreateThemeBackgroundType> {
    return this.makeAuthorizedRequest<CreateThemeBackgroundType>(
      async (params) => {
        const { data } = await baseApiClient.adminPanel.uploadMediaFileCreate(
          { file },
          params,
        );
        return AdminApi.mapThemeBackgroundResponse(data.data);
      },
      'createThemeBackground',
    );
  }

  async removeFile(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      // await baseApiClient.coach.profileAvatarDelete(params);
    }, 'removeFile');
  }

  async getTheme(id: string): Promise<ThemeType> {
    return this.makeAuthorizedRequest<ThemeType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themesDetail(id, params);
      return AdminApi.mapThemeResponse(data.data);
    }, 'getTheme');
  }

  async getThemes({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at' | 'category_id';
      page: number;
      'per-page': number;
      category_id?: string;
      name?: string;
    };
  }): Promise<{
    data: ThemeType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ThemeType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themesList(query, params);

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const themes = data.data;
      const meta = data.meta;
      return {
        data: themes.map((item) => AdminApi.mapThemeResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getThemes');
  }

  async updateTheme(
    id: string,
    dataToUpdate: Partial<ThemeType>,
  ): Promise<ThemeType> {
    return this.makeAuthorizedRequest<ThemeType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themesUpdate(
        id,
        {
          ...dataToUpdate,
          theme_category_id: dataToUpdate.theme_category_id,
        },
        params,
      );
      return AdminApi.mapThemeResponse(data.data);
    }, 'updateTheme');
  }

  async createTheme(dataToCreate: CreateThemeDtoType): Promise<ThemeType> {
    return this.makeAuthorizedRequest<ThemeType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themesCreate(
        {
          ...dataToCreate,
          theme_category_id: dataToCreate.theme_category_id,
        },
        params,
      );
      return AdminApi.mapThemeResponse(data.data);
    }, 'createTheme');
  }

  async deleteTheme(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.adminPanel.themesDelete(id, params);
    }, 'deleteTheme');
  }

  async getThemeCategory(id: string): Promise<ThemeCategoryType> {
    return this.makeAuthorizedRequest<ThemeCategoryType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themeCategoriesDetail(
        id,
        params,
      );
      return AdminApi.mapThemesCategoryResponse(data.data);
    }, 'getThemeCategory');
  }

  async getThemeCategories({
    query,
  }: {
    query: {
      sort_type?: SORT_ORDER;
      sort_by?: 'created_at' | 'updated_at';
      page?: number;
      'per-page'?: number;
    };
  }): Promise<{
    data: ThemeCategoryType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ThemeCategoryType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themeCategoriesList(
        query,
        params,
      );

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const categories = data.data;
      const meta = data.meta;
      return {
        data: categories.map((item) =>
          AdminApi.mapThemesCategoryResponse(item),
        ),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getThemeCategories');
  }

  async updateThemeCategory(
    id: string,
    dataToUpdate: Partial<ThemeCategoryType>,
  ): Promise<ThemeCategoryType> {
    return this.makeAuthorizedRequest<ThemeCategoryType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themeCategoriesUpdate(
        id,
        dataToUpdate,
        params,
      );
      return AdminApi.mapThemesCategoryResponse(data.data);
    }, 'updateThemeCategory');
  }

  async createThemeCategory(
    dataToCreate: Partial<CreateThemeCategoryDtoType>,
  ): Promise<ThemeCategoryType> {
    return this.makeAuthorizedRequest<ThemeCategoryType>(async (params) => {
      const { data } = await baseApiClient.adminPanel.themeCategoriesCreate(
        dataToCreate,
        params,
      );
      return AdminApi.mapThemesCategoryResponse(data.data);
    }, 'createThemeCategory');
  }

  async deleteThemeCategory(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.adminPanel.themeCategoriesDelete(id, params);
    }, 'deleteThemeCategory');
  }

  async getUsers({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: UserType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: UserType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.adminPanel.usersList(query, params);

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const users = data.data;
      const meta = data.meta;
      return {
        data: users.map((item) => AdminApi.mapUserResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getUsers');
  }

  async createPlan(dataToCreate: Partial<PlanType>): Promise<PlanType> {
    return {
      id: '1',
      name: 'plan1',
      is_visible: false,
      is_premium: true,
      days_count: 2,
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async updatePlan(
    id: string,
    dataToUpdate: Partial<PlanType>,
  ): Promise<PlanType> {
    return {
      id: '1',
      name: 'plan1',
      is_visible: false,
      is_premium: true,
      days_count: 2,
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async getPlan(id: string): Promise<PlanType> {
    return {
      id: '1',
      name: 'plan1',
      is_visible: false,
      is_premium: true,
      days_count: 2,
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async getPlans({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: PlanType[];
    meta: MetaDataResponseType;
  }> {
    return {
      data: [
        {
          id: 'plan11',
          name: 'plan1',
          is_visible: false,
          is_premium: true,
          days_count: 2,
          created_at: '2021-01-01',
          updated_at: '2021-01-01',
        },
        {
          id: 'plan12',
          name: 'plan2',
          is_visible: false,
          is_premium: true,
          days_count: 4,
          created_at: '2021-01-01',
          updated_at: '2021-01-01',
        },
      ],
      meta: {
        currentPage: 1,
        pageSize: 20,
        totalCount: 2,
        totalPages: 1,
      },
    };
  }

  async createPlanDay(
    dataToCreate: Partial<PlanDayType>,
  ): Promise<PlanDayType> {
    return {
      id: '1',
      title: 'day1',
      week: 'first',
      position: 1,
      affirmations_count: 2,
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async updatePlanDay(
    id: string,
    dataToUpdate: Partial<PlanDayType>,
  ): Promise<PlanDayType> {
    return {
      id: '1',
      title: 'day1',
      week: 'first',
      position: 1,
      affirmations_count: 2,
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async getPlanDay(id: string): Promise<PlanDayType> {
    return {
      id: '1',
      title: 'day1',
      week: 'first',
      position: 1,
      affirmations_count: 2,
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async getPlanDays({
    planId,
    query,
  }: {
    planId: string;
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: PlanDayType[];
    meta: MetaDataResponseType;
  }> {
    return {
      data: [
        {
          id: 'day11',
          title: 'day1',
          week: 'first',
          position: 1,
          affirmations_count: 2,
          created_at: '2021-01-01',
          updated_at: '2021-01-01',
        },
        {
          id: 'day12',
          title: 'day2',
          week: 'first',
          position: 1,
          affirmations_count: 2,
          created_at: '2021-01-01',
          updated_at: '2021-01-01',
        },
      ],
      meta: {
        currentPage: 1,
        pageSize: 20,
        totalCount: 2,
        totalPages: 1,
      },
    };
  }

  async createPlanAffirmation(
    dataToCreate: Partial<PlanAffirmationType>,
  ): Promise<PlanAffirmationType> {
    return {
      id: '1',
      name: 'aff1',
      text: 'text',
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async updatePlanAffirmation(
    id: string,
    dataToUpdate: Partial<PlanAffirmationType>,
  ): Promise<PlanAffirmationType> {
    return {
      id: '1',
      name: 'aff1',
      text: 'text',
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async getPlanAffirmation(id: string): Promise<PlanAffirmationType> {
    return {
      id: '1',
      name: 'aff1',
      text: 'text',
      created_at: '2021-01-01',
      updated_at: '2021-01-01',
    };
  }

  async getPlanAffirmations({
    dayId,
    query,
  }: {
    dayId: string;
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: PlanAffirmationType[];
    meta: MetaDataResponseType;
  }> {
    return {
      data: [
        {
          id: '1',
          name: 'aff1',
          text: 'text',
          created_at: '2021-01-01',
          updated_at: '2021-01-01',
        },
        {
          id: '2',
          name: 'aff2',
          text: 'text',
          created_at: '2021-01-01',
          updated_at: '2021-01-01',
        },
      ],
      meta: {
        currentPage: 1,
        pageSize: 20,
        totalCount: 2,
        totalPages: 1,
      },
    };
  }
}

const accessToken = localStorage.getByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY);
const refreshToken = localStorage.getByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY);

export default new AdminApi({
  accessToken,
  refreshToken,
  storage: localStorage,
});
