import Resizer from 'react-image-file-resizer';

export const resizeFile = (
  file: Blob,
  maxWidth: number,
  maxHeight: number,
  quality: number | undefined = 100,
): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      quality,
      0,
      (uri) => {
        resolve(uri as File);
      },
      'file',
    );
  });
