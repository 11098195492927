import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import ThemeCategoriesItem from '~/modules/themeCategories/components/ThemeCategoriesItem';
import ThemeCategoriesList from '~/modules/themeCategories/components/ThemeCategoriesList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'ThemeCategories';

export const ThemeCategoriesListRoute = {
  path: '/theme-categories',
  Component: withSidebarLayout(ThemeCategoriesList),
  title: 'Theme Categories',
  exact: true,
  permissionKey,
};

export const ThemeCategoriesItemRoute = {
  path: '/theme-categories/:id',
  Component: withSidebarLayout(ThemeCategoriesItem),
  title: 'Category Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  c: [
    {
      path: '/theme-categories',
      title: 'Theme Categories',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([ThemeCategoriesListRoute, ThemeCategoriesItemRoute]);
