import React, { lazy } from 'react';

type DynamicImportType = () => Promise<any>; // TODO right better types
type LazyComponentType = React.LazyExoticComponent<React.ComponentType<any>>;

const lazyComponentLoader = (
  component: DynamicImportType,
): LazyComponentType => {
  return lazy(() => {
    return component().catch((e) => {
      console.error(e);
    });
  });
};

export default lazyComponentLoader;
