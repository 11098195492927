import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import { ImprovementAreaType } from '~/modules/improvementAreas/types';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
  improvement_area_id: string;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    improvement_area_id: yup.string().required('Required'),
  })
  .required();

type Props = {
  onClose: () => void;
  addGroup: (data: {
    name: string;
    improvement_area_id: string;
  }) => Promise<void>;
};

const CreateForm: FC<Props> = ({ onClose, addGroup }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [areas, setAreas] = useState<ImprovementAreaType[]>([]);
  const { showSnackbar } = useSnackBarContext();

  const loadAreas = async (): Promise<void> => {
    try {
      const areas = await Api.getImprovementAreas({
        query: { 'per-page': 1000 },
      });
      setAreas(areas.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadAreas();
  }, []);

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      improvement_area_id: '',
    },
  });

  const { handleSubmit, formState } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      await addGroup(data);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.main_container}>
      <ModalContentTitle text="New Group" />
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="name"
              label="Group Name"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormSelectField
              required
              name="improvement_area_id"
              label="Improvement area"
              disabled={isLoading}
              fullWidth
              options={areas.map(({ name, id }) => ({
                text: name,
                value: id,
              }))}
            />
          </div>
          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Group
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateForm;
