import React, { FC } from 'react';

import { Route as DefaultRoute, Redirect, useLocation } from 'react-router-dom';

import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';

interface Props {
  path: string;
  exact?: boolean;
  title: string;
  Component: FC;
}

const AuthRoute: FC<Props> = ({ path, exact, Component }) => {
  const user = useAppSelector((state) => state.profile);

  const location = useLocation<{ from?: string }>();

  const redirectPath =
    location.state && location.state.from
      ? location.state.from
      : router.getDefaultPrivateRoutePath();

  return (
    <DefaultRoute
      path={path}
      exact={exact}
      render={({ location }): JSX.Element => {
        return user.isLoggedIn ? (
          <>
            {redirectPath ? (
              <Redirect
                to={{
                  pathname: redirectPath,
                  state: { from: location.pathname },
                }}
              />
            ) : null}
          </>
        ) : (
          <Component />
        );
      }}
    />
  );
};

export default AuthRoute;
