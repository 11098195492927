// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KP1hLM1EeaFp9TVG9oAQ{display:flex;flex-wrap:nowrap;justify-content:space-between;padding:16px 8px;align-items:center}.rtMrSrx1imqEBgxeeOSz{color:#3d98ab !important}.xkD5Ek7nvgIFs2JXXEun{color:#c6c8cf !important}", "",{"version":3,"sources":["webpack://./src/components/molecules/Pagination/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,gBAAA,CACA,6BAAA,CACA,gBAAA,CACA,kBAAA,CAGF,sBACE,wBAAA,CAEA,sBACE,wBAAA","sourcesContent":["@import \"src/styles/colors\";\n\n.container {\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  padding: 16px 8px;\n  align-items: center;\n}\n\n.page {\n  color: $primary-500 !important;\n\n  &__muted {\n    color: $neutral-500 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "KP1hLM1EeaFp9TVG9oAQ",
	"page": "rtMrSrx1imqEBgxeeOSz",
	"page__muted": "xkD5Ek7nvgIFs2JXXEun"
};
export default ___CSS_LOADER_EXPORT___;
