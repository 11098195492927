import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import Button from '~/components/atoms/buttons/Button';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Pagination from '~/components/molecules/Pagination';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import TotalCount from '~/components/molecules/TotalCount';
import useFilters from '~/hooks/useFilters';
import useQueryParams from '~/hooks/useQueryParams';
import Table from '~/modules/themes/components/ThemesList/Table';
import useData, {
  FiltersType,
} from '~/modules/themes/components/ThemesList/useData';
import { SearchFormDtoType } from '~/modules/themes/types';
import { SORT_ORDER } from '~/types/common';

import { ThemesCreateRoute, ThemesListRoute } from '../..';
import styles from './styles.modules.scss';

type FormDataType = SearchFormDtoType;

const DEFAULT_FILTER_STATE: FiltersType = {
  sortOrder: SORT_ORDER.DESC,
  sortBy: 'created_at',
  page: 1,
  'per-page': 10,
};

const formSchema = yup
  .object()
  .shape({
    search: yup.string(),
  })
  .required();

const ThemesList: FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const { getQueryParams, setQueryParams } = useQueryParams();

  const defaultFilterState = {
    ...DEFAULT_FILTER_STATE,
    ...getQueryParams(),
  };

  const { filters, updateFilter } = useFilters<FiltersType>(defaultFilterState);

  const { themes, meta } = useData(filters);

  const { showSnackbar } = useSnackBarContext();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  const { handleSubmit } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      history.push(`${ThemesListRoute.path}/?name=${data.search}`);
      window.location.reload();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setQueryParams(filters);
  }, [filters]);

  return (
    <div
      className={clsx(
        { [styles.loading]: isLoading },
        styles.vertical_flex_container,
        styles['vertical_flex_container--full_height'],
      )}
    >
      <TopHeadlineRow className={styles.top_bar} text="Themes">
        <Button
          variant="outlined"
          onClick={(): void => {
            history.push(ThemesCreateRoute.path);
          }}
        >
          <Icon
            style={{ marginRight: 5 }}
            fontSize={20}
            icon="material-symbols:person-add-rounded"
          />
          New Theme
        </Button>
      </TopHeadlineRow>
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div className={styles.search_container}>
            <HookFormTextField
              required
              name="search"
              label="Search"
              disabled={isLoading}
            />

            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
            >
              Search
            </Button>

            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => {
                history.push(ThemesListRoute.path);
                window.location.reload();
              }}
            >
              Clear search
            </Button>
          </div>
        </form>
      </FormProvider>
      <div
        className={clsx(
          styles.container,
          styles.vertical_flex_container__full_parent_height,
        )}
      >
        <div className={styles.container__left}>
          <TotalCount count={meta.totalCount} />
          <div
            className={clsx(
              styles.vertical_flex_container,
              styles['vertical_flex_container--full_height'],
            )}
          >
            <div className={styles.vertical_flex_container__full_parent_height}>
              <Table
                themes={themes}
                filter={{
                  sortBy: filters.sortBy,
                  sortOrder: filters.sortOrder,
                }}
                applyFilters={(filters): void => updateFilter(filters)}
              />
            </div>
            {meta.totalPages > 1 && (
              <Pagination
                currentPage={Number(meta.currentPage)}
                totalPages={Number(meta.totalPages)}
                setPage={(page: number): void => {
                  updateFilter({ page });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemesList;
