import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import AffirmationCategoryItem from '~/modules/affirmationCategories/components/AffirmationCategoryItem';
import AffirmationCategoryItemList from '~/modules/affirmationCategories/components/AffirmationCategoryList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'AffirmationCategories';

export const AffirmationCategoryListRoute = {
  path: '/affirmation-category',
  Component: withSidebarLayout(AffirmationCategoryItemList),
  title: 'Affirmation Categories',
  exact: true,
  permissionKey,
};

export const AffirmationCategoryItemRoute = {
  path: '/affirmation-category/:id',
  Component: withSidebarLayout(AffirmationCategoryItem),
  title: 'Affirmation Category Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  a: [
    {
      path: '/affirmation-category',
      title: 'Affirmation Categories',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([
  AffirmationCategoryListRoute,
  AffirmationCategoryItemRoute,
]);
