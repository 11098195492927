// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DuSsyCGm3WGx_sGqxb7X{opacity:.8;pointer-events:none}.NjzSBrroUIlhegsFR_pg{display:flex;flex-direction:column}.QL0Jjg2f06HnWSIF26r4{height:100%}.Yo16zHkCBqZk17X6K98g{flex:1}.O4lZHFNHqYA0PHvy2otW{border-bottom:1px solid #e5e6e9}.PoyMNUYTZHXuiViZgHPp{display:flex;justify-content:space-between;flex-wrap:nowrap;width:100%;padding:18px 40px 0}.zAY2vDzV42VSzPuKI3KT{display:flex;flex-direction:column;flex:1;overflow-x:hidden}.pVpbnAcfcT94MRY9n93Q{min-width:230px}.sg5n0mM0rhgI8o429GSX{font-size:15px;font-weight:700;display:block;margin-bottom:10px}.fkZ2DDU8sy_fJr6CLe89{margin-right:25px;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/products/components/ProductsList/styles.modules.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAEA,sBACE,WAAA,CAGF,sBACE,MAAA,CAIJ,sBACE,+BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,UAAA,CACA,mBAAA,CAEA,sBACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CAIJ,sBACE,iBAAA,CACA,WAAA","sourcesContent":["@import 'src/styles/colors';\n@import \"src/styles/fonts\";\n\n.loading {\n  opacity: .8;\n  pointer-events: none;\n}\n\n.vertical_flex_container {\n  display: flex;\n  flex-direction: column;\n\n  &--full_height {\n    height: 100%;\n  }\n\n  &__full_parent_height {\n    flex: 1;\n  }\n}\n\n.top_bar {\n  border-bottom: 1px solid $neutral-200;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  width: 100%;\n  padding: 18px 40px 0;\n\n  &__left {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    overflow-x: hidden;\n  }\n\n  &__right {\n    min-width: 230px;\n  }\n\n  &__headline {\n    font-size: 15px;\n    font-weight: 700;\n    display: block;\n    margin-bottom: 10px\n  }\n}\n\n.table_container {\n  margin-right: 25px;\n  height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "DuSsyCGm3WGx_sGqxb7X",
	"vertical_flex_container": "NjzSBrroUIlhegsFR_pg",
	"vertical_flex_container--full_height": "QL0Jjg2f06HnWSIF26r4",
	"vertical_flex_container__full_parent_height": "Yo16zHkCBqZk17X6K98g",
	"top_bar": "O4lZHFNHqYA0PHvy2otW",
	"container": "PoyMNUYTZHXuiViZgHPp",
	"container__left": "zAY2vDzV42VSzPuKI3KT",
	"container__right": "pVpbnAcfcT94MRY9n93Q",
	"container__headline": "sg5n0mM0rhgI8o429GSX",
	"table_container": "fkZ2DDU8sy_fJr6CLe89"
};
export default ___CSS_LOADER_EXPORT___;
