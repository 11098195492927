// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bTtCk2O7NbazvNp4MuYs{display:block;font-family:\"Source Serif Pro\",sans-serif;font-weight:600;font-size:28px;line-height:36px;text-align:left;margin-bottom:16px}@media(max-width: 768px){.bTtCk2O7NbazvNp4MuYs{font-size:24px;line-height:31px}}", "",{"version":3,"sources":["webpack://./src/components/atoms/ModalContentTitle/styles.module.scss","webpack://./src/styles/_fonts.scss","webpack://./src/styles/_breakpoints.scss"],"names":[],"mappings":"AAGA,sBACE,aAAA,CACA,yCCLqB,CDMrB,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CERE,yBFCJ,sBAUI,cAAA,CACA,gBAAA,CAAA","sourcesContent":["@import \"src/styles/fonts\";\n@import \"src/styles/breakpoints\";\n\n.title {\n  display: block;\n  font-family: $headings-font-family;\n  font-weight: 600;\n  font-size: 28px;\n  line-height: 36px;\n  text-align: left;\n  margin-bottom: 16px;\n\n  @include breakpoint(xs) {\n    font-size: 24px;\n    line-height: 31px;\n  }\n}","$headings-font-family: 'Source Serif Pro', sans-serif;\n$content-font-family: 'Source Sans Pro', sans-serif;\n","@mixin breakpoint($class) {\n  @if $class == xs {\n    @media (max-width: 768px) {\n      @content;\n    }\n  }\n\n  @if $class == md {\n    @media (max-width: 992px) {\n      @content;\n    }\n  }\n\n  @if $class == xl {\n    @media (min-width: 1536px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "bTtCk2O7NbazvNp4MuYs"
};
export default ___CSS_LOADER_EXPORT___;
