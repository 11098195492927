import React, { FC, useState } from 'react';

import { Icon } from '@iconify/react';
import { Drawer } from '@mui/material';

import IconButton from '~/components/atoms/buttons/IconButton';

import styles from './styles.module.scss';

type Props = {
  Sidebar: FC<{ onClickItem?: () => void }>;
};

const MobileLayout: FC<Props> = ({ children, Sidebar }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={styles.mobile_layout}>
        <div>
          <IconButton
            className={styles.mobile_layout__burger_button}
            onClick={(): void => setIsOpen(true)}
          >
            <Icon icon="charm:menu-hamburger" />
          </IconButton>
        </div>
        <div className={styles.mobile_layout__main}>{children}</div>
      </div>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={(): void => setIsOpen(false)}
      >
        <div className={styles.mobile_layout__sidebar}>
          <Sidebar onClickItem={(): void => setIsOpen(false)} />
        </div>
      </Drawer>
    </>
  );
};

export default MobileLayout;
