import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import ProductItem from '~/modules/products/components/ProductItem';
import ProductList from '~/modules/products/components/ProductsList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'PRODUCTS';

export const ProductsListRoute = {
  path: '/product',
  Component: withSidebarLayout(ProductList),
  title: 'Products',
  exact: true,
  permissionKey,
};

export const ProductsItemRoute = {
  path: '/product/:id',
  Component: withSidebarLayout(ProductItem),
  title: 'Product Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: '/product',
      title: 'Products',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([ProductsListRoute, ProductsItemRoute]);
