import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  Sidebar: FC;
};

const DesktopLayout: FC<Props> = ({ children, Sidebar }) => {
  return (
    <div className={styles.desktop_layout}>
      <div className={styles.desktop_layout__sidebar}>
        <Sidebar />
      </div>
      <div className={styles.desktop_layout__main}>{children}</div>
    </div>
  );
};

export default DesktopLayout;
