import React, { ChangeEvent, FC, useRef } from 'react';

import Button, { ButtonProps } from '~/components/atoms/buttons/Button';

export interface FileUploadButtonProps
  extends Omit<ButtonProps, 'value' | 'onChange' | 'errorMessage'> {
  disabled?: boolean;
  name?: string;
  value?: string | ReadonlyArray<string> | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  onClick?: () => void;
}

const FileUploadButton: FC<FileUploadButtonProps> = ({
  onChange,
  disabled = false,
  name,
  value,
  onClick,
  children,
  size,
  variant,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFileSelection = (): void => {
    onClick && onClick();
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };
  return (
    <label>
      <Button
        onClick={handleFileSelection}
        disabled={disabled}
        variant={variant}
        size={size}
      >
        {children}
      </Button>
      <input
        name={name}
        value={value}
        onChange={onChange}
        ref={inputFileRef}
        type="file"
        hidden
      />
    </label>
  );
};

export default FileUploadButton;
