// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hg6gIPpVIR2Bj4aDnTtC{opacity:.8;pointer-events:none}.jhZLTebVV_qieUwL5Oel{display:flex;flex-direction:column}.g6OgBhGY8PUTt9seibuM{height:100%}.SBxAVR0edbgsdyqdXhKj{flex:1}.aNBGwE8nRz1_PyvJsMt2{border-bottom:1px solid #e5e6e9}.Y7GDvlyvFn0vkG46Nl1Y{display:flex;justify-content:space-between;flex-wrap:nowrap;width:100%;padding:18px 40px 0}.jDyAgS_ieMX900nodWet{display:flex;flex-direction:column;flex:1;overflow-x:hidden}.shQnLOLGO7V4LjLJv9li{min-width:230px}.AgeUOMmE1wq8FThgjUDb{font-size:15px;font-weight:700;display:block;margin-bottom:10px}.oKkC0XyEh9PfR1keqWzm{margin-right:25px;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/plans/components/PlansList/styles.modules.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAEA,sBACE,WAAA,CAGF,sBACE,MAAA,CAIJ,sBACE,+BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,UAAA,CACA,mBAAA,CAEA,sBACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CAIJ,sBACE,iBAAA,CACA,WAAA","sourcesContent":["@import 'src/styles/colors';\n@import \"src/styles/fonts\";\n\n.loading {\n  opacity: .8;\n  pointer-events: none;\n}\n\n.vertical_flex_container {\n  display: flex;\n  flex-direction: column;\n\n  &--full_height {\n    height: 100%;\n  }\n\n  &__full_parent_height {\n    flex: 1;\n  }\n}\n\n.top_bar {\n  border-bottom: 1px solid $neutral-200;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  width: 100%;\n  padding: 18px 40px 0;\n\n  &__left {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    overflow-x: hidden;\n  }\n\n  &__right {\n    min-width: 230px;\n  }\n\n  &__headline {\n    font-size: 15px;\n    font-weight: 700;\n    display: block;\n    margin-bottom: 10px\n  }\n}\n\n.table_container {\n  margin-right: 25px;\n  height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "Hg6gIPpVIR2Bj4aDnTtC",
	"vertical_flex_container": "jhZLTebVV_qieUwL5Oel",
	"vertical_flex_container--full_height": "g6OgBhGY8PUTt9seibuM",
	"vertical_flex_container__full_parent_height": "SBxAVR0edbgsdyqdXhKj",
	"top_bar": "aNBGwE8nRz1_PyvJsMt2",
	"container": "Y7GDvlyvFn0vkG46Nl1Y",
	"container__left": "jDyAgS_ieMX900nodWet",
	"container__right": "shQnLOLGO7V4LjLJv9li",
	"container__headline": "AgeUOMmE1wq8FThgjUDb",
	"table_container": "oKkC0XyEh9PfR1keqWzm"
};
export default ___CSS_LOADER_EXPORT___;
