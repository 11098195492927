import React, { FC } from 'react';

import {
  TableHead as MuiTableHead,
  styled,
  TableHeadProps,
} from '@mui/material';

const TableHeadStyled = styled(MuiTableHead)(({ theme }) => ({
  '&': {
    background: theme.colors['neutral_100'],
    borderBottom: `1px solid ${theme.colors['neutral_100']}`,
    //borderRadius: '8px 8px 0px 0px',
    padding: '16px 24px',
    color: theme.colors.black,
  },

  '.MuiTableCell-head': {
    '&:first-child': {
      paddingLeft: '24px',
    },

    '&:last-child': {
      paddingRight: '24px',
    },
  },
}));

const TableHead: FC<TableHeadProps> = ({ children, ...props }) => {
  return <TableHeadStyled {...props}>{children}</TableHeadStyled>;
};

export default TableHead;
