import React, { FC } from 'react';

import { Route as DefaultRoute, Redirect } from 'react-router-dom';

import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';

interface Props {
  path: string;
  exact?: boolean;
  title: string;
  Component: FC;
}

const PrivateRoute: FC<Props> = ({ path, exact, Component }) => {
  const user = useAppSelector((state) => state.profile);
  const signInPath = router.getSignInRoutePath();

  return (
    <DefaultRoute path={path} exact={exact}>
      {user.isLoggedIn ? (
        <>
          <Component />
        </>
      ) : (
        <Redirect to={signInPath} />
      )}
    </DefaultRoute>
  );
};

export default PrivateRoute;
