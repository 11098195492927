import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import useIsMobile from '~/hooks/useIsMobile';
import { AffirmationListRoute } from '~/modules/affirmation';
import { ProductsListRoute } from '~/modules/products';
import { ProductType } from '~/modules/products/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = {
  product_id: string;
  product_name: string;
};

const formSchema = yup
  .object()
  .shape({
    product_id: yup.string().required('Required'),
    product_name: yup.string().required('Required'),
  })
  .required();

const ProductItem: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<ProductType>({
    id: '',
    product_id: '',
    product_name: '',
    created_at: '2022-11-11 11:11:11',
    updated_at: '2022-11-11 11:11:11',
  });
  const isMobile = useIsMobile();
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      product_id: '',
      product_name: '',
    },
  });

  const { handleSubmit, setValue } = form;

  const load = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      const product = await Api.getProduct(id);
      setProduct(product);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const product = await Api.updateProduct(id, data);
      showSnackbar('Updated', 'success');
      setProduct(product);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleRemove = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.deleteProduct(id);
      showSnackbar('removed', 'success');
      setIsLoading(false);
      history.push(ProductsListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    load(id).finally(() => setIsFirstLoading(false));
  }, []);

  useEffect(() => {
    setValue('product_id', product.product_id);
    setValue('product_name', product.product_name);
  }, [product]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to Search"
        onClick={(): void => {
          history.push(ProductsListRoute.path);
        }}
      />
      <TopHeadlineRow className={styles.top_bar} text="Product card" />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          <Tabs
            className={styles.container__tabs}
            items={[
              {
                label: 'General info',
                isActive: true,
              },
            ]}
            onClickItem={(): void => {}}
          />
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="product_id"
                    label="Product ID"
                    disabled={isLoading}
                    fullWidth
                    size={isMobile ? 'small' : 'medium'}
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="product_name"
                    label="Product name"
                    disabled={isLoading}
                    fullWidth
                    size={isMobile ? 'small' : 'medium'}
                  />
                </div>

                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(product.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(product.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.buttons_container}>
                  <Button
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                    type="submit"
                    disabled={isLoading}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(handleSuccessSubmit)}
                  >
                    Update info
                  </Button>
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    variant="contained"
                    onClick={handleRemove}
                  >
                    Delete
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default ProductItem;
