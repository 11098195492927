import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';

import IconButton from '~/components/atoms/buttons/IconButton';

import styles from './styles.module.scss';

type Props = {
  currentPage: number;
  totalPages: number;
  setPage: (number) => void;
  disabled?: boolean;
};

const MAX_BUTTONS_COUNT = 5;

const generateVisibleButtons = (
  currentPage: number,
  totalPages: number,
): number[] => {
  if (totalPages <= MAX_BUTTONS_COUNT) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const edge = Math.ceil(MAX_BUTTONS_COUNT / 2);

  const a = currentPage - edge;
  const startIndex = a > 0 ? a : 0; // need small refactor
  if (totalPages - edge < currentPage) {
    return Array.from(
      { length: MAX_BUTTONS_COUNT },
      (_, i) => totalPages - MAX_BUTTONS_COUNT + i + 1,
    );
  }
  return Array.from(
    { length: MAX_BUTTONS_COUNT },
    (_, i) => startIndex + i + 1,
  );
};

const Pagination: FC<Props> = ({
  currentPage,
  totalPages,
  setPage,
  disabled,
}) => {
  const buttonPagesArray = generateVisibleButtons(currentPage, totalPages - 1);
  return (
    <div className={styles.container}>
      <IconButton
        onClick={(): void => setPage(currentPage - 1)}
        disabled={currentPage === 1 || disabled}
        variant="outlined"
        size="small"
      >
        <Icon icon="material-symbols:chevron-left" />
      </IconButton>
      <div>
        {buttonPagesArray.map((page, i) => {
          const isMuted = page !== currentPage;
          // if (page !== totalPages) {
          return (
            <IconButton
              key={i}
              className={clsx(styles.page, {
                [styles.page__muted]: isMuted,
              })}
              onClick={(): void => setPage(page)}
              size="small"
            >
              {page}
            </IconButton>
          );
          // }
          // return null;
        })}

        {buttonPagesArray.length &&
        buttonPagesArray[buttonPagesArray.length - 1] !== totalPages - 1 ? (
          <IconButton
            className={clsx(styles.page, styles.page__muted)}
            size="small"
          >
            ...
          </IconButton>
        ) : null}

        <IconButton
          className={clsx(styles.page, {
            [styles.page__muted]: currentPage !== totalPages,
          })}
          size="small"
          onClick={(): void => setPage(totalPages)}
        >
          {totalPages}
        </IconButton>
      </div>
      <IconButton
        onClick={(): void => setPage(currentPage + 1)}
        disabled={currentPage >= totalPages || disabled}
        variant="outlined"
        size="small"
      >
        <Icon icon="material-symbols:chevron-right" />
      </IconButton>
    </div>
  );
};

export default Pagination;
