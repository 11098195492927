import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { PlanAffirmationType } from '~/modules/plans/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
  text: string;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    text: yup.string().required('Required'),
  })
  .required();

const Item: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [affirmation, setAffirmation] = useState<PlanAffirmationType>({
    id: '',
    name: '',
    text: '',
    created_at: '2021-01-01',
    updated_at: '2021-01-01',
  });
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { planId, dayId, id } = useParams<{
    planId: string;
    dayId: string;
    id: string;
  }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      text: '',
    },
  });

  const { handleSubmit, setValue } = form;

  const loadTheme = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);

      const affirmation = await Api.getPlanAffirmation(id);
      setAffirmation(affirmation);

      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);

      const affirmation = await Api.updatePlanAffirmation(id, data);
      showSnackbar('Updated', 'success');

      setAffirmation(affirmation);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    loadTheme(id).finally(() => setIsFirstLoading(false));
  }, [id]);

  useEffect(() => {
    setValue('name', affirmation.name);
    setValue('text', affirmation.text);
  }, [affirmation]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to List"
        onClick={(): void => {
          history.push(`/plans/${planId}/days/${dayId}/affirmations`);
        }}
      />
      <TopHeadlineRow className={styles.top_bar} text="Affirmation card" />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          <Tabs
            className={styles.container__tabs}
            items={[
              {
                label: 'General info',
                isActive: true,
              },
            ]}
            onClickItem={(): void => {}}
          />
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <div style={{ marginBottom: '8px' }}>
                    <HookFormTextField
                      required
                      name="name"
                      label="Name"
                      fullWidth
                    />
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <HookFormTextField
                      required
                      name="text"
                      label="Text"
                      fullWidth
                    />
                  </div>
                </div>
                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(affirmation.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(affirmation.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <Button
                  type="submit"
                  disabled={isLoading}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(handleSuccessSubmit)}
                >
                  Update info
                </Button>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default Item;
