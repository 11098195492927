import { Dispatch } from 'redux';

import API from '~/Api';
import { ProfileAction, ProfileActionTypes } from '~/store/types/profile';

const loadAndDispatchActualCustomerData = async (
  dispatch: Dispatch<ProfileAction>,
): Promise<void> => {
  const profile = await API.getProfile();

  dispatch({
    type: ProfileActionTypes.UPDATE_PROFILE,
    payload: {
      ...profile,
      isLoggedIn: false,
    },
  });
};

export const checkIsUserLoggedIn = () => {
  return async (dispatch: Dispatch<ProfileAction>): Promise<void> => {
    try {
      await loadAndDispatchActualCustomerData(dispatch);

      dispatch({
        type: ProfileActionTypes.SIGN_IN,
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const signIn = (email: string, password: string) => {
  return async (dispatch: Dispatch<ProfileAction>): Promise<void> => {
    try {
      await API.signIn(email, password);
      await loadAndDispatchActualCustomerData(dispatch);

      dispatch({
        type: ProfileActionTypes.SIGN_IN,
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const signUp = (data: {
  email: string;
  name: string;
  password: string;
  token: string;
}) => {
  return async (dispatch: Dispatch<ProfileAction>): Promise<void> => {
    try {
      await API.signUp(data);
      await API.signIn(data.email, data.password);
      await loadAndDispatchActualCustomerData(dispatch);

      dispatch({
        type: ProfileActionTypes.SIGN_IN,
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const signOut = () => {
  return async (dispatch: Dispatch<ProfileAction>): Promise<void> => {
    await API.signOut();
    dispatch({
      type: ProfileActionTypes.SIGN_OUT,
    });
  };
};
