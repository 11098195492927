import styles from './colors.module.scss';

export const colors: any = {
  black: styles.color_black,
  white: styles.color_white,

  primary_50: styles.color_primary_50,
  primary_100: styles.color_primary_100,
  primary_200: styles.color_primary_200,
  primary_300: styles.color_primary_300,
  primary_400: styles.color_primary_400,
  primary_500: styles.color_primary_500,
  primary_600: styles.color_primary_600,
  primary_700: styles.color_primary_700,
  primary_800: styles.color_primary_800,
  primary_900: styles.color_primary_900,

  secondary_50: styles.color_secondary_50,
  secondary_100: styles.color_secondary_100,
  secondary_200: styles.color_secondary_200,
  secondary_300: styles.color_secondary_300,
  secondary_400: styles.color_secondary_400,
  secondary_500: styles.color_secondary_500,
  secondary_600: styles.color_secondary_600,
  secondary_700: styles.color_secondary_700,
  secondary_800: styles.color_secondary_800,
  secondary_900: styles.color_secondary_900,

  success_50: styles.color_success_50,
  success_300: styles.color_success_300,
  success_500: styles.color_success_500,

  error_50: styles.color_error_50,
  error_300: styles.color_error_300,
  error_500: styles.color_error_500,

  warning_50: styles.color_warning_50,
  warning_300: styles.color_warning_300,
  warning_500: styles.color_warning_500,

  neutral_50: styles.color_neutral_50,
  neutral_100: styles.color_neutral_100,
  neutral_200: styles.color_neutral_200,
  neutral_300: styles.color_neutral_300,
  neutral_400: styles.color_neutral_400,
  neutral_500: styles.color_neutral_500,
  neutral_600: styles.color_neutral_600,
  neutral_700: styles.color_neutral_700,
  neutral_800: styles.color_neutral_800,
  neutral_900: styles.color_neutral_900,

  neutral_2_50: styles.color_neutral_2_50,
  neutral_2_100: styles.color_neutral_2_100,
  neutral_2_200: styles.color_neutral_2_200,
  neutral_2_300: styles.color_neutral_2_300,
  neutral_2_400: styles.color_neutral_2_400,
  neutral_2_500: styles.color_neutral_2_500,
};
