import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  count: number;
};

const TotalCount: FC<Props> = ({ count }) => {
  return (
    <span className={styles.count}>
      {count ? `Total count: ${count}` : 'No results'}
    </span>
  );
};

export default TotalCount;
