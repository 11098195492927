import React from 'react';

import ReactDOM from 'react-dom';

import '~/bootstrap'; // import before App
import App from './App';
import '~/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
