import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from '@mui/material';

export type ButtonProps = MuiButtonProps;

const Button = styled(MuiButton, {
  name: 'StyledButton',
})(({ theme }) => ({
  '&': {
    fontWeight: 600,
    borderRadius: '16px',
    fontFamily: theme.typography.fontFamily,
    textTransform: 'none',
    boxShadow: 'none',
    transition: '300ms ease-in-out',
    alignItems: 'center',

    '&:hover': {
      boxShadow: 'none',
    },
  },

  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.colors['primary_500'],
    color: theme.colors.white,

    '&:hover': {
      backgroundColor: theme.colors['primary_300'],

      '& .MuiButton-startIcon svg path': {
        fill: theme.colors.white,
      },
    },
  },

  '&.MuiButton-containedSecondary': {
    color: theme.colors.white,
    backgroundColor: theme.colors.black,

    '&:hover': {
      backgroundColor: theme.colors['neutral_800'],
    },
  },

  '&.MuiButton-outlined': {
    border: '1px solid #E5E6E9',
    color: theme.colors.black,

    '&:hover': {
      backgroundColor: theme.colors['neutral_100'],
    },
  },

  '&.MuiButton-outlinedSecondary': {
    borderColor: theme.colors['primary_500'],
    background: theme.colors['primary_50'],
    color: theme.colors.black,

    '&:hover': {
      backgroundColor: theme.colors['primary_50'],
    },
  },

  '&.MuiButton-sizeSmall': {
    padding: '8px 24px',
    fontSize: '13px',
    height: '40px',
  },

  '&.MuiButton-sizeMedium': {
    padding: '12px 24px',
    fontSize: '16px',
  },

  '&.MuiButton-sizeLarge': {
    padding: '13px 24px',
    fontSize: '16px',
  },

  '&.MuiButton-fullWidth': {
    width: '100%',
  },

  '&.Mui-disabled': {
    background: theme.colors['neutral_100'],
    color: theme.colors['neutral_500'],
  },

  '&.MuiButton-textSecondary': {
    fontWeight: 600,
    fontSize: '16px',
    color: theme.colors['neutral_600'],
    '&:hover': {
      background: 'none',
    },
  },
}));

export default Button;
