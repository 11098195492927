// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gs8U4VLnBnvT_FGa7muw{width:320px;text-align:left}.VUYIgroosz9TioSy0jWf{display:flex;justify-content:space-between;flex-wrap:nowrap;margin-top:32px}.VUYIgroosz9TioSy0jWf>*:first-child{margin-right:10px}", "",{"version":3,"sources":["webpack://./src/modules/plans/components/DaysList/CreateForm/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,eAAA,CAEA,oCACE,iBAAA","sourcesContent":[".main_container {\n  width: 320px;\n  text-align: left;\n}\n\n.buttons_container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  margin-top: 32px;\n\n  & > *:first-child {\n    margin-right: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": "gs8U4VLnBnvT_FGa7muw",
	"buttons_container": "VUYIgroosz9TioSy0jWf"
};
export default ___CSS_LOADER_EXPORT___;
