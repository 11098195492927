import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, IconButton, styled } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface ModalProps extends DialogProps {
  pageViewMobile?: boolean;
}

const StyledModal = styled(Dialog)<ModalProps>(({ theme, pageViewMobile }) => ({
  '& .MuiDialog-container': {
    height: '100%',
    position: 'relative',
  },
  '& .MuiPaper-root': {
    position: 'relative',
    maxWidth: '432px',
    width: '100%',
    marginLeft: '8px',
    marginRight: '8px',
    borderRadius: '16px',

    [theme.breakpoints.down('sm')]: pageViewMobile && {
      maxWidth: '100%',
      margin: 0,
      height: '100%',
      maxHeight: 'calc(100% - 40px)',
      marginTop: '40px',
      borderRadius: '16px 16px 0 0',
    },
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(43, 49, 67, 0.1)',
  },
}));

interface Props {
  isOpen: boolean;
  onClose(): void;
  className?: string;
  hideCloseArrow?: boolean;
  pageViewMobile?: boolean;
  isFullScreen?: boolean;
}

const Modal: FC<Props> = ({
  isOpen,
  className,
  hideCloseArrow = false,
  pageViewMobile = false,
  children,
  onClose,
  isFullScreen,
}) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullScreen={isFullScreen}
      pageViewMobile={pageViewMobile}
    >
      <div className={styles.close_icon_container}>
        {!hideCloseArrow && (
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <div
        className={clsx(
          styles.content,
          { [styles['content--full']]: isFullScreen },
          className,
        )}
      >
        {children}
      </div>
    </StyledModal>
  );
};

export default Modal;
