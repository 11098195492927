import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import EditPhoto from '~/components/organisms/EditPhoto';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { AffirmationListRoute } from '~/modules/affirmation';
import {
  AffirmationType,
  CreateAffirmationType,
} from '~/modules/affirmation/types';
import { AffirmationCategoryType } from '~/modules/affirmationCategories/types';
import { GroupAffirmationCategoryType } from '~/modules/groupsAffirmationsCategories/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = CreateAffirmationType;

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    text: yup.string().required('Required'),
    category_id: yup.string().required('Required'),
  })
  .required();

const AffirmationCategoryItem: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<AffirmationCategoryType[]>([]);
  const [affirmation, setAffirmation] = useState<AffirmationType>({
    id: '1',
    name: '',
    text: '',
    category_id: '1',
    category_name: '',
    is_visible: false,
    created_at: '2022-11-11 11:11:11',
    updated_at: '2022-11-11 11:11:11',
  });
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit, setValue } = form;

  const load = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      const affirmation = await Api.getAffirmation(id);
      setAffirmation(affirmation);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const loadCategories = async (): Promise<void> => {
    try {
      const categories = await Api.getAffirmationCategories({
        query: { 'per-page': 1000 },
      });
      setCategories(categories.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const affirmation = await Api.updateAffirmation(id, data);
      showSnackbar('Updated', 'success');
      setAffirmation(affirmation);
      setIsLoading(false);
      history.push(AffirmationListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
    }
  };

  const handleRemove = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.deleteAffirmation(id);
      showSnackbar('Removed', 'success');
      setIsLoading(false);
      history.push(AffirmationListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    Promise.all([load(id), loadCategories()]).finally(() =>
      setIsFirstLoading(false),
    );
  }, [id]);

  useEffect(() => {
    setValue('name', affirmation.name);
    setValue('text', affirmation.text);
    setValue('is_visible', affirmation.is_visible);
    setValue('category_id', affirmation.category_id);
  }, [affirmation]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to List"
        onClick={(): void => {
          history.push(AffirmationListRoute.path);
        }}
      />
      <TopHeadlineRow className={styles.top_bar} text="Affirmation card" />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          <Tabs
            className={styles.container__tabs}
            items={[
              {
                label: 'General info',
                isActive: true,
              },
            ]}
            onClickItem={(): void => {}}
          />
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="name"
                    label="Name"
                    disabled={isLoading}
                    fullWidth
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="text"
                    label="Text"
                    disabled={isLoading}
                    fullWidth
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormSelectField
                    required
                    name="category_id"
                    label="Category"
                    disabled={isLoading}
                    fullWidth
                    options={categories.map(({ id, name }) => ({
                      value: id,
                      text: name,
                    }))}
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormCheckbox
                    required
                    name="is_visible"
                    label="Visible"
                    disabled={isLoading}
                  />
                </div>

                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(affirmation.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(affirmation.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.buttons_container}>
                  <Button
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                    type="submit"
                    disabled={isLoading}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(handleSuccessSubmit)}
                  >
                    Update info
                  </Button>
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    variant="contained"
                    onClick={handleRemove}
                  >
                    Delete
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default AffirmationCategoryItem;
