import React, { FC } from 'react';

import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';

import theme from '~/theme';

export type CheckboxCustomProps = MuiCheckboxProps & {
  label?: string;
  errorMessage?: string;
};

const CheckboxComponent: FC<CheckboxCustomProps> = ({
  checked,
  onChange,
  name,
  disabled,
  label,
  errorMessage,
}) => {
  return (
    <div>
      <FormControlLabel
        label={label}
        checked={checked}
        control={
          <MuiCheckbox
            checked={checked}
            onChange={onChange}
            name={name}
            sx={{
              color: theme.colors.neutral_200,
              borderRadius: '2px',
              width: '18px',
              height: '18px',
              ...(disabled
                ? {
                    color: theme.colors.neutral_100,
                    background: theme.colors.neutral_100,
                    '&:hover': {
                      background: theme.colors.neutral_100,
                    },
                  }
                : {}),
            }}
          />
        }
      />
      {errorMessage && <div style={{ color: '#f00' }}>{errorMessage}</div>}
    </div>
  );
};

export default CheckboxComponent;
