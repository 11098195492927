// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xBcUx6MHiS9VWgsk74I_{opacity:.8;pointer-events:none}.OoFchFDaT9gYj6rawsAU{display:flex;flex-direction:column}.dWEeB_lSkP16zkQ4fNFU{height:100%}.WtBq80aK0UJHqfn3xT9o{flex:1}.OaVE4IvB1qptmycvqipf{border-bottom:1px solid #e5e6e9}.d1y6fpRUkvFcmPQkwa8x{display:flex;justify-content:space-between;flex-wrap:nowrap;width:100%;padding:18px 40px 0}.PkCfhICcUAeZH0N9cc70{display:flex;flex-direction:column;flex:1;overflow-x:hidden}.du8kOkS0PK0AslIeJGi6{min-width:230px}.zw7myK4YS3r2oVN7DUK9{font-size:15px;font-weight:700;display:block;margin-bottom:10px}.cdWyIhIOxeiJKfPs5Ost{margin-right:25px;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/improvementAreas/components/ImprovementAreaList/styles.modules.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAEA,sBACE,WAAA,CAGF,sBACE,MAAA,CAIJ,sBACE,+BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,UAAA,CACA,mBAAA,CAEA,sBACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CAIJ,sBACE,iBAAA,CACA,WAAA","sourcesContent":["@import 'src/styles/colors';\n@import \"src/styles/fonts\";\n\n.loading {\n  opacity: .8;\n  pointer-events: none;\n}\n\n.vertical_flex_container {\n  display: flex;\n  flex-direction: column;\n\n  &--full_height {\n    height: 100%;\n  }\n\n  &__full_parent_height {\n    flex: 1;\n  }\n}\n\n.top_bar {\n  border-bottom: 1px solid $neutral-200;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  width: 100%;\n  padding: 18px 40px 0;\n\n  &__left {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    overflow-x: hidden;\n  }\n\n  &__right {\n    min-width: 230px;\n  }\n\n  &__headline {\n    font-size: 15px;\n    font-weight: 700;\n    display: block;\n    margin-bottom: 10px\n  }\n}\n\n.table_container {\n  margin-right: 25px;\n  height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "xBcUx6MHiS9VWgsk74I_",
	"vertical_flex_container": "OoFchFDaT9gYj6rawsAU",
	"vertical_flex_container--full_height": "dWEeB_lSkP16zkQ4fNFU",
	"vertical_flex_container__full_parent_height": "WtBq80aK0UJHqfn3xT9o",
	"top_bar": "OaVE4IvB1qptmycvqipf",
	"container": "d1y6fpRUkvFcmPQkwa8x",
	"container__left": "PkCfhICcUAeZH0N9cc70",
	"container__right": "du8kOkS0PK0AslIeJGi6",
	"container__headline": "zw7myK4YS3r2oVN7DUK9",
	"table_container": "cdWyIhIOxeiJKfPs5Ost"
};
export default ___CSS_LOADER_EXPORT___;
