import colors from '~/theme/colors.module.scss';

export const getStatusColor = (status: string): string => {
  switch (status) {
    case 'active':
      return colors.color_primary_500;
    case 'invited':
      return colors.color_neutral_500;
    case 'deactivated':
      return colors.color_error_300;
    case 'inactive':
      return colors.color_secondary_500;
    default:
      return colors.color_primary_500;
  }
};
