import React, { FC } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';

import { ModalContextProvider } from '~/components/layoutComponents/modal/ModalContext';
import Router from '~/Router';
import { store } from '~/store';
import theme from '~/theme';

import { SnackBarContextProvider } from './components/layoutComponents/snackbar/SnackbarContext';

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <SnackBarContextProvider>
          <ModalContextProvider>
            <Router />
          </ModalContextProvider>
        </SnackBarContextProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
