// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color_black": "#1e1e1f",
	"color_white": "#fff",
	"color_primary_50": "#ecf5f7",
	"coor_primary_100": "#c3dfe5",
	"color_primary_200": "#a6d0d8",
	"color_primary_300": "#7dbac7",
	"color_primary_400": "#64adbc",
	"color_primary_500": "#3d98ab",
	"color_primary_600": "#388a9c",
	"color_primary_700": "#2b6c79",
	"color_primary_800": "#22545e",
	"color_primary_900": "#1a4048",
	"color_secondary_50": "#fefaee",
	"color_secondary_100": "#fcf0ca",
	"color_secondary_200": "#fbe8b0",
	"color_secondary_300": "#f9de8c",
	"color_secondary_400": "#f8d875",
	"color_secondary_500": "#f6ce53",
	"color_secondary_600": "#e0bb4c",
	"color_secondary_700": "#af923b",
	"color_secondary_800": "#87712e",
	"color_secondary_900": "#675723",
	"color_success_50": "#ecf5f7",
	"color_success_300": "#7dbac7",
	"color_success_500": "#3d98ab",
	"color_error_50": "#fcefef",
	"color_error_300": "#eb9393",
	"color_error_500": "#e15e5e",
	"color_warning_50": "#fefaee",
	"color_warning_300": "#f9de8c",
	"color_warning_500": "#f6ce53",
	"color_neutral_50": "#f9fafa",
	"color_neutral_100": "#edeef0",
	"color_neutral_200": "#e5e6e9",
	"color_neutral_300": "#d9dadf",
	"color_neutral_400": "#d1d3d9",
	"color_neutral_500": "#c6c8cf",
	"color_neutral_600": "#b4b6bc",
	"color_neutral_700": "#8d8e93",
	"color_neutral_800": "#6d6e72",
	"color_neutral_900": "#535457",
	"color_neutral_2_50": "#fafbfe",
	"color_neutral_2_100": "#eff3fd",
	"color_neutral_2_200": "#e8edfb",
	"color_neutral_2_300": "#dde5fa",
	"color_neutral_2_400": "#d6e0f9",
	"color_neutral_2_500": "#ccd8f7"
};
export default ___CSS_LOADER_EXPORT___;
