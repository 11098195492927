import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import GroupAffirmationCategoryItem from '~/modules/groupsAffirmationsCategories/components/GroupAffirmationCategoryItem';
import GroupAffirmationCategoryList from '~/modules/groupsAffirmationsCategories/components/GroupAffirmationCategoryList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = 'GroupAffirmationCategory';

export const GroupAffirmationCategoryListRoute = {
  path: '/group-affirmation-category',
  Component: withSidebarLayout(GroupAffirmationCategoryList),
  title: 'Groups of affirmation categories',
  exact: true,
  permissionKey,
};

export const GroupAffirmationCategoryItemRoute = {
  path: '/group-affirmation-category/:id',
  Component: withSidebarLayout(GroupAffirmationCategoryItem),
  title: 'Group of Affirmation category Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  a: [
    {
      path: '/group-affirmation-category',
      title: 'Groups of affirmation categories',
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([
  GroupAffirmationCategoryListRoute,
  GroupAffirmationCategoryItemRoute,
]);
