import { PaletteOptions } from '@mui/material';

import { colors } from '~/theme/colors';

export const palette: PaletteOptions = {
  background: {
    paper: '#FFF',
    // default: colors.darkGrey, // magic notistack not working with this line
  },
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: {
    50: colors.primary_50,
    100: colors.primary_100,
    200: colors.primary_200,
    300: colors.primary_300,
    400: colors.primary_400,
    500: colors.primary_500,
    600: colors.primary_600,
    700: colors.primary_700,
    800: colors.primary_800,
    900: colors.primary_900,
  },
  secondary: {
    main: colors.secondary_500,
    50: colors.secondary_50,
    100: colors.secondary_100,
    200: colors.secondary_200,
    300: colors.secondary_300,
    400: colors.secondary_400,
    500: colors.secondary_500,
    600: colors.secondary_600,
    700: colors.secondary_700,
    800: colors.secondary_800,
    900: colors.secondary_900,
  },
  neutral: {
    50: colors.neutral_50,
    100: colors.neutral_100,
    200: colors.neutral_200,
    300: colors.neutral_300,
    400: colors.neutral_400,
    500: colors.neutral_500,
    600: colors.neutral_600,
    700: colors.neutral_700,
    800: colors.neutral_800,
    900: colors.neutral_900,
  },
  neutral_2: {
    50: colors.neutral_2_50,
    100: colors.neutral_2_100,
    200: colors.neutral_2_200,
    300: colors.neutral_2_300,
    400: colors.neutral_2_400,
    500: colors.neutral_2_500,
  },
  success: {
    50: colors.success_50,
    300: colors.success_300,
    500: colors.success_500,
  },
  error: {
    50: colors.error_50,
    300: colors.error_300,
    500: colors.error_500,
  },
  warning: {
    50: colors.warning_50,
    300: colors.warning_300,
    500: colors.warning_500,
  },
};
