import React, { FC } from 'react';

import { TableRow as MuiTableRow, styled, TableRowProps } from '@mui/material';

const TableRowStyled = styled(MuiTableRow)(({ theme }) => ({
  '&': {
    background: theme.colors['neutral_50'],
    borderBottom: `1px solid ${theme.colors['neutral_100']}`,

    '&:last-child': {
      borderRadius: '0px 0px 8px 8px',
    },
  },

  '.MuiTableCell-body': {
    '&:first-child': {
      paddingLeft: '24px',
    },

    '&:last-child': {
      paddingRight: '24px',
    },
  },
}));

const TableRow: FC<TableRowProps> = ({ children, ...props }) => {
  return <TableRowStyled {...props}>{children}</TableRowStyled>;
};

export default TableRow;
