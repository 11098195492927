import React, { ComponentType, ReactElement } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

export const withHookForm =
  <T extends Record<string, any>>(
    WrappedComponent: ComponentType<
      Omit<T, 'value' | 'onChange' | 'errorMessage'>
    >,
  ) =>
  ({ ...props }: T): ReactElement => {
    const { name } = props;
    const { control } = useFormContext();

    return (
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }): ReactElement => (
          // @ts-ignore
          <WrappedComponent
            value={value}
            onChange={onChange}
            error={!!error?.message}
            helperText={error && error.message ? error.message : undefined}
            {...props}
          />
        )}
      />
    );
  };
