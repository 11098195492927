import React, { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import TogglerComponent, { TogglerCustomProps } from '../atoms/inputs/Toggler';

const HookFormToggler: FC<TogglerCustomProps & { name: string }> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }): JSX.Element => (
        <TogglerComponent
          checked={value}
          onChange={onChange}
          errorMessage={error && error.message ? error.message : undefined}
          {...props}
        />
      )}
    />
  );
};

export default HookFormToggler;
