import React, { FC, useEffect } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import { useModalContext } from '~/components/layoutComponents/modal/ModalContext';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Pagination from '~/components/molecules/Pagination';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import TotalCount from '~/components/molecules/TotalCount';
import useFilters from '~/hooks/useFilters';
import useQueryParams from '~/hooks/useQueryParams';
import CreateAdminForm from '~/modules/admins/components/AdminsList/CreateAdminForm';
import Table from '~/modules/admins/components/AdminsList/Table';
import useData, {
  FiltersType,
} from '~/modules/admins/components/AdminsList/useData';
import { SORT_ORDER } from '~/types/common';

import styles from './styles.modules.scss';

const DEFAULT_FILTER_STATE: FiltersType = {
  sortOrder: SORT_ORDER.DESC,
  sortBy: 'created_at',
  page: 1,
  'per-page': 10,
};

const AdminsList: FC = () => {
  const { handleOpenModal } = useModalContext();
  const { showSnackbar } = useSnackBarContext();
  const { getQueryParams, setQueryParams } = useQueryParams();

  const defaultFilterState = {
    ...DEFAULT_FILTER_STATE,
    ...getQueryParams(),
  };

  const { filters, updateFilter } = useFilters<FiltersType>(defaultFilterState);

  const { admins, isLoading, meta, updateItems } = useData(filters);

  useEffect(() => {
    setQueryParams(filters);
  }, [filters]);

  const handleAdd = async (email: string, password: string): Promise<void> => {
    try {
      await Api.createAdmin(email, password);
      await updateItems();
      showSnackbar('Admin created', 'success');
    } catch (e: any) {
      showSnackbar(e.message);
      console.error(e);
    }
  };

  return (
    <div
      className={clsx(
        { [styles.loading]: isLoading },
        styles.vertical_flex_container,
        styles['vertical_flex_container--full_height'],
      )}
    >
      <TopHeadlineRow className={styles.top_bar} text="Admins">
        <Button
          variant="outlined"
          onClick={(): void => {
            handleOpenModal({
              component: ({ onClose }) => (
                <CreateAdminForm addAdmin={handleAdd} onClose={onClose} />
              ),
            });
          }}
        >
          <Icon
            style={{ marginRight: 5 }}
            fontSize={20}
            icon="material-symbols:person-add-rounded"
          />
          New Admin
        </Button>
      </TopHeadlineRow>
      <div
        className={clsx(
          styles.container,
          styles.vertical_flex_container__full_parent_height,
        )}
      >
        <div className={styles.container__left}>
          <TotalCount count={meta.totalCount} />
          <div
            className={clsx(
              styles.vertical_flex_container,
              styles['vertical_flex_container--full_height'],
            )}
          >
            <div className={styles.vertical_flex_container__full_parent_height}>
              <Table
                admins={admins}
                filter={{
                  sortBy: filters.sortBy,
                  sortOrder: filters.sortOrder,
                }}
                applyFilters={(filters): void => updateFilter(filters)}
              />
            </div>
            {meta.totalPages > 1 && (
              <Pagination
                currentPage={Number(meta.currentPage)}
                totalPages={Number(meta.totalPages)}
                setPage={(page: number): void => {
                  updateFilter({ page });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminsList;
