import React, { FC } from 'react';

import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const FullscreenPreloader: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress disableShrink />
    </div>
  );
};

export default FullscreenPreloader;
