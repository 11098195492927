/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type CommonHealthCheckResponse = Record<string, string>;

export interface DefinitionsAdminLoginRequest {
  email?: string;
  password?: string;
}

export interface DefinitionsAdminLoginResponse {
  access_token?: string;
  refresh_token?: string;
}

export interface DefinitionsAdminSignUpRequest {
  email?: string;
  password?: string;
}

export interface DefinitionsAdminSignUpResponse {
  created_at?: string;
  email?: string;
  id?: string;
  updated_at?: string;
}

export interface DefinitionsChangeForgottenPasswordRequest {
  new_password?: string;
  reset_password_token?: string;
}

export interface DefinitionsChangePasswordRequest {
  new_password?: string;
  old_password?: string;
}

export interface DefinitionsCreateAffirmationRequest {
  category_id?: string;
  is_visible?: boolean;
  name?: string;
  text?: string;
}

export interface DefinitionsCreateGroupRequest {
  improvement_area_id?: string;
  name?: string;
}

export interface DefinitionsCreateGroupResponse {
  categories?: ModelsCategoryResponse[];
  category_count?: number;
  created_at?: string;
  id?: string;
  improvement_area_id?: string;
  improvement_area_name?: string;
  name?: string;
  position?: number;
  updated_at?: string;
}

export interface DefinitionsCreateImprovementAreaRequest {
  name?: string;
}

export interface DefinitionsCreateImprovementAreaResponse {
  created_at?: string;
  id?: string;
  name?: string;
  updated_at?: string;
}

export interface DefinitionsCreateOwnAffirmationRequest {
  device_id?: string;
  text?: string;
}

export interface DefinitionsCreatePlanAffirmationRequest {
  name?: string;
  plan_day_id?: string;
  text?: string;
}

export interface DefinitionsCreatePlanAffirmationResponse {
  created_at?: string;
  id?: string;
  name?: string;
  plan_day_id?: string;
  updated_at?: string;
  week?: string;
}

export interface DefinitionsCreatePlanDayRequest {
  journal_question?: string;
  plan_id?: string;
  position?: number;
  tips?: string;
  title?: string;
  week?: string;
}

export interface DefinitionsCreatePlanDayResponse {
  created_at?: string;
  id?: string;
  journal_question?: string;
  name?: string;
  plan_id?: string;
  position?: number;
  tips?: string;
  updated_at?: string;
  week?: string;
}

export interface DefinitionsCreatePlanRequest {
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
}

export interface DefinitionsCreatePlanResponse {
  created_at?: string;
  id?: string;
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
  updated_at?: string;
}

export interface DefinitionsCreateProductRequest {
  description?: string;
  name?: string;
  product_id?: string;
}

export interface DefinitionsCreateThemeCategoryRequest {
  is_visible?: boolean;
  name?: string;
}

export interface DefinitionsCreateThemeRequest {
  color?: string;
  font_color?: string;
  font_name?: string;
  is_new?: boolean;
  is_premium?: boolean;
  name?: string;
  theme_category_id?: string;
}

export interface DefinitionsFunnelInitUserRequest {
  email?: string;
  panda_id?: string;
}

export interface DefinitionsGetGroupResponse {
  categories?: ModelsCategoryResponse[];
  category_count?: number;
  created_at?: string;
  id?: string;
  improvement_area_id?: string;
  improvement_area_name?: string;
  name?: string;
  position?: number;
  updated_at?: string;
}

export interface DefinitionsGetPlanAffirmationResponse {
  created_at?: string;
  id?: string;
  name?: string;
  plan_day_id?: string;
  updated_at?: string;
  week?: string;
}

export interface DefinitionsImportAffirmationItem {
  text?: string;
}

export interface DefinitionsImportAffirmationsRequest {
  affirmations?: DefinitionsImportAffirmationItem[];
  category_id?: string;
}

export interface DefinitionsRefreshTokenRequest {
  access_token?: string;
  refresh_token?: string;
}

export interface DefinitionsResetPasswordRequest {
  email?: string;
}

export interface DefinitionsUpdateAffirmationRequest {
  category_id?: string;
  is_visible?: boolean;
  name?: string;
  text?: string;
}

export interface DefinitionsUpdateCategoryRequest {
  is_new?: boolean;
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
  position?: number;
}

export interface DefinitionsUpdateCategoryResponse {
  created_at?: string;
  group_id?: string;
  id?: string;
  image_src?: string;
  is_new?: boolean;
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
  position?: number;
  preview_image_src?: string;
  updated_at?: string;
}

export interface DefinitionsUpdateGroupRequest {
  improvement_area_id?: string;
  name?: string;
  position?: number;
}

export interface DefinitionsUpdateGroupResponse {
  categories?: ModelsCategoryResponse[];
  category_count?: number;
  created_at?: string;
  id?: string;
  improvement_area_id?: string;
  improvement_area_name?: string;
  name?: string;
  position?: number;
  updated_at?: string;
}

export interface DefinitionsUpdateImprovementAreaRequest {
  name?: string;
}

export interface DefinitionsUpdateImprovementAreaResponse {
  created_at?: string;
  id?: string;
  name?: string;
  updated_at?: string;
}

export interface DefinitionsUpdateOwnAffirmationRequest {
  device_id?: string;
  text?: string;
}

export interface DefinitionsUpdatePlanAffirmationRequest {
  name?: string;
  plan_day_id?: string;
  text?: string;
}

export interface DefinitionsUpdatePlanAffirmationResponse {
  created_at?: string;
  id?: string;
  name?: string;
  plan_day_id?: string;
  updated_at?: string;
  week?: string;
}

export interface DefinitionsUpdatePlanDayRequest {
  journal_question?: string;
  plan_id?: string;
  position?: number;
  tips?: string;
  title?: string;
  week?: string;
}

export interface DefinitionsUpdatePlanRequest {
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
}

export interface DefinitionsUpdateProductRequest {
  description?: string;
  name?: string;
}

export interface DefinitionsUpdateThemeCategoryRequest {
  is_visible?: boolean;
  name?: string;
  position?: number;
}

export interface DefinitionsUpdateThemeRequest {
  asset_src?: string;
  category_name?: string;
  color?: string;
  font_color?: string;
  font_name?: string;
  is_new?: boolean;
  is_premium?: boolean;
  name?: string;
  position?: number;
  preview_asset_src?: string;
  theme_category_id?: string;
}

export interface DefinitionsUploadMediaFileResponse {
  file_key?: string;
}

export interface DefinitionsUserLoginRequest {
  email?: string;
  password?: string;
}

export interface DefinitionsUserLoginResponse {
  access_token?: string;
  refresh_token?: string;
}

export interface DefinitionsUserSignUpRequest {
  email?: string;
  password?: string;
}

export interface DefinitionsUserSignUpResponse {
  created_at?: string;
  email?: string;
  id?: string;
  improvement_area_id?: string;
  panda_id?: string;
  panda_id_ios?: string;
  reset_password_token?: string;
  status?: ModelsSubscriptionStatus;
  updated_at?: string;
}

export interface ModelsAdmin {
  created_at?: string;
  email?: string;
  id?: string;
  updated_at?: string;
}

export interface ModelsAffirmation {
  category_id?: string;
  created_at?: string;
  id?: string;
  is_visible?: boolean;
  name?: string;
  text?: string;
  updated_at?: string;
}

export interface ModelsAffirmationCategory {
  created_at?: string;
  group_id?: string;
  id?: string;
  image_src?: string;
  is_new?: boolean;
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
  position?: number;
  preview_image_src?: string;
  updated_at?: string;
}

export interface ModelsAffirmationCategoryGroup {
  created_at?: string;
  id?: string;
  improvement_area_id?: string;
  name?: string;
  position?: number;
  updated_at?: string;
}

export interface ModelsAffirmationCategoryGroupResponse {
  categories?: ModelsCategoryResponse[];
  category_count?: number;
  created_at?: string;
  id?: string;
  improvement_area_id?: string;
  improvement_area_name?: string;
  name?: string;
  position?: number;
  updated_at?: string;
}

export interface ModelsAffirmationResponse {
  category_id?: string;
  category_name?: string;
  created_at?: string;
  id?: string;
  is_visible?: boolean;
  name?: string;
  text?: string;
  updated_at?: string;
}

export interface ModelsAuthTokens {
  access_token?: string;
  refresh_token?: string;
}

export interface ModelsCategoryResponse {
  affirmations_count?: number;
  created_at?: string;
  group_id?: string;
  group_name?: string;
  id?: string;
  image_src?: string;
  is_new?: boolean;
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
  position?: number;
  preview_image_src?: string;
  updated_at?: string;
}

export interface ModelsCreateCategoryRequest {
  group_id?: string;
  image_src?: string;
  is_new?: boolean;
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
  position?: number;
  preview_image_src?: string;
}

export interface ModelsCreateSolidOrderPayload {
  force_3ds?: boolean;
  name?: string;
  order_description?: string;
  product_id?: string;
  purchase_url?: string;
  sandbox?: boolean;
  user_id?: string;
  wallet?: string;
  with_paypal?: boolean;
}

export interface ModelsCreateSolidOrderResponse {
  gate_merchant?: string;
  gate_payment_intent?: string;
  gate_signature?: string;
  merchant?: string;
  order_id?: string;
  payment_intent?: string;
  pv?: number;
  signature?: string;
  transaction_id?: number;
}

export interface ModelsCreateSolidPaypalPayload {
  product_id?: string;
  purchase_token?: string;
}

export interface ModelsCreateUserPayload {
  app_version?: string;
  appsflyer_id?: string;
  country?: string;
  custom_user_id?: string;
  device_family?: string;
  device_model?: string;
  email?: string;
  facebook_login_id?: string;
  fbc?: string;
  fbp?: string;
  first_name?: string;
  full_name?: string;
  gclid?: string;
  gender?: number;
  idfa?: string;
  idfv?: string;
  ip?: string;
  language?: string;
  last_name?: string;
  locale?: string;
  os_version?: string;
  payload?: string;
  phone?: string;
  platform?: string;
  properties?: ModelsProperties;
  push_notifications_token?: string;
  start_app_version?: string;
  timezone?: string;
}

export interface ModelsCreateUserResponse {
  id?: string;
}

export interface ModelsDayResponse {
  affirmations?: ModelsPlanAffirmation[];
  created_at?: string;
  id?: string;
  journal_question?: string;
  name?: string;
  plan_id?: string;
  position?: number;
  tips?: string;
  updated_at?: string;
  week?: string;
}

export interface ModelsGetPandaUserResponse {
  app_id?: string;
  app_version?: string;
  appsflyer_id?: string;
  country?: string;
  custom_user_id?: string;
  device_family?: string;
  device_id?: number;
  device_model?: string;
  email?: string;
  facebook_login_id?: string;
  first_name?: string;
  full_name?: string;
  gclid?: string;
  gender?: number;
  idfa?: string;
  idfv?: string;
  ip?: string;
  language?: string;
  last_name?: string;
  locale?: string;
  os_version?: string;
  payload?: string;
  phone?: string;
  platform?: string;
  properties?: ModelsProperties;
  push_notifications_token?: string;
  start_app_version?: string;
  timezone?: string;
  user_id?: string;
}

export interface ModelsImprovementArea {
  created_at?: string;
  id?: string;
  name?: string;
  updated_at?: string;
}

export interface ModelsOwnAffirmation {
  created_at?: string;
  device_id?: string;
  id?: string;
  text?: string;
  updated_at?: string;
  user_id?: string;
}

export interface ModelsPlanAffirmation {
  created_at?: string;
  id?: string;
  name?: string;
  plan_day_id?: string;
  updated_at?: string;
  week?: string;
}

export interface ModelsPlanResponse {
  created_at?: string;
  days?: ModelsDayResponse[];
  days_count?: number;
  id?: string;
  is_premium?: boolean;
  is_visible?: boolean;
  name?: string;
  updated_at?: string;
}

export interface ModelsProduct {
  created_at?: string;
  description?: string;
  id?: string;
  name?: string;
  product_id?: string;
  updated_at?: string;
}

export type ModelsProperties = Record<string, any>;

export enum ModelsSubscriptionStatus {
  SubscriptionStatusActive = 'active',
  SubscriptionStatusInactive = 'inactive',
}

export interface ModelsSubscriptionStatusResponse {
  is_active?: boolean;
}

export interface ModelsTheme {
  asset_src?: string;
  category_name?: string;
  color?: string;
  created_at?: string;
  font_color?: string;
  font_name?: string;
  id?: string;
  is_new?: boolean;
  is_premium?: boolean;
  name?: string;
  position?: number;
  preview_asset_src?: string;
  theme_category_id?: string;
  updated_at?: string;
}

export interface ModelsThemeCategory {
  created_at?: string;
  id?: string;
  is_visible?: boolean;
  name?: string;
  position?: number;
  updated_at?: string;
}

export interface ModelsThemeCategoryResponse {
  created_at?: string;
  id?: string;
  is_visible?: boolean;
  name?: string;
  position?: number;
  themes_count?: number;
  updated_at?: string;
}

export interface ModelsTotal {
  current_page?: number;
  page_size?: number;
  total_count?: number;
  total_pages?: number;
}

export interface ModelsUpdateUserPayload {
  app_version?: string;
  appsflyer_id?: string;
  country?: string;
  custom_user_id?: string;
  device_family?: string;
  device_model?: string;
  email?: string;
  facebook_login_id?: string;
  fbc?: string;
  fbp?: string;
  first_name?: string;
  full_name?: string;
  gclid?: string;
  gender?: number;
  idfa?: string;
  idfv?: string;
  ip?: string;
  language?: string;
  last_name?: string;
  locale?: string;
  os_version?: string;
  payload?: string;
  phone?: string;
  platform?: string;
  properties?: ModelsProperties;
  push_notifications_token?: string;
  start_app_version?: string;
  timezone?: string;
}

export interface ModelsUpdateUserResponse {
  id?: string;
}

export interface ModelsUser {
  created_at?: string;
  email?: string;
  id?: string;
  improvement_area_id?: string;
  panda_id?: string;
  panda_id_ios?: string;
  reset_password_token?: string;
  status?: ModelsSubscriptionStatus;
  updated_at?: string;
}

export interface ModelsVerifyReceiptResponse {
  active?: boolean;
  id?: string;
}

export interface PlatformUpdateProfileRequest {
  improvement_area_id?: string;
  status?: ModelsSubscriptionStatus;
}

export interface PlatformUpdateProfileResponse {
  created_at?: string;
  email?: string;
  id?: string;
  improvement_area_id?: string;
  panda_id?: string;
  panda_id_ios?: string;
  reset_password_token?: string;
  status?: ModelsSubscriptionStatus;
  updated_at?: string;
}

export interface RenderHTTPResponse {
  data?: any;
  meta?: any;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '//localhost:8081/api';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`,
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key],
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Swagger Example API
 * @version 1.0
 * @license (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @baseUrl //localhost:8081/api
 * @contact
 *
 * This is a sample server Petstore server.
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  adminPanel = {
    /**
     * @description Admin List
     *
     * @tags AdminPanel/Admins
     * @name AdminsList
     * @summary Admin List
     * @request GET:/admin-panel/admins
     */
    adminsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAdmin[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/admins`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin By ID
     *
     * @tags AdminPanel/Admins
     * @name AdminsDetail
     * @summary Admin By ID
     * @request GET:/admin-panel/admins/{admin_id}
     */
    adminsDetail: (adminId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAdmin;
        },
        any
      >({
        path: `/admin-panel/admins/${adminId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Profile
     *
     * @tags AdminPanel/Admins
     * @name AdminsProfileList
     * @summary Admin Profile
     * @request GET:/admin-panel/admins/profile
     */
    adminsProfileList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAdmin;
        },
        any
      >({
        path: `/admin-panel/admins/profile`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Affirmations
     *
     * @tags AdminPanel/Affirmation
     * @name AffirmationsList
     * @summary Admin List Affirmations
     * @request GET:/admin-panel/affirmations
     */
    affirmationsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmation[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/affirmations`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Create Affirmation
     *
     * @tags AdminPanel/Affirmation
     * @name AffirmationsCreate
     * @summary Admin Create Affirmation
     * @request POST:/admin-panel/affirmations
     */
    affirmationsCreate: (
      request: DefinitionsCreateAffirmationRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationResponse;
        },
        any
      >({
        path: `/admin-panel/affirmations`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Delete Affirmation
     *
     * @tags AdminPanel/Affirmation
     * @name AffirmationsDelete
     * @summary Admin Delete Affirmation
     * @request DELETE:/admin-panel/affirmations/{affirmation_id}
     */
    affirmationsDelete: (affirmationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-panel/affirmations/${affirmationId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin Get Affirmation
     *
     * @tags AdminPanel/Affirmation
     * @name AffirmationsDetail
     * @summary Admin Get Affirmation
     * @request GET:/admin-panel/affirmations/{affirmation_id}
     */
    affirmationsDetail: (affirmationId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationResponse;
        },
        any
      >({
        path: `/admin-panel/affirmations/${affirmationId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Update Affirmation
     *
     * @tags AdminPanel/Affirmation
     * @name AffirmationsUpdate
     * @summary Admin Update Affirmation
     * @request PUT:/admin-panel/affirmations/{affirmation_id}
     */
    affirmationsUpdate: (
      affirmationId: string,
      request: DefinitionsUpdateAffirmationRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationResponse;
        },
        any
      >({
        path: `/admin-panel/affirmations/${affirmationId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Import Affirmations
     *
     * @tags AdminPanel/Affirmation
     * @name AffirmationsImportCreate
     * @summary Import Affirmations
     * @request POST:/admin-panel/affirmations/import
     */
    affirmationsImportCreate: (
      request: DefinitionsImportAffirmationsRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationResponse[];
        },
        any
      >({
        path: `/admin-panel/affirmations/import`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Affirmation Categories
     *
     * @tags AdminPanel/Category
     * @name CategoriesList
     * @summary Admin List Affirmation Categories
     * @request GET:/admin-panel/categories
     */
    categoriesList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationCategory[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/categories`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Create Affirmation Category
     *
     * @tags AdminPanel/Category
     * @name CategoriesCreate
     * @summary Admin Create Affirmation Category
     * @request POST:/admin-panel/categories
     */
    categoriesCreate: (
      request: ModelsCreateCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsCategoryResponse;
        },
        any
      >({
        path: `/admin-panel/categories`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Delete Affirmation Category
     *
     * @tags AdminPanel/Category
     * @name CategoriesDelete
     * @summary Admin Delete Affirmation Category
     * @request DELETE:/admin-panel/categories/{category_id}
     */
    categoriesDelete: (categoryId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-panel/categories/${categoryId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin Get Affirmation Category
     *
     * @tags AdminPanel/Category
     * @name CategoriesDetail
     * @summary Admin Get Affirmation Category
     * @request GET:/admin-panel/categories/{category_id}
     */
    categoriesDetail: (categoryId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationCategory;
        },
        any
      >({
        path: `/admin-panel/categories/${categoryId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Update Affirmation Category
     *
     * @tags AdminPanel/Category
     * @name CategoriesUpdate
     * @summary Admin Update Affirmation Category
     * @request PUT:/admin-panel/categories/{category_id}
     */
    categoriesUpdate: (
      categoryId: string,
      request: DefinitionsUpdateCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsUpdateCategoryResponse;
        },
        any
      >({
        path: `/admin-panel/categories/${categoryId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Groups
     *
     * @tags AdminPanel/Group
     * @name GroupsList
     * @summary Admin List Groups
     * @request GET:/admin-panel/groups
     */
    groupsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationCategoryGroup[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/groups`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Create Group
     *
     * @tags AdminPanel/Group
     * @name GroupsCreate
     * @summary Admin Create Group
     * @request POST:/admin-panel/groups
     */
    groupsCreate: (
      request: DefinitionsCreateGroupRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsCreateGroupResponse;
        },
        any
      >({
        path: `/admin-panel/groups`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Delete Group
     *
     * @tags AdminPanel/Group
     * @name GroupsDelete
     * @summary Admin Delete Group
     * @request DELETE:/admin-panel/groups/{group_id}
     */
    groupsDelete: (groupId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-panel/groups/${groupId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin Get Group
     *
     * @tags AdminPanel/Group
     * @name GroupsDetail
     * @summary Admin Get Group
     * @request GET:/admin-panel/groups/{group_id}
     */
    groupsDetail: (groupId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsGetGroupResponse;
        },
        any
      >({
        path: `/admin-panel/groups/${groupId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Update Group
     *
     * @tags AdminPanel/Group
     * @name GroupsUpdate
     * @summary Admin Update Group
     * @request PUT:/admin-panel/groups/{group_id}
     */
    groupsUpdate: (
      groupId: string,
      request: DefinitionsUpdateGroupRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsUpdateGroupResponse;
        },
        any
      >({
        path: `/admin-panel/groups/${groupId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Improvement Areas
     *
     * @tags AdminPanel/ImprovementArea
     * @name ImprovementAreasList
     * @summary Admin List Improvement Areas
     * @request GET:/admin-panel/improvement-areas
     */
    improvementAreasList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsImprovementArea[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/improvement-areas`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Create Improvement Area
     *
     * @tags AdminPanel/ImprovementArea
     * @name ImprovementAreasCreate
     * @summary Admin Create Improvement Area
     * @request POST:/admin-panel/improvement-areas
     */
    improvementAreasCreate: (
      request: DefinitionsCreateImprovementAreaRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsCreateImprovementAreaResponse;
        },
        any
      >({
        path: `/admin-panel/improvement-areas`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Delete Improvement Area
     *
     * @tags AdminPanel/ImprovementArea
     * @name ImprovementAreasDelete
     * @summary Admin Delete Improvement Area
     * @request DELETE:/admin-panel/improvement-areas/{improvement_area_id}
     */
    improvementAreasDelete: (
      improvementAreaId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/admin-panel/improvement-areas/${improvementAreaId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin Get Improvement Area
     *
     * @tags AdminPanel/ImprovementArea
     * @name ImprovementAreasDetail
     * @summary Admin Get Improvement Area
     * @request GET:/admin-panel/improvement-areas/{improvement_area_id}
     */
    improvementAreasDetail: (
      improvementAreaId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsImprovementArea;
        },
        any
      >({
        path: `/admin-panel/improvement-areas/${improvementAreaId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Update Improvement Area
     *
     * @tags AdminPanel/ImprovementArea
     * @name ImprovementAreasUpdate
     * @summary Admin Update Improvement Area
     * @request PUT:/admin-panel/improvement-areas/{improvement_area_id}
     */
    improvementAreasUpdate: (
      improvementAreaId: string,
      request: DefinitionsUpdateImprovementAreaRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsUpdateImprovementAreaResponse;
        },
        any
      >({
        path: `/admin-panel/improvement-areas/${improvementAreaId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Login
     *
     * @tags AdminPanel
     * @name LoginCreate
     * @summary Admin Login
     * @request POST:/admin-panel/login
     */
    loginCreate: (
      request: DefinitionsAdminLoginRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsAdminLoginResponse;
        },
        any
      >({
        path: `/admin-panel/login`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Logout
     *
     * @tags AdminPanel
     * @name LogoutCreate
     * @summary Admin Logout
     * @request POST:/admin-panel/logout
     */
    logoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-panel/logout`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin List Plans
     *
     * @tags AdminPanel/Plan
     * @name PlanList
     * @summary Admin List Plans
     * @request GET:/admin-panel/plan
     */
    planList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/plan`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Create Plan
     *
     * @tags AdminPanel/Plan
     * @name PlanCreate
     * @summary Admin Create Plan
     * @request POST:/admin-panel/plan
     */
    planCreate: (
      request: DefinitionsCreatePlanRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsCreatePlanResponse;
        },
        any
      >({
        path: `/admin-panel/plan`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Plan Affirmations
     *
     * @tags AdminPanel/PlanAffirmation
     * @name PlanAffirmationList
     * @summary Admin List Plan Affirmations
     * @request GET:/admin-panel/plan-affirmation
     */
    planAffirmationList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanAffirmation[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/plan-affirmation`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Create Plan Affirmation
     *
     * @tags AdminPanel/PlanAffirmation
     * @name PlanAffirmationCreate
     * @summary Admin Create Plan Affirmation
     * @request POST:/admin-panel/plan-affirmation
     */
    planAffirmationCreate: (
      request: DefinitionsCreatePlanAffirmationRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsCreatePlanAffirmationResponse;
        },
        any
      >({
        path: `/admin-panel/plan-affirmation`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Delete Plan Affirmation
     *
     * @tags AdminPanel/PlanAffirmation
     * @name PlanAffirmationDelete
     * @summary Admin Delete Plan Affirmation
     * @request DELETE:/admin-panel/plan-affirmation/{plan_affirmation_id}
     */
    planAffirmationDelete: (
      planAffirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/admin-panel/plan-affirmation/${planAffirmationId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin Get Plan Affirmation
     *
     * @tags AdminPanel/PlanAffirmation
     * @name PlanAffirmationDetail
     * @summary Admin Get Plan Affirmation
     * @request GET:/admin-panel/plan-affirmation/{plan_affirmation_id}
     */
    planAffirmationDetail: (
      planAffirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsGetPlanAffirmationResponse;
        },
        any
      >({
        path: `/admin-panel/plan-affirmation/${planAffirmationId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Update Plan Affirmation
     *
     * @tags AdminPanel/PlanAffirmation
     * @name PlanAffirmationUpdate
     * @summary Admin Update Plan Affirmation
     * @request PUT:/admin-panel/plan-affirmation/{plan_affirmation_id}
     */
    planAffirmationUpdate: (
      planAffirmationId: string,
      request: DefinitionsUpdatePlanAffirmationRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsUpdatePlanAffirmationResponse;
        },
        any
      >({
        path: `/admin-panel/plan-affirmation/${planAffirmationId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Plan Days
     *
     * @tags AdminPanel/PlanDay
     * @name PlanDayList
     * @summary Admin List Plan Days
     * @request GET:/admin-panel/plan-day
     */
    planDayList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsDayResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/plan-day`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Create Plan Day
     *
     * @tags AdminPanel/PlanDay
     * @name PlanDayCreate
     * @summary Admin Create Plan Day
     * @request POST:/admin-panel/plan-day
     */
    planDayCreate: (
      request: DefinitionsCreatePlanDayRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsCreatePlanDayResponse;
        },
        any
      >({
        path: `/admin-panel/plan-day`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Delete Plan Day
     *
     * @tags AdminPanel/PlanDay
     * @name PlanDayDelete
     * @summary Admin Delete Plan Day
     * @request DELETE:/admin-panel/plan-day/{plan_day_id}
     */
    planDayDelete: (planDayId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-panel/plan-day/${planDayId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin Get Plan Day
     *
     * @tags AdminPanel/PlanDay
     * @name PlanDayDetail
     * @summary Admin Get Plan Day
     * @request GET:/admin-panel/plan-day/{plan_day_id}
     */
    planDayDetail: (planDayId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsDayResponse;
        },
        any
      >({
        path: `/admin-panel/plan-day/${planDayId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Update Plan Day
     *
     * @tags AdminPanel/PlanDay
     * @name PlanDayUpdate
     * @summary Admin Update Plan Day
     * @request PUT:/admin-panel/plan-day/{plan_day_id}
     */
    planDayUpdate: (
      planDayId: string,
      request: DefinitionsUpdatePlanDayRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsDayResponse;
        },
        any
      >({
        path: `/admin-panel/plan-day/${planDayId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Plan Affirmations By Plan Day ID
     *
     * @tags AdminPanel/PlanAffirmation
     * @name PlanDayPlanAffirmationDetail
     * @summary Admin List Plan Affirmations By Plan Day ID
     * @request GET:/admin-panel/plan-day/{plan_day_id}/plan-affirmation
     */
    planDayPlanAffirmationDetail: (
      planDayId: string,
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanAffirmation[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/plan-day/${planDayId}/plan-affirmation`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Delete Plan
     *
     * @tags AdminPanel/Plan
     * @name PlanDelete
     * @summary Admin Delete Plan
     * @request DELETE:/admin-panel/plan/{plan_id}
     */
    planDelete: (planId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-panel/plan/${planId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Admin Get Plan
     *
     * @tags AdminPanel/Plan
     * @name PlanDetail
     * @summary Admin Get Plan
     * @request GET:/admin-panel/plan/{plan_id}
     */
    planDetail: (planId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanResponse;
        },
        any
      >({
        path: `/admin-panel/plan/${planId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Update Plan
     *
     * @tags AdminPanel/Plan
     * @name PlanUpdate
     * @summary Admin Update Plan
     * @request PUT:/admin-panel/plan/{plan_id}
     */
    planUpdate: (
      planId: string,
      request: DefinitionsUpdatePlanRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanResponse;
        },
        any
      >({
        path: `/admin-panel/plan/${planId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin List Plan Days By Plan ID
     *
     * @tags AdminPanel/PlanDay
     * @name PlanPlanDayDetail
     * @summary Admin List Plan Days By Plan ID
     * @request GET:/admin-panel/plan/{plan_id}/plan-day
     */
    planPlanDayDetail: (
      planId: string,
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsDayResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/plan/${planId}/plan-day`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List products
     *
     * @tags AdminPanel/Product
     * @name ProductsList
     * @summary List products
     * @request GET:/admin-panel/products
     */
    productsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProduct[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/products`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create product
     *
     * @tags AdminPanel/Product
     * @name ProductsCreate
     * @summary Create product
     * @request POST:/admin-panel/products
     */
    productsCreate: (
      request: DefinitionsCreateProductRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProduct;
        },
        any
      >({
        path: `/admin-panel/products`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get product
     *
     * @tags AdminPanel/Product
     * @name ProductsDetail
     * @summary Get product
     * @request GET:/admin-panel/products/{product_id}
     */
    productsDetail: (productId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProduct;
        },
        any
      >({
        path: `/admin-panel/products/${productId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update product
     *
     * @tags AdminPanel/Product
     * @name ProductsUpdate
     * @summary Update product
     * @request PUT:/admin-panel/products/{product_id}
     */
    productsUpdate: (
      productId: string,
      request: DefinitionsUpdateProductRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProduct;
        },
        any
      >({
        path: `/admin-panel/products/${productId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Admin Refresh Token
     *
     * @tags AdminPanel
     * @name RefreshTokenCreate
     * @summary Admin Refresh Token
     * @request POST:/admin-panel/refresh-token
     */
    refreshTokenCreate: (
      request: DefinitionsRefreshTokenRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAuthTokens;
        },
        any
      >({
        path: `/admin-panel/refresh-token`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description AdminSignUp
     *
     * @tags AdminPanel
     * @name SignUpCreate
     * @summary Admin SignUp
     * @request POST:/admin-panel/sign-up
     */
    signUpCreate: (
      request: DefinitionsAdminSignUpRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsAdminSignUpResponse;
        },
        any
      >({
        path: `/admin-panel/sign-up`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Theme Categories
     *
     * @tags AdminPanel/ThemeCategories
     * @name ThemeCategoriesList
     * @summary List Theme Categories
     * @request GET:/admin-panel/theme-categories
     */
    themeCategoriesList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsThemeCategoryResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/theme-categories`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Theme Category
     *
     * @tags AdminPanel/ThemeCategories
     * @name ThemeCategoriesCreate
     * @summary Create Theme Category
     * @request POST:/admin-panel/theme-categories
     */
    themeCategoriesCreate: (
      object: DefinitionsCreateThemeCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsThemeCategory;
        },
        any
      >({
        path: `/admin-panel/theme-categories`,
        method: 'POST',
        body: object,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Theme Category
     *
     * @tags AdminPanel/ThemeCategories
     * @name ThemeCategoriesDelete
     * @summary Delete Theme Category
     * @request DELETE:/admin-panel/theme-categories/{theme_category_id}
     */
    themeCategoriesDelete: (
      themeCategoryId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/admin-panel/theme-categories/${themeCategoryId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Theme Category
     *
     * @tags AdminPanel/ThemeCategories
     * @name ThemeCategoriesDetail
     * @summary Get Theme Category
     * @request GET:/admin-panel/theme-categories/{theme_category_id}
     */
    themeCategoriesDetail: (
      themeCategoryId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsThemeCategory;
        },
        any
      >({
        path: `/admin-panel/theme-categories/${themeCategoryId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Theme Category
     *
     * @tags AdminPanel/ThemeCategories
     * @name ThemeCategoriesUpdate
     * @summary Update Theme Category
     * @request PUT:/admin-panel/theme-categories/{theme_category_id}
     */
    themeCategoriesUpdate: (
      themeCategoryId: string,
      object: DefinitionsUpdateThemeCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsThemeCategory;
        },
        any
      >({
        path: `/admin-panel/theme-categories/${themeCategoryId}`,
        method: 'PUT',
        body: object,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Themes
     *
     * @tags AdminPanel/Themes
     * @name ThemesList
     * @summary List Themes
     * @request GET:/admin-panel/themes
     */
    themesList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
        /** Category ID */
        category_id?: string;
        /** Name */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsTheme[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/themes`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Theme
     *
     * @tags AdminPanel/Themes
     * @name ThemesCreate
     * @summary Create Theme
     * @request POST:/admin-panel/themes
     */
    themesCreate: (
      request: DefinitionsCreateThemeRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsTheme;
        },
        any
      >({
        path: `/admin-panel/themes`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Theme
     *
     * @tags AdminPanel/Themes
     * @name ThemesDelete
     * @summary Delete Theme
     * @request DELETE:/admin-panel/themes/{theme_id}
     */
    themesDelete: (themeId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-panel/themes/${themeId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Theme
     *
     * @tags AdminPanel/Themes
     * @name ThemesDetail
     * @summary Get Theme
     * @request GET:/admin-panel/themes/{theme_id}
     */
    themesDetail: (themeId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsTheme;
        },
        any
      >({
        path: `/admin-panel/themes/${themeId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Theme
     *
     * @tags AdminPanel/Themes
     * @name ThemesUpdate
     * @summary Update Theme
     * @request PUT:/admin-panel/themes/{theme_id}
     */
    themesUpdate: (
      themeId: string,
      request: DefinitionsUpdateThemeRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsTheme;
        },
        any
      >({
        path: `/admin-panel/themes/${themeId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload Theme Background
     *
     * @tags AdminPanel/Themes
     * @name ThemesBackgroundCreate
     * @summary Upload Theme Background
     * @request POST:/admin-panel/themes/{theme_id}/background
     */
    themesBackgroundCreate: (
      themeId: string,
      data: {
        /** file */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: string;
        },
        any
      >({
        path: `/admin-panel/themes/${themeId}/background`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload Theme Background Preview
     *
     * @tags AdminPanel/Themes
     * @name ThemesBackgroundPreviewCreate
     * @summary Upload Theme Background Preview
     * @request POST:/admin-panel/themes/{theme_id}/background-preview
     */
    themesBackgroundPreviewCreate: (
      themeId: string,
      data: {
        /** file */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: string;
        },
        any
      >({
        path: `/admin-panel/themes/${themeId}/background-preview`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload Media File
     *
     * @tags AdminPanel/UploadMediaFile
     * @name UploadMediaFileCreate
     * @summary Upload Media File
     * @request POST:/admin-panel/upload-media-file
     */
    uploadMediaFileCreate: (
      data: {
        /** File to upload */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsUploadMediaFileResponse;
        },
        any
      >({
        path: `/admin-panel/upload-media-file`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Users
     *
     * @tags AdminPanel/Users
     * @name UsersList
     * @summary List Users
     * @request GET:/admin-panel/users
     */
    usersList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUser[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/admin-panel/users`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Health
     * @name HealthList
     * @summary Health check
     * @request GET:/health
     */
    healthList: (params: RequestParams = {}) =>
      this.request<CommonHealthCheckResponse, any>({
        path: `/health`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  platform = {
    /**
     * @description List Affirmation Groups
     *
     * @tags Group
     * @name AffirmationGroupsList
     * @summary List Affirmation Groups
     * @request GET:/platform/affirmation-groups
     */
    affirmationGroupsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
        /** Improvement Area Id */
        improvement_area_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationCategoryGroupResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/affirmation-groups`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Affirmations
     *
     * @tags Affirmation
     * @name AffirmationsList
     * @summary List Affirmations
     * @request GET:/platform/affirmations
     */
    affirmationsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
        /** Affirmations IDs (separated by comma) */
        ids?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmation[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/affirmations`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change Forgotten Password
     *
     * @tags Platform
     * @name ChangeForgottenPasswordCreate
     * @summary Change Forgotten Password
     * @request POST:/platform/change-forgotten-password
     */
    changeForgottenPasswordCreate: (
      request: DefinitionsChangeForgottenPasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/change-forgotten-password`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Change user password
     *
     * @tags Platform
     * @name ChangePasswordCreate
     * @summary Change user password
     * @request POST:/platform/change-password
     */
    changePasswordCreate: (
      request: DefinitionsChangePasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/change-password`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description List Favorite Affirmations
     *
     * @tags Affirmation
     * @name FavoriteAffirmationList
     * @summary List Favorite Affirmations
     * @request GET:/platform/favorite-affirmation
     */
    favoriteAffirmationList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmation[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/favorite-affirmation`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Favorite Affirmation Categories
     *
     * @tags AffirmationCategory
     * @name FavoriteAffirmationCategoryList
     * @summary List Favorite Affirmation Categories
     * @request GET:/platform/favorite-affirmation-category
     */
    favoriteAffirmationCategoryList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAffirmationCategory[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/favorite-affirmation-category`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Favorite Affirmation Category
     *
     * @tags AffirmationCategory
     * @name FavoriteAffirmationCategoryDelete
     * @summary Delete Favorite Affirmation Category
     * @request DELETE:/platform/favorite-affirmation-category/{category_id}
     */
    favoriteAffirmationCategoryDelete: (
      categoryId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/favorite-affirmation-category/${categoryId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create Favorite Affirmation Category
     *
     * @tags AffirmationCategory
     * @name FavoriteAffirmationCategoryCreate
     * @summary Create Favorite Affirmation Category
     * @request POST:/platform/favorite-affirmation-category/{category_id}
     */
    favoriteAffirmationCategoryCreate: (
      categoryId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/favorite-affirmation-category/${categoryId}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete Favorite Affirmation
     *
     * @tags Affirmation
     * @name FavoriteAffirmationDelete
     * @summary Delete Favorite Affirmation
     * @request DELETE:/platform/favorite-affirmation/{affirmation_id}
     */
    favoriteAffirmationDelete: (
      affirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/favorite-affirmation/${affirmationId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create Favorite Affirmation
     *
     * @tags Affirmation
     * @name FavoriteAffirmationCreate
     * @summary Create Favorite Affirmation
     * @request POST:/platform/favorite-affirmation/{affirmation_id}
     */
    favoriteAffirmationCreate: (
      affirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/favorite-affirmation/${affirmationId}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description List Favorite Plan Affirmations
     *
     * @tags PlanAffirmation
     * @name FavoritePlanAffirmationList
     * @summary List Favorite Plan Affirmations
     * @request GET:/platform/favorite-plan-affirmation
     */
    favoritePlanAffirmationList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanAffirmation[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/favorite-plan-affirmation`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Favorite Plan Affirmation
     *
     * @tags PlanAffirmation
     * @name FavoritePlanAffirmationDelete
     * @summary Delete Favorite Plan Affirmation
     * @request DELETE:/platform/favorite-plan-affirmation/{plan_affirmation_id}
     */
    favoritePlanAffirmationDelete: (
      planAffirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/favorite-plan-affirmation/${planAffirmationId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create Favorite Plan Affirmation
     *
     * @tags PlanAffirmation
     * @name FavoritePlanAffirmationCreate
     * @summary Create Favorite Plan Affirmation
     * @request POST:/platform/favorite-plan-affirmation/{plan_affirmation_id}
     */
    favoritePlanAffirmationCreate: (
      planAffirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/favorite-plan-affirmation/${planAffirmationId}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description List Improvement Areas
     *
     * @tags ImprovementArea
     * @name ImprovementAreasList
     * @summary List Improvement Areas
     * @request GET:/platform/improvement-areas
     */
    improvementAreasList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsImprovementArea[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/improvement-areas`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description InitUserIOS
     *
     * @tags Funnel
     * @name InitUserIosCreate
     * @summary InitUserIOS
     * @request POST:/platform/init-user-ios
     */
    initUserIosCreate: (
      request: DefinitionsFunnelInitUserRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUser;
        },
        any
      >({
        path: `/platform/init-user-ios`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description InitUserWeb
     *
     * @tags Funnel
     * @name InitUserWebCreate
     * @summary InitUserWeb
     * @request POST:/platform/init-user-web
     */
    initUserWebCreate: (
      request: DefinitionsFunnelInitUserRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUser;
        },
        any
      >({
        path: `/platform/init-user-web`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Login a user
     *
     * @tags Platform
     * @name LoginCreate
     * @summary Login a user
     * @request POST:/platform/login
     */
    loginCreate: (
      request: DefinitionsUserLoginRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsUserLoginResponse;
        },
        any
      >({
        path: `/platform/login`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Logout a user
     *
     * @tags Platform
     * @name LogoutCreate
     * @summary Logout a user
     * @request POST:/platform/logout
     */
    logoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/platform/logout`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description List Own Affirmations
     *
     * @tags OwnAffirmation
     * @name OwnAffirmationsList
     * @summary List Own Affirmations
     * @request GET:/platform/own-affirmations
     */
    ownAffirmationsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsOwnAffirmation[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/own-affirmations`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Own Affirmation
     *
     * @tags OwnAffirmation
     * @name OwnAffirmationsCreate
     * @summary Create Own Affirmation
     * @request POST:/platform/own-affirmations
     */
    ownAffirmationsCreate: (
      request: DefinitionsCreateOwnAffirmationRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsOwnAffirmation;
        },
        any
      >({
        path: `/platform/own-affirmations`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Own Affirmation
     *
     * @tags OwnAffirmation
     * @name OwnAffirmationsDelete
     * @summary Delete Own Affirmation
     * @request DELETE:/platform/own-affirmations/{affirmation_id}
     */
    ownAffirmationsDelete: (
      affirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/own-affirmations/${affirmationId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Own Affirmation
     *
     * @tags OwnAffirmation
     * @name OwnAffirmationsDetail
     * @summary Get Own Affirmation
     * @request GET:/platform/own-affirmations/{affirmation_id}
     */
    ownAffirmationsDetail: (
      affirmationId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsOwnAffirmation;
        },
        any
      >({
        path: `/platform/own-affirmations/${affirmationId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Own Update Affirmation
     *
     * @tags OwnAffirmation
     * @name OwnAffirmationsUpdate
     * @summary Own Update Affirmation
     * @request PUT:/platform/own-affirmations/{affirmation_id}
     */
    ownAffirmationsUpdate: (
      affirmationId: string,
      request: DefinitionsUpdateOwnAffirmationRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsOwnAffirmation;
        },
        any
      >({
        path: `/platform/own-affirmations/${affirmationId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description CreateIOSUserPanda
     *
     * @tags Funnel
     * @name PandaIosUsersCreate
     * @summary CreateIOSUserPanda
     * @request POST:/platform/panda/ios/users
     */
    pandaIosUsersCreate: (
      request: ModelsCreateUserPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsCreateUserResponse;
        },
        any
      >({
        path: `/platform/panda/ios/users`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description UpdateIOSUserPanda
     *
     * @tags Funnel
     * @name PandaIosUsersUpdate
     * @summary UpdateIOSUserPanda
     * @request PUT:/platform/panda/ios/users/{panda_id}
     */
    pandaIosUsersUpdate: (
      pandaId: string,
      request: ModelsUpdateUserPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUpdateUserResponse;
        },
        any
      >({
        path: `/platform/panda/ios/users/${pandaId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description SubscriptionStatusIOS
     *
     * @tags Funnel
     * @name PandaIosUsersSubscriptionStatusDetail
     * @summary SubscriptionStatusIOS
     * @request GET:/platform/panda/ios/users/{panda_id}/subscription-status
     */
    pandaIosUsersSubscriptionStatusDetail: (
      pandaId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsSubscriptionStatusResponse;
        },
        any
      >({
        path: `/platform/panda/ios/users/${pandaId}/subscription-status`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description VerifyReceipt
     *
     * @tags Funnel
     * @name PandaIosUsersVerifyReceiptCreate
     * @summary VerifyReceipt
     * @request POST:/platform/panda/ios/users/{panda_id}/verify-receipt
     */
    pandaIosUsersVerifyReceiptCreate: (
      pandaId: string,
      request: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsVerifyReceiptResponse;
        },
        any
      >({
        path: `/platform/panda/ios/users/${pandaId}/verify-receipt`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description SolidV2
     *
     * @tags Funnel
     * @name PandaSolidCreate
     * @summary SolidV2
     * @request POST:/platform/panda/solid
     */
    pandaSolidCreate: (
      request: ModelsCreateSolidOrderPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsCreateSolidOrderResponse;
        },
        any
      >({
        path: `/platform/panda/solid`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description SolidPaypal
     *
     * @tags Funnel
     * @name PandaSolidPaypalCreate
     * @summary SolidPaypal
     * @request POST:/platform/panda/solid/paypal
     */
    pandaSolidPaypalCreate: (
      request: ModelsCreateSolidPaypalPayload,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/platform/panda/solid/paypal`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description GetPandaUserByEmailOrCustomUserID
     *
     * @tags Funnel
     * @name PandaUsersList
     * @summary GetPandaUserByEmailOrCustomUserID
     * @request GET:/platform/panda/users
     */
    pandaUsersList: (
      query?: {
        /** Email */
        email?: string;
        /** CustomUserID */
        custom_user_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsGetPandaUserResponse[];
        },
        any
      >({
        path: `/platform/panda/users`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description GetUserByPandaID
     *
     * @tags Funnel
     * @name PandaUsersDetail
     * @summary GetUserByPandaID
     * @request GET:/platform/panda/users/{panda_id}
     */
    pandaUsersDetail: (pandaId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsGetPandaUserResponse;
        },
        any
      >({
        path: `/platform/panda/users/${pandaId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description CreateWebUserPanda
     *
     * @tags Funnel
     * @name PandaWebUsersCreate
     * @summary CreateWebUserPanda
     * @request POST:/platform/panda/web/users
     */
    pandaWebUsersCreate: (
      request: ModelsCreateUserPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsCreateUserResponse;
        },
        any
      >({
        path: `/platform/panda/web/users`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description UpdateWebUserPanda
     *
     * @tags Funnel
     * @name PandaWebUsersUpdate
     * @summary UpdateWebUserPanda
     * @request PUT:/platform/panda/web/users/{panda_id}
     */
    pandaWebUsersUpdate: (
      pandaId: string,
      request: ModelsUpdateUserPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUpdateUserResponse;
        },
        any
      >({
        path: `/platform/panda/web/users/${pandaId}`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Plans
     *
     * @tags Plan
     * @name PlanList
     * @summary List Plans
     * @request GET:/platform/plan
     */
    planList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/plan`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Plan
     *
     * @tags Plan
     * @name PlanDetail
     * @summary Get Plan
     * @request GET:/platform/plan/{plan_id}
     */
    planDetail: (planId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlanResponse;
        },
        any
      >({
        path: `/platform/plan/${planId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete user profile
     *
     * @tags Platform
     * @name ProfileDelete
     * @summary Delete user profile
     * @request DELETE:/platform/profile
     */
    profileDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/platform/profile`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get user profile
     *
     * @tags Platform
     * @name ProfileList
     * @summary Get user profile
     * @request GET:/platform/profile
     */
    profileList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUser;
        },
        any
      >({
        path: `/platform/profile`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update user profile
     *
     * @tags Platform
     * @name ProfileUpdate
     * @summary Update user profile
     * @request PUT:/platform/profile
     */
    profileUpdate: (
      request: PlatformUpdateProfileRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: PlatformUpdateProfileResponse;
        },
        any
      >({
        path: `/platform/profile`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Refresh token
     *
     * @tags Platform
     * @name RefreshTokenCreate
     * @summary Refresh token
     * @request POST:/platform/refresh-token
     */
    refreshTokenCreate: (
      request: DefinitionsRefreshTokenRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAuthTokens;
        },
        any
      >({
        path: `/platform/refresh-token`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Reset user password
     *
     * @tags Platform
     * @name ResetPasswordCreate
     * @summary Reset user password
     * @request POST:/platform/reset-password
     */
    resetPasswordCreate: (
      request: DefinitionsResetPasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/platform/reset-password`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Sign up a new user
     *
     * @tags Platform
     * @name SignUpCreate
     * @summary Sign up a new user
     * @request POST:/platform/sign-up
     */
    signUpCreate: (
      request: DefinitionsUserSignUpRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: DefinitionsUserSignUpResponse;
        },
        any
      >({
        path: `/platform/sign-up`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Theme Categories
     *
     * @tags ThemeCategory
     * @name ThemeCategoriesList
     * @summary List Theme Categories
     * @request GET:/platform/theme-categories
     */
    themeCategoriesList: (
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsThemeCategoryResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/theme-categories`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List Category Themes
     *
     * @tags Theme
     * @name ThemeCategoriesThemesDetail
     * @summary List Category Themes
     * @request GET:/platform/theme-categories/{category_id}/themes
     */
    themeCategoriesThemesDetail: (
      categoryId: string,
      query?: {
        /** Page */
        page?: number;
        /** Per Page */
        'per-page'?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsTheme[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/platform/theme-categories/${categoryId}/themes`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  resetPasswordLinkRedirect = {
    /**
     * @description Reset Password Link Redirect
     *
     * @tags Platform
     * @name ResetPasswordLinkRedirectDetail
     * @summary Reset Password Link Redirect
     * @request GET:/reset-password-link-redirect/{token}
     */
    resetPasswordLinkRedirectDetail: (
      token: string,
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/reset-password-link-redirect/${token}`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),
  };
}
