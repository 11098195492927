import { FC } from 'react';

export type SideBarItemType = {
  path: string;
  Icon: FC;
  title: string;
  permissionKey?: string;
};

class Sidebar {
  private items: Record<string, SideBarItemType[]>;

  constructor(routes?: Record<string, SideBarItemType[]>) {
    this.items = routes ?? {};
  }

  addItems(routes: Record<string, SideBarItemType[]>): void {
    Object.entries(routes).forEach(([key, value]) => {
      if (this.items[key]) {
        this.items[key] = [...this.items[key], ...value];
      } else {
        this.items[key] = value;
      }
    });
  }

  getItems(): Record<string, SideBarItemType[]> {
    return this.items;
  }

  getItemsByPermissions(
    permissionsList: string[],
  ): Record<string, SideBarItemType[]> {
    return Object.fromEntries(
      Object.entries(this.items).map(([key, item]) => [
        key,
        item.filter(
          ({ permissionKey }) =>
            !permissionKey || permissionsList.includes(permissionKey),
        ),
      ]),
    );
  }
}

export default new Sidebar();
