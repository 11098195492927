import React, { FC, ReactElement } from 'react';

import {
  MenuItem,
  Select,
  SelectProps,
  styled,
  Typography,
} from '@mui/material';

import { ReactComponent as ChevronDown } from '~/assets/icons/chevron-down.svg';

export interface DropdownProps extends SelectProps {
  options: { value: string; text: string | ReactElement; disabled?: boolean }[];
  isTimeIntervals?: boolean;
  size?: 'small' | 'medium';
}

export interface DropdownStyledProps extends SelectProps {
  size?: 'small' | 'medium';
}

const DropdownStyled = styled(Select)<DropdownStyledProps>(
  ({ size, theme }) => ({
    '&': {
      width: '100%',
      borderRadius: '8px',
      fontSize: size === 'small' ? '14px' : '15px',
      lineHeight: '24px',
    },

    '&:hover': {
      '&& fieldset': {
        borderColor: theme.colors['neutral_600'],
      },
    },

    '& .MuiSelect-select': {
      padding: size === 'small' ? '9px 16px!important' : '12px 16px!important',
      height: size === 'small' ? '40px' : '48px',
      boxSizing: 'border-box',
    },

    '& fieldset': {
      borderColor: theme.colors['neutral_100'],
      fontSize: size === 'small' ? '14px' : '15px',
      lineHeight: '24px',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors['neutral_700'],
      borderWidth: '1px',
    },

    '& .MuiSvgIcon-root': {
      display: 'none',
    },

    '& svg': {
      width: '18px',
      height: '18px',
      marginRight: '16px',
      cursor: 'pointer',
      position: 'absolute !important',
      right: '0 !important',
      'pointer-events': 'none !important',
    },
  }),
);

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 12;

const Dropdown: FC<DropdownProps> = ({
  value,
  onChange,
  label,
  disabled,
  options,
  isTimeIntervals,
  size,
  ...props
}) => (
  <>
    {label && (
      <Typography
        variant="body2"
        sx={{
          color: 'neutral.600',
          mb: '8px',
          fontSize: size === 'small' ? '14px' : '15px',
        }}
      >
        {label}
      </Typography>
    )}
    <DropdownStyled
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoWidth={false}
      size={size}
      IconComponent={(): JSX.Element | null =>
        !isTimeIntervals ? <ChevronDown /> : null
      }
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
            paddingRight: '5px',
            paddingLeft: '5px',
            marginLeft: 0,
            borderRadius: '4px',
          },
          sx: {
            '&::-webkit-scrollbar-track': {
              background: 'none',
            },

            '&::-webkit-scrollbar': {
              width: '8px',
            },

            '&::-webkit-scrollbar-thumb': {
              background: '#D9DADF',
              'border-radius': '4px',
            },
          },
        },
      }}
      sx={{ width: isTimeIntervals ? '85px!important' : '' }}
      {...props}
    >
      {options.map((item) => (
        <MenuItem
          key={item.value}
          value={item.value}
          style={{
            padding: '9px',
            fontSize: size === 'small' ? '14px' : '15px',
          }}
        >
          {item.text}
        </MenuItem>
      ))}
    </DropdownStyled>
  </>
);

export default Dropdown;
