import * as yup from 'yup';

import DateService from '~/services/Date';

const passwordSchema = yup
  .string()
  .max(128)
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/);

const emailSchema = yup
  .string()
  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

const birthdaySchema = yup
  .string()
  .test('test-birthday', 'Invalid Date', (value) => {
    try {
      return !(
        value &&
        (!DateService.isValid(new Date(value)) ||
          new Date(value).getFullYear() <
            DateService.addYears(new Date(), -100).getFullYear() ||
          new Date(value).getFullYear() >
            DateService.addYears(new Date(), -16).getFullYear())
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  });

export { passwordSchema, emailSchema, birthdaySchema };
