import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { ImprovementAreaListRoute } from '~/modules/improvementAreas';
import { ImprovementAreaType } from '~/modules/improvementAreas/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = {
  name: string;
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
  })
  .required();

const ProductItem: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [area, setArea] = useState<ImprovementAreaType>({
    id: '1',
    name: '',
    created_at: '2022-11-11 11:11:11',
    updated_at: '2022-11-11 11:11:11',
  });
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit, setValue } = form;

  const loadAdmin = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      const area = await Api.getImprovementArea(id);
      setArea(area);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const area = await Api.updateImprovementArea(id, data);
      showSnackbar('Updated', 'success');
      setArea(area);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleDelete = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.deleteImprovementArea(id);
      showSnackbar('Removed', 'success');
      setArea(area);
      setIsLoading(false);
      history.push(ImprovementAreaListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push(ImprovementAreaListRoute.path);
    }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    loadAdmin(id).finally(() => {
      setIsFirstLoading(false);
    });
  }, []);

  useEffect(() => {
    setValue('name', area.name);
  }, [area]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to List"
        onClick={(): void => {
          history.push(ImprovementAreaListRoute.path);
        }}
      />
      <TopHeadlineRow className={styles.top_bar} text="Improvement area card" />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          <Tabs
            className={styles.container__tabs}
            items={[
              {
                label: 'General info',
                isActive: true,
              },
            ]}
            onClickItem={(): void => {}}
          />
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="name"
                    label="Improvement area name"
                    fullWidth
                  />
                </div>

                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(area.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(area.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.buttons_container}>
                  <Button
                    style={{ marginRight: 10, marginBottom: 10 }}
                    type="submit"
                    disabled={isLoading}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(handleSuccessSubmit)}
                  >
                    Update info
                  </Button>

                  <Button
                    disabled={isLoading}
                    color="secondary"
                    variant="contained"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default ProductItem;
