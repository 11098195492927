import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = (): {
  setQueryParams: (params: Record<string, number | string | null>) => void;
  getQueryParams: () => Record<string, number | string | null>;
} => {
  const history = useHistory();
  const location = useLocation();

  return {
    setQueryParams: (params): void => {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([, value]) => value),
      );
      history.replace({
        search: new URLSearchParams(
          filteredParams as Record<string, string>,
        ).toString(),
      });
    },
    getQueryParams: (): Record<string, number | string | null> => {
      return Object.fromEntries(new URLSearchParams(location.search));
    },
  };
};

export default useQueryParams;
