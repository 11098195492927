import React, { FC } from 'react';

import { Icon } from '@iconify/react';

import FileUploadButton from '~/components/atoms/buttons/FileUploadButton';
import IconButton from '~/components/atoms/buttons/IconButton';

import styles from './styles.module.scss';

type Props = {
  isLoading: boolean;
  src: string | ArrayBuffer | null;
  update: (file: File) => Promise<void>;
  remove?: () => void;
};

const EditPhoto: FC<Props> = ({ isLoading, src, update, remove }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container__item}>
        <FileUploadButton
          onChange={(e): void => {
            if (e?.target?.files && e.target.files[0]) {
              update(e.target.files[0]);
            }
          }}
          variant="outlined"
        >
          Upload photo
        </FileUploadButton>
      </div>
      {Boolean(src) && remove && (
        <div className={styles.container__item}>
          <IconButton
            size="medium"
            variant="outlined"
            disabled={isLoading}
            onClick={remove}
          >
            <Icon color="#f00" icon="heroicons:trash" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default EditPhoto;
