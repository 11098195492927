import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormPassword from '~/components/hookFormControls/PasswordControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import { emailSchema, passwordSchema } from '~/helpers/validationSchemas';

import styles from './styles.module.scss';

type FormDataType = {
  email: string;
  password: string;
  confirmPassword: string;
};

const formSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('E-mail address is required')
      .test('test-email', 'Invalid Email', (value) => {
        if (value) return emailSchema.isValidSync(value);
        return true;
      }),
    password: yup
      .string()
      .required()
      .test(
        'test-password',
        'Password must be at least 8 characters long and include at least one number,one capital letter and one small letter.',
        (value) => {
          if (value) return passwordSchema.isValidSync(value);
          return true;
        },
      ),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

type Props = {
  onClose: () => void;
  addAdmin: (email: string, password: string) => Promise<void>;
};

const CreateAdminForm: FC<Props> = ({ onClose, addAdmin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit } = form;

  const handleSuccessSubmit = async ({
    email,
    password,
  }: FormDataType): Promise<void> => {
    if (!email) {
      return;
    }
    try {
      setIsLoading(true);
      await addAdmin(email, password);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.main_container}>
      <ModalContentTitle text="New Admin" />
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="email"
              label="Email"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormPassword
              required
              name="password"
              label="New Password"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormPassword
              required
              name="confirmPassword"
              label="Confirm New Password"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Admin
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateAdminForm;
