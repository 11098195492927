import React, { FC } from 'react';

import {
  TableCell as MuiTableCell,
  styled,
  TableCellProps,
} from '@mui/material';

export const TableCellStyled = styled(MuiTableCell)(({ theme }) => ({
  '&': {
    borderBottom: 'none',
    color: theme.colors.black,

    '& a': {
      color: theme.colors.black,
    },

    '& .header_sort_arrow': {
      marginLeft: '8px',
      display: 'flex',
    },
  },
}));

const TableCell: FC<TableCellProps> = ({ children, ...props }) => {
  return <TableCellStyled {...props}>{children}</TableCellStyled>;
};

export default TableCell;
