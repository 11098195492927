import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  text: string;
  onClick: () => void;
  className?: string;
}

const BackButton: FC<Props> = ({ text, onClick, className }) => (
  <button
    onClick={(): void => onClick()}
    className={clsx(styles.button, className)}
  >
    <Icon fontSize={22} icon="material-symbols:arrow-back-ios-rounded" />
    &nbsp;&nbsp;{text}
  </button>
);

export default BackButton;
