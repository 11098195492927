import React, { createContext, useCallback, useContext, useState } from 'react';

import Modal from '~/components/molecules/Modal/Modal';

interface Props {
  component: React.FC<{ onClose: () => void }>;
  title?: string;
  className?: string;
  onClose?: () => void;
  onShow?: () => void;
  pageViewMobile?: boolean;
  hideCloseArrow?: boolean;
  disableBackdropClick?: boolean;
}

type ContextStateType = {
  handleOpenModal({ component, hideCloseArrow, onClose, onShow }: Props): void;
};

type ModalStateType = {
  isOpen: boolean;
  component: React.FC<{ onClose: () => void }>;
  onClose: () => void;
  onShow: () => void;
  disableBackdropClick: boolean;
  className?: string;
  hideCloseArrow?: boolean;
  pageViewMobile?: boolean;
};

const initialModalState: ModalStateType = {
  isOpen: false,
  component: () => <div />,
  onClose: () => {},
  onShow: () => {},
  hideCloseArrow: false,
  disableBackdropClick: false,
  pageViewMobile: false,
};

const ModalContext = createContext<ContextStateType>({
  handleOpenModal: () => {},
});

const ModalContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<ModalStateType>(initialModalState);

  const handleOpenModal = useCallback(
    ({
      component,
      className,
      hideCloseArrow,
      onClose = (): void => {},
      onShow = (): void => {},
      disableBackdropClick = false,
      pageViewMobile = false,
    }: Props): void => {
      setState(() => {
        onShow();
        return {
          isOpen: true,
          component,
          className,
          onClose,
          onShow,
          hideCloseArrow,
          disableBackdropClick,
          pageViewMobile,
        };
      });
    },
    [],
  );

  const handleClose = (): void => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
  };

  return (
    <ModalContext.Provider
      value={{
        handleOpenModal,
      }}
    >
      {children}
      <Modal
        isOpen={state.isOpen}
        className={state.className}
        onClose={(): void => {
          if (!state.disableBackdropClick) {
            state.onClose();
            handleClose();
          }
        }}
        hideCloseArrow={state.hideCloseArrow}
        pageViewMobile={state.pageViewMobile}
      >
        {state.component({
          onClose: handleClose,
        })}
      </Modal>
    </ModalContext.Provider>
  );
};

const useModalContext = (): ContextStateType => {
  const modalContext = useContext<ContextStateType>(ModalContext);

  if (!modalContext) {
    throw Error(
      'useModalContext hook should be wrapped by ModalContextProvider',
    );
  }

  return modalContext;
};

export { ModalContextProvider, useModalContext };
