import React, { FC } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import AuthRoute from '~/components/layoutComponents/AuthRoute';
import { CheckAuthWrapper } from '~/components/layoutComponents/CheckAuthWrapper';
import PrivateRoute from '~/components/layoutComponents/PrivateRoute';
import router from '~/core/router';

const authRoutes = router.getAuthRoutes();
const privateRoutes = router.getPrivateRoutes();
const Router: FC = () => {
  return (
    <BrowserRouter>
      <CheckAuthWrapper>
        <React.Suspense fallback={<FullscreenPreloader />}>
          <Switch>
            {authRoutes.map(({ path, title, exact, Component }) => (
              <AuthRoute
                key={path}
                path={path}
                title={title}
                exact={exact}
                Component={Component}
              />
            ))}
            {privateRoutes.map(({ path, title, exact, Component }) => (
              <PrivateRoute
                key={path}
                path={path}
                title={title}
                exact={exact}
                Component={Component}
              />
            ))}
            <Route path="*">
              <Redirect to={router.getSignInRoutePath()} />
            </Route>
          </Switch>
        </React.Suspense>
      </CheckAuthWrapper>
    </BrowserRouter>
  );
};

export default Router;
