// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dlk2levopWtKrvS075bP{opacity:.8;pointer-events:none}.Q2GAbofxEHtYVveKlFYj{display:flex;flex-direction:column}.Ga6tzj6J825Ayqte7AwF{height:100%}.lXlbnBxiuLyjsMjnWhDP{flex:1}.x1XndUX749sOrILWeo71{border-bottom:1px solid #e5e6e9}.JErzweZEZXGNBZvzyN6W{display:flex;justify-content:space-between;flex-wrap:nowrap;width:100%;padding:18px 40px 0}.CCDsUmoLVGchoLOq98ZA{display:flex;flex-direction:column;flex:1;overflow-x:hidden}.gzYdW9W6fLy9SYbSUpfQ{min-width:230px}.bCLQuSfTNfohYoT_QScY{font-size:15px;font-weight:700;display:block;margin-bottom:10px}.oFtM2VFkEVw0tBEers4l{margin-right:25px;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/plans/components/AffirmationsList/styles.modules.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAEA,sBACE,WAAA,CAGF,sBACE,MAAA,CAIJ,sBACE,+BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,UAAA,CACA,mBAAA,CAEA,sBACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CAIJ,sBACE,iBAAA,CACA,WAAA","sourcesContent":["@import 'src/styles/colors';\n@import \"src/styles/fonts\";\n\n.loading {\n  opacity: .8;\n  pointer-events: none;\n}\n\n.vertical_flex_container {\n  display: flex;\n  flex-direction: column;\n\n  &--full_height {\n    height: 100%;\n  }\n\n  &__full_parent_height {\n    flex: 1;\n  }\n}\n\n.top_bar {\n  border-bottom: 1px solid $neutral-200;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  width: 100%;\n  padding: 18px 40px 0;\n\n  &__left {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    overflow-x: hidden;\n  }\n\n  &__right {\n    min-width: 230px;\n  }\n\n  &__headline {\n    font-size: 15px;\n    font-weight: 700;\n    display: block;\n    margin-bottom: 10px\n  }\n}\n\n.table_container {\n  margin-right: 25px;\n  height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "Dlk2levopWtKrvS075bP",
	"vertical_flex_container": "Q2GAbofxEHtYVveKlFYj",
	"vertical_flex_container--full_height": "Ga6tzj6J825Ayqte7AwF",
	"vertical_flex_container__full_parent_height": "lXlbnBxiuLyjsMjnWhDP",
	"top_bar": "x1XndUX749sOrILWeo71",
	"container": "JErzweZEZXGNBZvzyN6W",
	"container__left": "CCDsUmoLVGchoLOq98ZA",
	"container__right": "gzYdW9W6fLy9SYbSUpfQ",
	"container__headline": "bCLQuSfTNfohYoT_QScY",
	"table_container": "oFtM2VFkEVw0tBEers4l"
};
export default ___CSS_LOADER_EXPORT___;
