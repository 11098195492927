import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

class ErrorLogger {
  isInitialized = false;

  init({ sentryDsn }: { sentryDsn: string }): void {
    Sentry.init({
      dsn: sentryDsn,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
    this.isInitialized = true;
  }

  captureException(e: Error, description?: string): void {
    if (this.isInitialized) {
      Sentry.captureException(e);
    }
    if (description) {
      console.error(`${description}: `, e);
    } else {
      console.error(e);
    }
  }
}

const errorLogger = new ErrorLogger();

export default errorLogger;
