import React, { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  text: string;
};

const ModalContentTitle: FC<Props> = ({ className, text }) => {
  return <span className={clsx(styles.title, className)}>{text}</span>;
};

export default ModalContentTitle;
