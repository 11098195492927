import React, {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
} from 'react';

import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';

import Snackbar from './Snackbar';

// Todo: change arguments to options object as one argument

type ContextStateType = {
  showSnackbar(
    message: ReactElement | string,
    type?: 'success' | 'error' | 'info',
    autoHideDuration?: number | null,
    optionalMessage?: ReactElement | string,
    onClose?: () => void,
    fullWidth?: boolean,
  ): void;
};

const SnackbarContext = createContext<ContextStateType>({
  showSnackbar: () => {},
});

const SnackBarContextProvider: FC = ({ children }) => (
  // @ts-ignore
  <SnackbarProvider maxSnack={3}>
    <SnackBarContext>{children}</SnackBarContext>
  </SnackbarProvider>
);

const SnackBarContext: FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = useCallback((key: SnackbarKey): void => {
    closeSnackbar(key);
  }, []);

  const showSnackbar = useCallback(
    (
      message: ReactElement | string,
      type: 'error' | 'success' | 'info' = 'error',
      autoHideDuration: number | null = 10000,
      optionalMessage: ReactElement | string,
      onClose: () => void,
      fullWidth = false,
    ) => {
      enqueueSnackbar(null, {
        autoHideDuration,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => (
          <Snackbar
            message={message}
            optionalMessage={optionalMessage}
            type={type}
            handleClose={(): void => {
              onClose && onClose();
              handleCloseSnackbar(key);
            }}
            fullWidth={fullWidth}
          />
        ),
      });
    },
    [],
  );

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackBarContext = (): ContextStateType => {
  const snackbarContext = useContext<ContextStateType>(SnackbarContext);

  if (!snackbarContext) {
    throw Error(
      'useSnackBarContext hook should be wrapped by SnackBarContextProvider',
    );
  }

  return snackbarContext;
};

export { SnackBarContextProvider, useSnackBarContext };
